import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import * as Feather from "react-feather";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useAppState } from "../../components/App/AppProvider";
import { MultiSelect } from "../../components/Forms/Field/MultiSelect";
import { Number } from "../../components/Forms/Field/Number";
import { Select } from "../../components/Forms/Field/Select";
import { Text } from "../../components/Forms/Field/Text";
import { HelpText } from "../../components/Forms/HelpText/HelpText";
import { Header } from "../../components/UI/Header/Header";
import LegacyScreenContainer from "../../components/UI/LegacyScreenContainer";
import { Loading } from "../../components/UI/Loading/Loading";
import * as ObjectTypeGroupID from "../../constants/objectTypeGroup";
import * as Permissions from "../../shared/constants/permission";
import * as Access from "../../utilities/access";
import { useLegacyApi } from "../../utilities/request";
import { toProperCase } from "../../utilities/strings";
import { getUrlSearchParam } from "../../utilities/url";
import useToast from "../../utilities/useToast";

// check uniqueness of code

const checkUniquenessOfPageCode = (
  currentID: any,
  currentCode: any,
  pages: any[],
) => {
  const check = pages.find(
    (obj: any) =>
      currentCode === obj.ObjectTypeCode && currentID !== obj.ObjectTypeID,
  );
  if (check) {
    return false;
  }
  return true;
};
function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const ScreensPageSettings = () => {
  const navigate = useNavigate();
  const { get, post, put, del } = useLegacyApi();
  const location = useLocation();
  const { auth, app } = useAppState();
  const [pageStatus, setPageStatus] = useState<any>("Loading");
  const [data, setData] = useState<any>(null);
  const { displayToast } = useToast();
  const [customFields, setCustomFields] = useState<any>(null);
  const [objectTypes, setObjectTypes] = useState<any>(null);
  const [availableFor, setAvailableFor] = useState<any>(null);
  const [selectedObjectType, setSelectedObjectType] = useState<any>(null);
  const [canDelete, setCanDelete] = useState<any>(null);
  const [relationships, setRelationships] = useState<any>(null);
  const [icons, setIcons] = useState<any>(null);
  const distinctObjectTypes: any[] = [];

  const getNewObjectType = (): any => {
    const type =
      location.search.indexOf("&") > -1
        ? location.search.split("&")[1].split("=")[1]
        : "";

    const newObjectType: any = {
      ObjectTypeID: "",
      ObjectTypeName: "",
      ObjectTypeDescription: "",
      ObjectTypeGroupName:
        Access.objectTypeGroupIDToName(type).toLocaleLowerCase(),
      ObjectTypeGroupID: type,
      ObjectTypeWindowBefore: 0,
      ObjectTypeWindowAfter: 0,
      ObjectTypeNamePlural: "",
      ObjectTypeCode: "",
      ObjectTypeIcon: "Activity",
      ObjectTypeRiskQuestionsSupported: 0,
      ObjectTypeNotesEnabled: false,
      ObjectTypeTimeNotesEnabled: false,
      ObjectTypeAvailableForMobile: false,
      ObjectTypeFlexibleDates: false,
    };
    return newObjectType;
  };

  const schema = Yup.object().shape({
    ObjectTypeName: Yup.string().required("Name is required"),
    ObjectTypeDescription: Yup.string().required("Description is required"),
    ObjectTypeGroupID: Yup.string().required("Group is required"),
    ObjectTypeNamePlural: Yup.string().required("A plural name is required"),
    ObjectTypeCode: Yup.string().min(1).max(3).required("A code is required"),
    ObjectTypeIcon: Yup.string().required("An icon is required"),
    ObjectTypeWindowBefore: Yup.number().when("ObjectTypeGroupID", {
      is: ObjectTypeGroupID.Action, // alternatively: (val) => val == true
      then: Yup.number().required("A Window Before is required"),
      otherwise: Yup.number().nullable(true),
    }),
    ObjectTypeWindowAfter: Yup.number().when("ObjectTypeGroupID", {
      is: ObjectTypeGroupID.Action, // alternatively: (val) => val == true
      then: Yup.number().required("A Window After is required"),
      otherwise: Yup.number().nullable(true),
    }),
  });

  const setAvailabilities = async (
    Relationships: any,
    selected: string,
    selectedGroupID: string,
  ) => {
    setRelationships(Relationships);
    const aFor = Relationships.filter(
      (relationship: any) => relationship.ObjectTypeID === selected,
    ).map((relationship: any) => {
      let type = relationship.ObjectTypeGroupName;
      if (!relationship.ObjectTypeRelationshipParent && type === "Risks") {
        type = "Controls";
      } else if (!relationship.ObjectTypeRelationshipParent) {
        type = "Sub-".concat(type);
      }
      return {
        ...relationship,
        ObjectTypeGroupName: type,
        ID: `${relationship.ObjectTypeRelationshipParent}:${relationship.ObjectTypeGroupID}`,
      };
    });
    let unSelected: any[] = [];
    if (
      selectedGroupID === ObjectTypeGroupID.Requirement ||
      selectedGroupID === ObjectTypeGroupID.Issue
    ) {
      unSelected = [
        {
          ObjectTypeGroupID: selectedGroupID,
          ObjectTypeID: selected,
          ObjectTypeGroupName:
            ObjectTypeGroupID.ObjectTypeGroupIDToName[selectedGroupID],
          ObjectTypeRelationshipParent: true,
          ID: `1:${selectedGroupID}`,
        },
        {
          ObjectTypeGroupID: selectedGroupID,
          ObjectTypeID: selected,
          ObjectTypeGroupName: "Sub-".concat(
            ObjectTypeGroupID.ObjectTypeGroupIDToName[selectedGroupID],
          ),
          ObjectTypeRelationshipParent: false,
          ID: `0:${selectedGroupID}`,
        },
      ];
    } else if (selectedGroupID === ObjectTypeGroupID.Risk) {
      unSelected = [
        {
          ObjectTypeGroupID: selectedGroupID,
          ObjectTypeID: selected,
          ObjectTypeGroupName:
            ObjectTypeGroupID.ObjectTypeGroupIDToName[selectedGroupID],
          ObjectTypeRelationshipParent: true,
          ID: `1:${selectedGroupID}`,
        },
        {
          ObjectTypeGroupID: selectedGroupID,
          ObjectTypeID: selected,
          ObjectTypeGroupName: "Controls",
          ObjectTypeRelationshipParent: false,
          ID: `0:${selectedGroupID}`,
        },
      ];
    } else if (selectedGroupID === ObjectTypeGroupID.Action) {
      unSelected = [
        {
          ObjectTypeGroupID: ObjectTypeGroupID.Requirement,
          ObjectTypeID: selected,
          ObjectTypeGroupName: "Requirements",
          ObjectTypeRelationshipParent: true,
          ID: `1:${ObjectTypeGroupID.Requirement}`,
        },
        {
          ObjectTypeGroupID: ObjectTypeGroupID.Requirement,
          ObjectTypeID: selected,
          ObjectTypeGroupName: "Sub-Requirements",
          ObjectTypeRelationshipParent: false,
          ID: `0:${ObjectTypeGroupID.Requirement}`,
        },
        {
          ObjectTypeGroupID: ObjectTypeGroupID.Issue,
          ObjectTypeID: selected,
          ObjectTypeGroupName: "Issues",
          ObjectTypeRelationshipParent: true,
          ID: `1:${ObjectTypeGroupID.Issue}`,
        },
        {
          ObjectTypeGroupID: ObjectTypeGroupID.Issue,
          ObjectTypeID: selected,
          ObjectTypeGroupName: "Sub-Issues",
          ObjectTypeRelationshipParent: false,
          ID: `0:${ObjectTypeGroupID.Issue}`,
        },
        {
          ObjectTypeGroupID: ObjectTypeGroupID.Risk,
          ObjectTypeID: selected,
          ObjectTypeGroupName: "Risks",
          ObjectTypeRelationshipParent: true,
          ID: `1:${ObjectTypeGroupID.Risk}`,
        },
        {
          ObjectTypeGroupID: ObjectTypeGroupID.Risk,
          ObjectTypeID: selected,
          ObjectTypeGroupName: "Controls",
          ObjectTypeRelationshipParent: false,
          ID: `0:${ObjectTypeGroupID.Risk}`,
        },
        {
          ObjectTypeGroupID: ObjectTypeGroupID.Job,
          ObjectTypeID: selected,
          ObjectTypeGroupName: "Jobs",
          ObjectTypeRelationshipParent: true,
          ID: `1:${ObjectTypeGroupID.Job}`,
        },
        {
          ObjectTypeGroupID: ObjectTypeGroupID.Register,
          ObjectTypeID: selected,
          ObjectTypeGroupName: "Registers",
          ObjectTypeRelationshipParent: true,
          ID: `1:${ObjectTypeGroupID.Register}`,
        },
      ];
    }
    aFor
      .map((ot: any) => {
        const letter = ot.ObjectTypeGroupName.substr(
          ot.ObjectTypeGroupName.length - 1,
        );
        if (letter != "s") {
          return {
            ...ot,
            ObjectTypeGroupName: ot.ObjectTypeGroupName.concat("s"),
          };
        }
        return ot;
      })
      .sort((a: any, b: any) =>
        a.ObjectTypeGroupName.localeCompare(b.ObjectTypeGroupName, "en", {
          numeric: true,
        }),
      );
    unSelected = unSelected
      .filter(
        (ot: any) => aFor.find((ot2: any) => ot.ID === ot2.ID) === undefined,
      )
      .map((ot: any) => {
        const letter = ot.ObjectTypeGroupName.substr(
          ot.ObjectTypeGroupName.length - 1,
        );
        if (letter != "s") {
          return {
            ...ot,
            ObjectTypeGroupName: ot.ObjectTypeGroupName.concat("s"),
          };
        }
        return ot;
      })
      .sort((a: any, b: any) =>
        a.ObjectTypeGroupName.localeCompare(b.ObjectTypeGroupName, "en", {
          numeric: true,
        }),
      );
    setAvailableFor({ selected: aFor, unSelected });
  };

  const setObjectType = async (
    selected: string,
    objectTypes: any[],
    customFields: any[],
    Relationships: any[],
  ) => {
    const selectedObjectType = objectTypes.find(
      (objectType: any) => objectType.ObjectTypeID === selected,
    );
    setSelectedObjectType(selectedObjectType);
    const objectTypeCFOrder = customFields.filter(
      (customfield: any, fieldIndex: any) =>
        customfield.ObjectTypes.find(
          (objecttype: any) =>
            objecttype.ObjectTypeID === selectedObjectType.ObjectTypeID,
        ),
    );

    const selectedGroupID: string = objectTypes.find(
      (ot: any) => ot.ObjectTypeID === selected,
    ).ObjectTypeGroupID;
    setAvailabilities(Relationships, selected, selectedGroupID);
  };

  useEffect(() => {
    const fetchData = async () => {
      setPageStatus("Loading");
      const result = await get(
        `settings/pages?userID=${app.attributes.userID}`,
      );
      if (result.status !== 200 || !result.data) {
        displayToast({
          status: "error",
          title: "Failed to retrieve Page",
        });
      } else {
        setCustomFields(result.data.customFields);
        setObjectTypes(result.data.objectTypeInformation);
        setCanDelete(result.data.canDelete);
        setIcons(
          Object.keys(Feather)
            .filter((icon: string) => icon !== "EyeOff")
            .map((icon: any) => {
              const IconType: keyof typeof Feather = icon;
              const Icon = Feather[IconType];
              const returnObj: any = [IconType, <Icon />];
              return returnObj;
            }),
        );
        if (location.search.indexOf("new") < 0) {
          const selected: string = location.search.split("=")[1];
          await setObjectType(
            selected,
            result.data.objectTypeInformation,
            result.data.customFields,
            result.data.Relationships,
          );

          setPageStatus("Ready");
        } else if (location.search.indexOf("new") > -1) {
          setSelectedObjectType({
            ...getNewObjectType(),
            Roles: result.data.Roles,
          });
          const objectTypeGroupID = getUrlSearchParam(location.search, "type");
          setAvailabilities([], "", objectTypeGroupID || "");
          setRelationships([]);
          setPageStatus("New");
        } else {
          setPageStatus("Ready");
        }
      }
    };
    if (auth.isLoggedIn) {
      fetchData();
    }
    if (pageStatus === "Submitting") {
      setPageStatus("Ready");
    }
  }, [auth.isLoggedIn]);
  return Access.checkAccess(
    app.permissions_LEGACY,
    Permissions.CodeUserSettings,
    Permissions.TypeRead,
    false,
  ) ? (
    pageStatus !== "Loading" ? (
      <LegacyScreenContainer
        pageTitle={
          selectedObjectType.ObjectTypeName !== ""
            ? `${
                !selectedObjectType
                  ? "Fields"
                  : selectedObjectType.ObjectTypeGroupName
              }: ${selectedObjectType.ObjectTypeName}`
            : "New Page"
        }
        breadcrumbs={[{ label: "Pages", link: "/settings/pages" }]}>
        <>
          <>
            {/* <Header
              breadcrumbs={[
                { title: "Settings", link: "" },
                { title: "Pages & Fields" },
                { title: "Pages", link: "/settings/pages" },
                pageStatus === "New"
                  ? {
                      title: `New ${capitalizeFirstLetter(
                        selectedObjectType.ObjectTypeGroupName,
                      )}`,
                    }
                  : !selectedObjectType
                  ? { title: "Settings" }
                  : { title: selectedObjectType.ObjectTypeName },
                selectedObjectType && pageStatus !== "New"
                  ? { title: "Settings" }
                  : {},
              ]}
              title="Page Settings"
            /> */}
            {pageStatus !== "New" && (
              <DropdownButton
                id="pageSelect"
                disabled={pageStatus === "New"}
                title="Select Page"
                onSelect={async (selected: any) => {
                  setObjectType(
                    selected,
                    objectTypes,
                    customFields,
                    relationships,
                  );
                }}>
                {objectTypes.map((requirement: any, index: any) => (
                  <Dropdown.Item
                    eventKey={requirement.ObjectTypeID}
                    key={
                      index
                    }>{`${requirement.ObjectTypeGroupName}:  ${requirement.ObjectTypeName}`}</Dropdown.Item>
                ))}
              </DropdownButton>
            )}
            <Row>
              <Col>
                <div style={{ height: "25px" }}>{}</div>
              </Col>
            </Row>

            {selectedObjectType || pageStatus === "New" ? (
              <section className="card">
                <Row>
                  <Col sm="10">
                    <h1>Page Settings</h1>
                  </Col>
                  <Col sm="2">
                    {pageStatus === "New" ? null : pageStatus !== "Editing" ? (
                      Access.checkAccess(
                        app.permissions_LEGACY,
                        Permissions.CodeRequirement,
                        Permissions.TypeUpdate,
                      ) ? (
                        <Button
                          type="button"
                          variant="outline-dark"
                          disabled={pageStatus === "New"}
                          onClick={() => setPageStatus("Editing")}>
                          <Feather.Edit2 className="feather" size="16" />
                          Edit
                        </Button>
                      ) : (
                        <OverlayTrigger
                          placement="auto"
                          overlay={
                            <Tooltip id="tooltip-not-authorised-to-edit">
                              You do not have permission to edit this
                            </Tooltip>
                          }>
                          <Button
                            type="button"
                            variant="outline-dark"
                            className="disabled">
                            <Feather.Edit2 className="feather" size="16" />
                            Edit
                          </Button>
                        </OverlayTrigger>
                      )
                    ) : canDelete.filter(
                        (usedObjectType: any) =>
                          selectedObjectType.ObjectTypeID ===
                          usedObjectType.ObjectTypeID,
                      ).length > 0 ? (
                      <DropdownButton
                        id="dropdown-basic-button"
                        variant="outline-dark"
                        title="Delete">
                        <Dropdown.Item
                          as="button"
                          onClick={async () => {
                            const reply = await del(
                              `settings/objecttype/${selectedObjectType.ObjectTypeID}`,
                            );
                            if (reply.status !== 200 || !reply.data) {
                              displayToast({
                                status: "danger",
                                title: `Failed to delete ${selectedObjectType.ObjectTypeName}`,
                              });
                            } else {
                              displayToast({
                                status: "success",
                                title: `${toProperCase(
                                  selectedObjectType.ObjectTypeName,
                                )} deleted successfully`,
                              });
                              navigate(`/settings/pages`);
                            }
                          }}>
                          Confirm delete
                        </Dropdown.Item>
                      </DropdownButton>
                    ) : (
                      <OverlayTrigger
                        placement="auto"
                        overlay={
                          <Tooltip id="tooltip-not-authorised-to-edit">
                            You cannot delete a Page that is being used
                            elsewhere
                          </Tooltip>
                        }>
                        <Button
                          type="button"
                          variant="outline-dark"
                          className="disabled">
                          Delete
                        </Button>
                      </OverlayTrigger>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <hr />
                  </Col>
                </Row>
                <Formik
                  enableReinitialize
                  validationSchema={schema}
                  initialValues={selectedObjectType}
                  onSubmit={async (values, actions) => {
                    if (
                      !checkUniquenessOfPageCode(
                        values.ObjectTypeID,
                        values.ObjectTypeCode,
                        objectTypes,
                      )
                    ) {
                      actions.setSubmitting(false);
                    } else {
                      let reply: any = {};
                      const newRel = relationships.filter(
                        (el: any) => el.ObjectTypeID === values.ObjectTypeID,
                      );
                      if (values.ObjectTypeID !== "") {
                        reply = await post(
                          `settings/objecttype/${values.ObjectTypeID}`,
                          { ...values, Relationships: newRel },
                        );
                      } else {
                        values.RoleSelected = values.RoleSelected
                          ? values.RoleSelected
                          : values.Roles[0].RoleID;
                        reply = await put(`settings/objecttype`, {
                          ...values,
                          Relationships: newRel,
                        });
                      }
                      setPageStatus("Loading");

                      if (reply.status !== 200 || !reply.data) {
                        displayToast({
                          status: "error",
                          title: `Failed to ${
                            values.ObjectTypeID !== "" ? "update" : "create"
                          } ${values.ObjectTypeGroupName}`,
                        });
                      } else {
                        setCustomFields(reply.data.newobjtypes.customFields);
                        setObjectTypes(
                          reply.data.newobjtypes.objectTypeInformation,
                        );
                        setCanDelete(reply.data.newobjtypes.canDelete);
                        setIcons(
                          Object.keys(Feather)
                            .filter((icon: string) => icon !== "EyeOff")
                            .map((icon: any) => {
                              const IconType: keyof typeof Feather = icon;
                              const Icon = Feather[IconType];
                              const returnObj: any = [IconType, <Icon />];
                              return returnObj;
                            }),
                        );
                        setObjectType(
                          reply.data.ObjectTypeID,
                          reply.data.newobjtypes.objectTypeInformation,
                          reply.data.newobjtypes.customFields,
                          relationships,
                        );
                        setRelationships(reply.data.newobjtypes.Relationships);
                        setPageStatus("Ready");
                        displayToast({
                          status: "success",
                          title: `${toProperCase(values.ObjectTypeGroupName)} ${
                            values.ObjectTypeID !== "" ? "updated" : "created"
                          } successfully`,
                        });
                        if (values.ObjectTypeID !== "") {
                          window.location.reload();
                        } else {
                          window.location.replace(
                            `/settings/pages/settings?objecttype=${reply.data.ObjectTypeID}`,
                          );
                        }
                      }
                    }
                  }}
                  render={(formikProps) => (
                    <Form onSubmit={formikProps.handleSubmit}>
                      {pageStatus !== "New" ? (
                        <Form.Group as={Row}>
                          <Form.Label column sm="2">
                            {" "}
                            Group{" "}
                          </Form.Label>
                          <Col sm="8" xs="6">
                            <Text
                              value={formikProps.values.ObjectTypeGroupName}
                              name="ObjectTypeGroupID"
                              onUpdate={formikProps.handleChange}
                              readOnly
                            />
                          </Col>
                          <Col>
                            <HelpText
                              id="GroupHelpText"
                              text="The group this new page will belong to"
                            />
                          </Col>
                        </Form.Group>
                      ) : (
                        <Form.Group as={Row}>
                          <Form.Label column sm="2">
                            {" "}
                            Group{" "}
                          </Form.Label>

                          <Col sm="8" xs="6">
                            <Select
                              value={formikProps.values.ObjectTypeGroupID}
                              name="ObjectTypeGroupID"
                              onUpdate={(e: any) => {
                                window.location.replace(
                                  `/settings/pages/settings?new=New&type=${e.value}`,
                                );
                              }}
                              readOnly={false}
                              optionsList={[
                                [ObjectTypeGroupID.Action, "Action"],
                                [ObjectTypeGroupID.Issue, "Issue"],
                                [ObjectTypeGroupID.Requirement, "Requirement"],
                                [ObjectTypeGroupID.Register, "Register"],
                                [ObjectTypeGroupID.Risk, "Risk"],
                              ]}
                              optionsListID="0"
                              optionsListValue="1"
                              unselectedOption="Select a Value"
                            />
                          </Col>
                          <Col>
                            <HelpText
                              id="GroupHelpText"
                              text="The group this Page belongs to"
                            />
                          </Col>
                        </Form.Group>
                      )}

                      <Form.Group as={Row}>
                        <Form.Label column sm="2">
                          Name
                        </Form.Label>

                        <Col sm="8" xs="6">
                          <Text
                            value={formikProps.values.ObjectTypeName}
                            name="ObjectTypeName"
                            onUpdate={formikProps.handleChange}
                            readOnly={
                              pageStatus !== "Editing" && pageStatus !== "New"
                            }
                          />
                        </Col>
                        <Col>
                          <HelpText
                            id="NameHelpText"
                            text="The name of the Page "
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm="2">
                          Plural Name
                        </Form.Label>

                        <Col sm="8" xs="6">
                          <Text
                            value={formikProps.values.ObjectTypeNamePlural}
                            name="ObjectTypeNamePlural"
                            onUpdate={formikProps.handleChange}
                            readOnly={
                              pageStatus !== "Editing" && pageStatus !== "New"
                            }
                          />
                        </Col>
                        <Col>
                          <HelpText
                            id="PluralNameHelpText"
                            text="The plural name for the Page"
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="2">
                          Description
                        </Form.Label>

                        <Col sm="8" xs="6">
                          <Text
                            value={formikProps.values.ObjectTypeDescription}
                            name="ObjectTypeDescription"
                            onUpdate={formikProps.handleChange}
                            readOnly={
                              pageStatus !== "Editing" && pageStatus !== "New"
                            }
                          />
                        </Col>
                        <Col>
                          <HelpText
                            id="DescriptionHelpText"
                            text="The description of the page"
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="2">
                          {" "}
                          Code{" "}
                        </Form.Label>

                        <Col sm="8" xs="6">
                          <Text
                            value={formikProps.values.ObjectTypeCode}
                            name="ObjectTypeCode"
                            onUpdate={formikProps.handleChange}
                            readOnly={
                              pageStatus !== "Editing" && pageStatus !== "New"
                            }
                            max={3}
                          />
                        </Col>
                        <Col>
                          <HelpText
                            id="CodeHelpText"
                            text="The 3 letter code that will be used to abbreviate this Page"
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="2">
                          {" "}
                          Icon{" "}
                        </Form.Label>
                        {pageStatus !== "Editing" && pageStatus !== "New" ? (
                          <Col sm="1" xs="2">
                            {
                              icons.find(
                                (icon: any) =>
                                  icon[0] === formikProps.values.ObjectTypeIcon,
                              )[1]
                            }
                          </Col>
                        ) : null}
                        <Col sm={pageStatus !== "Editing" ? "7" : "8"} xs="4">
                          <Select
                            value={formikProps.values.ObjectTypeIcon}
                            name="ObjectTypeIcon"
                            onUpdate={(e: any) => {
                              formikProps.setValues({
                                ...formikProps.values,
                                ObjectTypeIcon: e.value,
                              });
                            }}
                            readOnly={
                              pageStatus !== "Editing" && pageStatus !== "New"
                            }
                            optionsList={icons}
                            optionsListID="0"
                            optionsListValue="0"
                            readOnlyValue={formikProps.values.ObjectTypeIcon}
                            unselectedOption={formikProps.values.ObjectTypeIcon}
                            icon
                          />
                        </Col>
                        <Col>
                          <HelpText
                            id="IconHelpText"
                            text="The icon that will appear next to Pages of this type in the navigation"
                          />
                        </Col>
                      </Form.Group>

                      {formikProps.values.ObjectTypeGroupID ===
                      ObjectTypeGroupID.Action ? (
                        <Form.Group as={Row} noGutters>
                          <Form.Label column sm="2" xs="12">
                            Task Window: &nbsp;{" "}
                          </Form.Label>
                          <Col sm="1" xs="3">
                            <Number
                              value={formikProps.values.ObjectTypeWindowBefore}
                              name="ObjectTypeWindowBefore"
                              onUpdate={formikProps.handleChange}
                              readOnly={
                                pageStatus !== "Editing" && pageStatus !== "New"
                              }
                              style={{
                                width: 50,
                                padding: "0px",
                                textAlign: "center",
                              }}
                            />
                          </Col>
                          <Form.Label
                            column
                            style={{ textAlign: "right" }}
                            sm="1"
                            xs="4">
                            {" "}
                            Days Before &nbsp;{" "}
                          </Form.Label>
                          <Col sm="1" xs="4" />
                          <Col sm="1" xs="3">
                            <Number
                              value={formikProps.values.ObjectTypeWindowAfter}
                              name="ObjectTypeWindowAfter"
                              onUpdate={formikProps.handleChange}
                              readOnly={
                                pageStatus !== "Editing" && pageStatus !== "New"
                              }
                              style={{
                                width: 50,
                                padding: "0px",
                                textAlign: "center",
                              }}
                            />
                          </Col>
                          <Form.Label
                            column
                            style={{ textAlign: "left" }}
                            sm="1"
                            xs="4">
                            Days After
                          </Form.Label>
                          <Col xs="1" sm="3" />

                          <Col sm="1" style={{ textAlign: "center" }}>
                            <HelpText
                              id="WindowHelpText"
                              text="The period of time in which an Task can be responded to"
                            />
                          </Col>
                        </Form.Group>
                      ) : null}
                      {formikProps.values.ObjectTypeGroupID !==
                      ObjectTypeGroupID.Register ? (
                        <Form.Group as={Row}>
                          <Form.Label column sm="2">
                            Available for{" "}
                          </Form.Label>
                          <Col sm="8" xs="6">
                            <MultiSelect
                              name="id"
                              value={
                                availableFor
                                  ? availableFor.selected.sort(
                                      (a: any, b: any) =>
                                        a.ObjectTypeGroupName.localeCompare(
                                          b.ObjectTypeGroupName,
                                          "en",
                                          { numeric: true },
                                        ),
                                    )
                                  : []
                              }
                              optionsList={
                                availableFor
                                  ? availableFor.selected
                                      .concat(availableFor.unSelected)
                                      .sort((a: any, b: any) =>
                                        a.ObjectTypeGroupName.localeCompare(
                                          b.ObjectTypeGroupName,
                                          "en",
                                          { numeric: true },
                                        ),
                                      )
                                  : []
                              }
                              optionsListID="ID"
                              optionsListValue="ObjectTypeGroupName"
                              onUpdate={(e: any[]) => {
                                if (e) {
                                  if (e.length < availableFor.selected.length) {
                                    const deletedObject: any =
                                      availableFor.selected.find(
                                        (option: any) =>
                                          e.find(
                                            (newOption: any) =>
                                              option.ID === newOption.value,
                                          ) === undefined,
                                      );
                                    const newUnselected = [
                                      ...availableFor.unSelected,
                                      deletedObject,
                                    ];

                                    const newSelected: any =
                                      availableFor.selected.filter(
                                        (option: any) =>
                                          option.ID !== deletedObject.ID,
                                      );

                                    setAvailableFor({
                                      selected: newSelected,
                                      unSelected: newUnselected,
                                    });

                                    const newRelationships: any[] =
                                      relationships.filter(
                                        (relation: any) =>
                                          relation.ObjectTypeGroupID !==
                                            deletedObject.ObjectTypeGroupID ||
                                          relation.ObjectTypeID !==
                                            deletedObject.ObjectTypeID ||
                                          relation.ObjectTypeRelationshipParent !==
                                            deletedObject.ObjectTypeRelationshipParent,
                                      );
                                    setRelationships(newRelationships);
                                  } else if (
                                    e.length > availableFor.selected.length
                                  ) {
                                    const addedObject: any =
                                      availableFor.unSelected.find(
                                        (option: any) =>
                                          e.find(
                                            (newOption: any) =>
                                              option.ID === newOption.value,
                                          ),
                                      );
                                    const newList: any[] =
                                      availableFor.unSelected.filter(
                                        (option: any) =>
                                          option.ID !== addedObject.ID,
                                      );
                                    setAvailableFor({
                                      selected: [
                                        ...availableFor.selected,
                                        addedObject,
                                      ],
                                      unSelected: newList,
                                    });
                                    const newRelationship: any = {
                                      ObjectTypeName:
                                        selectedObjectType.ObjectTypeName,
                                      ObjectTypeGroupName:
                                        addedObject.ObjectTypeGroupID ===
                                          ObjectTypeGroupID.Risk &&
                                        addedObject.ObjectTypeRelationshipParent
                                          ? "Risks"
                                          : addedObject.ObjectTypeGroupName.replace(
                                              "Sub-",
                                            ),
                                      ObjectTypeID: addedObject.ObjectTypeID,
                                      ObjectTypeGroupID:
                                        addedObject.ObjectTypeGroupID,
                                      ObjectTypeRelationshipParent:
                                        addedObject.ObjectTypeRelationshipParent,
                                    };
                                    setRelationships([
                                      ...relationships,
                                      newRelationship,
                                    ]);
                                  }
                                } else {
                                  const deletedObject: any =
                                    availableFor.selected[0];
                                  const newSelected: any =
                                    availableFor.selected.filter(
                                      (option: any) =>
                                        option.ID !== deletedObject.ID,
                                    );
                                  setAvailableFor({
                                    selected: newSelected,
                                    unSelected: [
                                      ...availableFor.unSelected,
                                      deletedObject,
                                    ],
                                  });

                                  const newRelationships: any[] =
                                    relationships.filter(
                                      (relation: any) =>
                                        relation.ObjectTypeGroupID !==
                                          deletedObject.ObjectTypeGroupID ||
                                        relation.ObjectTypeID !==
                                          deletedObject.ObjectTypeID ||
                                        relation.ObjectTypeRelationshipParent !==
                                          deletedObject.ObjectTypeRelationshipParent,
                                    );
                                  setRelationships(newRelationships);
                                }
                              }}
                              available
                              readOnlyValue={
                                availableFor
                                  ? availableFor.selected.length > 0
                                    ? availableFor.selected
                                        .map(
                                          (type: any) =>
                                            type.ObjectTypeGroupName,
                                        )
                                        .join(", ")
                                    : "Not available for any record types"
                                  : ""
                              }
                              readOnly={
                                pageStatus !== "Editing" && pageStatus !== "New"
                              }
                            />
                          </Col>
                          <Col>
                            <HelpText
                              id="AvailabilityHelpText"
                              text="The type of Records this Page will be available for"
                            />
                          </Col>
                        </Form.Group>
                      ) : null}
                      {formikProps.values.ObjectTypeGroupID ===
                      ObjectTypeGroupID.Action ? (
                        <Form.Group as={Row}>
                          <Form.Label column sm="2">
                            Flexible dates
                          </Form.Label>
                          <Col sm="8" xs="6">
                            {pageStatus !== "Editing" &&
                            pageStatus !== "New" ? (
                              <div>
                                {BooleanToYesNo(
                                  formikProps.values.ObjectTypeFlexibleDates,
                                )}
                              </div>
                            ) : (
                              <Form.Check
                                type="checkbox"
                                id="page-settings-enable-notes"
                                name="ObjectTypeFlexibleDates"
                                value={
                                  formikProps.values.ObjectTypeFlexibleDates
                                }
                                checked={
                                  formikProps.values.ObjectTypeFlexibleDates
                                }
                                onChange={formikProps.handleChange}
                              />
                            )}
                          </Col>
                          <Col>
                            <HelpText
                              id="FlexibleDatesHelpText"
                              text="Allow the dates of once off Tasks to change, even if the Task has been answered or has gone overdue"
                            />
                          </Col>
                        </Form.Group>
                      ) : null}
                      <Form.Group as={Row}>
                        <Form.Label column sm="2">
                          Notes
                        </Form.Label>
                        <Col sm="8" xs="6">
                          {pageStatus !== "Editing" && pageStatus !== "New" ? (
                            <div>
                              {BooleanToYesNo(
                                formikProps.values.ObjectTypeNotesEnabled,
                              )}
                            </div>
                          ) : (
                            <Form.Check
                              type="checkbox"
                              id="page-settings-enable-notes"
                              name="ObjectTypeNotesEnabled"
                              value={formikProps.values.ObjectTypeNotesEnabled}
                              checked={
                                formikProps.values.ObjectTypeNotesEnabled
                              }
                              onChange={formikProps.handleChange}
                            />
                          )}
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm="2">
                          Time Notes
                        </Form.Label>
                        <Col sm="8" xs="6">
                          {pageStatus !== "Editing" && pageStatus !== "New" ? (
                            <div>
                              {BooleanToYesNo(
                                formikProps.values.ObjectTypeTimeNotesEnabled,
                              )}
                            </div>
                          ) : (
                            <Form.Check
                              type="checkbox"
                              id="page-settings-enable-time-notes"
                              name="ObjectTypeTimeNotesEnabled"
                              value={
                                formikProps.values.ObjectTypeTimeNotesEnabled
                              }
                              checked={
                                formikProps.values.ObjectTypeTimeNotesEnabled
                              }
                              onChange={formikProps.handleChange}
                            />
                          )}
                        </Col>
                      </Form.Group>

                      {formikProps.values.ObjectTypeGroupID ===
                      ObjectTypeGroupID.Action ? (
                        <Form.Group as={Row}>
                          <Form.Label column sm="2">
                            Support Risk Questions
                          </Form.Label>
                          <Col sm="8" xs="6">
                            {pageStatus !== "Editing" &&
                            pageStatus !== "New" ? (
                              <div>
                                {BooleanToYesNo(
                                  formikProps.values
                                    .ObjectTypeRiskQuestionsSupported,
                                )}
                              </div>
                            ) : (
                              <Form.Check
                                type="checkbox"
                                id="page-settings-support-risk-questions"
                                name="ObjectTypeRiskQuestionsSupported"
                                value={
                                  formikProps.values
                                    .ObjectTypeRiskQuestionsSupported
                                }
                                checked={
                                  formikProps.values
                                    .ObjectTypeRiskQuestionsSupported
                                }
                                disabled={
                                  !relationships.find(
                                    (relation: any) =>
                                      relation.ObjectTypeID ===
                                        formikProps.values.ObjectTypeID &&
                                      relation.ObjectTypeGroupID ===
                                        ObjectTypeGroupID.Risk,
                                  )
                                }
                                onChange={(e: any) => {
                                  formikProps.handleChange(e);
                                  setRelationships(
                                    relationships.filter((relation: any) =>
                                      relation.ObjectTypeID !==
                                      formikProps.values.ObjectTypeID
                                        ? true
                                        : relation.ObjectTypeGroupID ===
                                          ObjectTypeGroupID.Risk,
                                    ),
                                  );
                                }}
                              />
                            )}
                          </Col>
                          <Col>
                            <HelpText
                              id="SupportRiskQuestionsHelpText"
                              text="Will Risk Questions be supported for this new Action Page?"
                            />
                          </Col>
                        </Form.Group>
                      ) : null}

                      <Form.Group as={Row}>
                        <Form.Label column sm="2">
                          Available for Mobile
                        </Form.Label>
                        <Col sm="8" xs="6">
                          {pageStatus !== "Editing" && pageStatus !== "New" ? (
                            <div>
                              {BooleanToYesNo(
                                formikProps.values.ObjectTypeAvailableForMobile,
                              )}
                            </div>
                          ) : (
                            <Form.Check
                              type="checkbox"
                              id="page-settings-available-for-mobile"
                              name="ObjectTypeAvailableForMobile"
                              value={
                                formikProps.values.ObjectTypeAvailableForMobile
                              }
                              checked={
                                formikProps.values.ObjectTypeAvailableForMobile
                              }
                              onChange={formikProps.handleChange}
                            />
                          )}
                        </Col>
                      </Form.Group>

                      {pageStatus === "New" ? (
                        <Form.Group as={Row}>
                          <Form.Label column style={{ paddingLeft: 0 }} sm="2">
                            Role for full permissions
                          </Form.Label>

                          <Col sm="8">
                            <Select
                              value={
                                formikProps.values.RoleSelected
                                  ? formikProps.values.RoleSelected
                                  : formikProps.values.Roles[0].RoleID
                              }
                              name="RoleSelected"
                              onUpdate={(event) => {
                                formikProps.setValues({
                                  ...formikProps.values,
                                  RoleSelected: event.value,
                                });
                              }}
                              optionsList={formikProps.values.Roles}
                              optionsListID="RoleID"
                              readOnly={
                                pageStatus !== "Editing" && pageStatus !== "New"
                              }
                              optionsListValue="RoleName"
                              disabled={formikProps.values.Roles.length < 2}
                            />
                          </Col>
                          <Col sm="1">
                            <HelpText
                              text={`Permissions are used to control who can see this new ${formikProps.values.ObjectTypeGroupName} Page. To get started pick the role that you would like to assign full permission to. You can configure more complex Permissions on the Permission Settings page after creation of the Page.`}
                              id="PermissionsHelpText"
                            />
                          </Col>
                        </Form.Group>
                      ) : null}
                      {pageStatus === "Editing" || pageStatus === "New" ? (
                        <Form.Group as={Row}>
                          <Col sm="2" />
                          <Col sm="auto">
                            <Button
                              type="submit"
                              onClick={() => {
                                if (
                                  formikProps.values.ObjectTypeName === "" &&
                                  formikProps.values.ObjectTypeDescription ===
                                    "" &&
                                  formikProps.values.ObjectTypeNamePlural ===
                                    "" &&
                                  formikProps.values.ObjectTypeCode === ""
                                ) {
                                  const title = `Failed to ${
                                    pageStatus === "New" ? "create" : "update"
                                  } ${formikProps.values.ObjectTypeGroupName}`;
                                  const description = `"Name is required" \n "Description is required" \n "A plural name is required" \n"A code is required" \n "An icon is required"`;
                                  displayToast({
                                    status: "error",
                                    title,
                                    description,
                                  });
                                } else {
                                  const extraErrors = [];
                                  if (
                                    !checkUniquenessOfPageCode(
                                      formikProps.values.ObjectTypeID,
                                      formikProps.values.ObjectTypeCode,
                                      objectTypes,
                                    )
                                  ) {
                                    extraErrors.push(
                                      '"The entered code is used by another page. Unique code is required"',
                                    );
                                  }
                                  const errors = JSON.stringify(
                                    formikProps.errors,
                                  )
                                    .replace("{", "")
                                    .replace("}", "")
                                    .split(",");

                                  let errorMessage: string = errors
                                    .map(
                                      (e: string, index: number) =>
                                        (errors[index] = e
                                          .substring(e.lastIndexOf(":") + 1)
                                          .replace("}", "")),
                                    )
                                    .join("\n");
                                  errorMessage = errorMessage
                                    .concat("\n")
                                    .concat(extraErrors.join("\n"));

                                  if (
                                    (errors.length > 0 && errors[0] !== "") ||
                                    extraErrors.length > 0
                                  ) {
                                    displayToast({
                                      status: "error",
                                      title: `Failed to ${
                                        pageStatus === "New"
                                          ? "create"
                                          : "update"
                                      } ${
                                        formikProps.values.ObjectTypeGroupName
                                      }`,
                                      description: `${errorMessage}`,
                                    });
                                  }
                                }
                              }}>
                              Submit
                            </Button>
                          </Col>

                          <Col sm="auto">
                            <Button
                              type="button"
                              variant="light"
                              onClick={() => {
                                if (pageStatus !== "New") {
                                  setData(data);
                                  formikProps.resetForm();
                                  setPageStatus("Ready");
                                } else {
                                  navigate("/settings/pages");
                                }
                              }}>
                              Cancel{" "}
                            </Button>
                          </Col>
                        </Form.Group>
                      ) : null}
                    </Form>
                  )}
                />
                {pageStatus !== "New" ? (
                  <Link
                    to={`/settings/pages/fields?objecttype=${selectedObjectType.ObjectTypeID}`}>
                    {" "}
                    Manage the Custom Fields for this page
                  </Link>
                ) : null}
                {pageStatus !== "New" ? (
                  <Link
                    to={`/settings/pages/documents?objecttype=${selectedObjectType.ObjectTypeID}`}>
                    {" "}
                    Manage the Template Documents for this page
                  </Link>
                ) : null}
              </section>
            ) : null}
          </>
          <section />
        </>
      </LegacyScreenContainer>
    ) : (
      <div className="progress-spinner" style={{ marginTop: "20%" }}>
        <Loading size="xl" />
      </div>
    )
  ) : (
    <></>
  );
};

const BooleanToYesNo = (checked: boolean) => {
  if (checked) {
    return "Yes";
  }
  return "No";
};

export { ScreensPageSettings };
