import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import * as Feather from "react-feather";
import {
  Link,
  NavigateFunction,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import * as Yup from "yup";
import { CoreAppInfo, useAppState } from "../../components/App/AppProvider";
import { CheckType } from "../../components/Forms/Field/Radio";
import { Select } from "../../components/Forms/Field/Select";
import { Text } from "../../components/Forms/Field/Text";
import { HelpText } from "../../components/Forms/HelpText/HelpText";
import { Header } from "../../components/UI/Header/Header";
import LegacyScreenContainer from "../../components/UI/LegacyScreenContainer";
import { Loading } from "../../components/UI/Loading/Loading";
import * as CustField from "../../constants/customFieldType";
import {
  CustomFieldIDToName,
  CustomFieldNameToID,
} from "../../constants/customFieldType";
import * as Permissions from "../../shared/constants/permission";
import * as Access from "../../utilities/access";
import { del, get, post, put } from "../../utilities/request";
import { getUrlSearchParam } from "../../utilities/url";
import useToast, { DisplayToastFn } from "../../utilities/useToast";
import { AccountPageStatus } from "../Settings/Account.d";
import { AuthState, useAuth } from "../../utilities/useAuth";

const validationSchema: any = Yup.object().shape({
  CustomFieldLabel: Yup.string().required("Custom Field Label is required."),
});
const listValidationSchema = Yup.object().shape({
  CustomFieldLabel: Yup.string().required("Custom Field Label is required."),
  ListID: Yup.string().required("Must specify a chosen list."),
});
const deleteCustomField = async (
  data: any,
  setPageStatus: any,
  displayToast: DisplayToastFn,
  getToken: AuthState["getToken"],
  navigate: NavigateFunction,
) => {
  setPageStatus("Submitting");
  const reply = await del(`customfields/${data.CustomFieldID}`, getToken);

  if (reply.status !== 200 || !reply.data) {
    displayToast({
      status: "error",
      title: `Failed to delete Custom Field`,
    });
  } else {
    displayToast({
      status: "success",
      title: `Custom Field deleted successfully `,
    });
    setTimeout(() => navigate(`/settings/customfields`), 1500);
  }
};

// check if the document tag is unique
const checkUniquenessOfDocTag = (
  newDocTag: any,
  cfID: any,
  currentDocTags: string[],
) => {
  const check = currentDocTags.find(
    (docTag: any) => newDocTag === docTag.DocumentTagName && cfID !== docTag.ID,
  );
  if (check) {
    return false;
  }
  return true;
};

const initialCustomField = {
  Name: "",
  Type: "",
  Units: "",
  HelpText: "",
  Mandatory: 0,
  List: {
    ListValues: [],
  },
};

const ScreensSingleCustomField = (): JSX.Element => {
  const { getToken } = useAuth();
  const { app, auth } = useAppState();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [pageStatus, setPageStatus] = useState<any>("Loading");
  const [data, setData] = useState<any>(null);
  const { displayToast } = useToast();

  const customFieldID = params.customFieldID || "";

  const deleteArray: any[] = [];

  useEffect(() => {
    const fetchRequirement = async () => {
      setPageStatus("Loading");

      // api call
      try {
        const info: any = await get(
          `customfield/${customFieldID}${
            location.search ? location.search : ""
          }`,
          getToken,
        );
        if (info.status !== 200 || !info.data) {
          setData(info.data);
          setPageStatus("Ready");
        } else if (info.data.CustomFieldID === undefined) {
          const type = getUrlSearchParam(location.search, "type");
          let newData: any = {};

          if (
            type === CustField.CustomFieldNameToID.List ||
            type === CustField.CustomFieldNameToID.MultiList
          ) {
            newData = {
              ...info.data,
              List: {},
              ListID: "",
              selectedList: {
                ListID: "",
                ListName: "",
                ListTypeColumnName: "",
                ListTypeID: "",
                ListTypeName: "",
              },
            };

            newData.CustomFieldTypeID = type;
            setData(newData);
          } else {
            info.data.CustomFieldTypeID = type;
            setData(info.data);
          }

          setPageStatus("New");
        } else {
          setData(info.data);
          setPageStatus("Ready");
        }
      } catch (ex) {
        navigate(`/settings/customfields`);
      }
    };
    fetchRequirement();
  }, []);

  return (
    <section>
      {renderPage(
        app,
        getToken,
        navigate,
        pageStatus,
        deleteArray,
        setPageStatus,
        data,
        setData,
        displayToast,
      )}
    </section>
  );
};

const renderPage = (
  app: CoreAppInfo,
  getToken: AuthState["getToken"],
  navigate: NavigateFunction,
  pageStatus: any,
  deleteArray: any[],
  setPageStatus: React.Dispatch<React.SetStateAction<AccountPageStatus>>,
  data: any,
  setData: any,
  displayToast: DisplayToastFn,
) => {
  if (pageStatus === "Loading") {
    return (
      <>
        <div className="progress-spinner" style={{ marginTop: "20%" }}>
          <Loading size={"xl"} />
        </div>
      </>
    );
  }
  return Access.checkAccess(
    app.permissions_LEGACY,
    Permissions.CodeUserSettings,
    Permissions.TypeRead,
    false,
  ) ? (
    <LegacyScreenContainer
      pageTitle={
        data.CustomFieldLabel !== ""
          ? data.CustomFieldLabel
          : "New Custom Field"
      }
      breadcrumbs={[
        { label: "Custom Fields", link: "/settings/customfields" },
      ]}>
      <>
        {/* <Header
          title="Custom Field"
          breadcrumbs={[
            { title: "Settings", link: "/settings" },
            { title: "Pages & Fields" },
            { title: "Custom Fields", link: "/settings/customfields" },
            {
              title:
                data.CustomFieldLabel !== ""
                  ? data.CustomFieldLabel
                  : "New Custom Field",
            },
          ]}
        /> */}
        <section className="card">
          <Row>
            <Col sm="10">
              <h1>Details</h1>
            </Col>
            <Col sm="2">
              {pageStatus === "New" ? null : pageStatus !== "Editing" ? (
                /* Needs to be changed once the permissions work for things other than Requirements. Will check to see
                             if a user has permission to Edit CustomFields */
                Access.checkAccess(
                  app.permissions_LEGACY,
                  Permissions.CodeUserSettings,
                  Permissions.TypeUpdate,
                ) ? (
                  <Button
                    type="button"
                    variant="outline-dark"
                    onClick={() => setPageStatus("Editing")}>
                    <Feather.Edit2 className="feather" size="16" />
                    Edit
                  </Button>
                ) : (
                  <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip id="tooltip-not-authorised-to-edit">
                        You do not have permission to edit this
                      </Tooltip>
                    }>
                    <Button
                      type="button"
                      variant="outline-dark"
                      className="disabled">
                      <Feather.Edit2 className="feather" size="16" />
                      Edit
                    </Button>
                  </OverlayTrigger>
                )
              ) : data.ObjectTypes.length === 0 &&
                data.ActionQuestionUsage.length === 0 ? (
                <DropdownButton
                  id="dropdown-basic-button"
                  variant="outline-dark"
                  title="Delete">
                  <Dropdown.Item
                    as="button"
                    onClick={() =>
                      deleteCustomField(
                        data,
                        setPageStatus,
                        displayToast,
                        getToken,
                        navigate,
                      )
                    }>
                    Confirm delete
                  </Dropdown.Item>
                </DropdownButton>
              ) : (
                <OverlayTrigger
                  placement="auto"
                  overlay={
                    <Tooltip id="tooltip-not-authorised-to-delete">
                      You cannot delete a Custom Field that is being used in a
                      Page or a Custom Question
                    </Tooltip>
                  }>
                  <Button
                    type="button"
                    variant="outline-dark"
                    className="disabled">
                    Delete
                  </Button>
                </OverlayTrigger>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <hr />
            </Col>
          </Row>
          <Formik
            validationSchema={
              data.CustomFieldTypeID === CustomFieldNameToID.List ||
              data.CustomFieldTypeID === CustomFieldNameToID.MultiList
                ? listValidationSchema
                : validationSchema
            }
            enableReinitialize
            initialValues={data}
            onSubmit={async (values, actions) => {
              let reply: any = {};
              if (
                values.CustomFieldLabel === "" ||
                !checkUniquenessOfDocTag(
                  values.DocumentTagName,
                  values.CustomFieldID,
                  values.DocumentTagNames,
                )
              ) {
                actions.setSubmitting(false);
              } else if (pageStatus === "New") {
                setPageStatus("Submitting");
                const newData = {
                  ...values,
                  selectedList: values.Lists.find(
                    (listItem: any) => listItem.ListID === values.ListID,
                  ),
                };
                setData(newData);
                reply = await put("customfields", values, getToken);
                if (reply.status !== 200) {
                  displayToast({
                    status: "error",
                    title: `Failed to create Custom Field`,
                  });
                } else {
                  setData({ ...newData, CustomFieldID: reply.data });

                  // Update document tags, couldnt do this until we had a customFieldID
                  const updateDocumentTag = await put(
                    "settings/pages/documenttags",
                    { ...values, CustomFieldID: reply.data },
                    getToken,
                  );
                  console.log("updateDocumentTag", updateDocumentTag);

                  displayToast({
                    status: "success",
                    title: `Custom Field created successfully`,
                  });
                }
                navigate(`/settings/customfield/${reply.data}`);
                setPageStatus("Ready");
              } else {
                setPageStatus("Submitting");
                if (
                  CustomFieldIDToName[data.CustomFieldTypeID] ===
                    "Single Select List" ||
                  CustomFieldIDToName[data.CustomFieldTypeID] ===
                    "Multi-select List"
                ) {
                  const newData = {
                    ...values,
                    selectedList: values.Lists.find(
                      (listItem: any) => listItem.ListID === values.ListID,
                    ),
                  };

                  setData(newData);
                } else {
                  setData(values);
                }

                reply = await post(
                  `customfields/${data.CustomFieldID}`,
                  values,
                  getToken,
                );
                if (reply.status !== 200) {
                  displayToast({
                    status: "danger",
                    title: `Failed to update Custom Field`,
                  });
                } else {
                  const updateDocumentTag = await put(
                    "settings/pages/documenttags",
                    values,
                    getToken,
                  );
                  console.log("updateDocumentTag", updateDocumentTag);

                  displayToast({
                    status: "success",
                    title: `Custom Field updated successfully`,
                  });
                }

                setPageStatus("Ready");
              }
            }}
            render={(formikProps) => (
              <Form onSubmit={formikProps.handleSubmit}>
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Label
                  </Form.Label>

                  <Col sm="6">
                    <Text
                      value={formikProps.values.CustomFieldLabel}
                      name="CustomFieldLabel"
                      onUpdate={formikProps.handleChange}
                      readOnly={
                        pageStatus !== "Editing" && pageStatus !== "New"
                      }
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Type
                  </Form.Label>

                  <Col sm="6">
                    <Text
                      value={data.CustomFieldTypeName}
                      name="ListTypeID"
                      onUpdate={formikProps.handleChange}
                      readOnly
                    />
                  </Col>
                </Form.Group>
                {data.CustomFieldTypeID === CustomFieldNameToID.List ||
                data.CustomFieldTypeID === CustomFieldNameToID.MultiList ? (
                  <>
                    {pageStatus === "Editing" || pageStatus === "New" ? (
                      <Form.Group as={Row}>
                        <Form.Label column sm="2">
                          {" "}
                          Chosen List
                        </Form.Label>
                        <Col sm="6">
                          <Select
                            unselectedOption="Select a Value"
                            onUpdate={(e: any) => {
                              if (
                                data.ObjectTypes.length != 0 ||
                                data.ActionQuestionUsage.length != 0
                              ) {
                                const confirmResult = window.confirm(
                                  "This List is being used in a Page or a Custom Question, changing the list will result in the old values being lost, are you sure you want to continue?",
                                );
                                if (confirmResult) {
                                  formikProps.setValues({
                                    ...formikProps.values,
                                    ListID: e.value,
                                  });
                                }
                              } else {
                                formikProps.setValues({
                                  ...formikProps.values,
                                  ListID: e.value,
                                });
                              }
                            }}
                            name="ListID"
                            value={formikProps.values.ListID}
                            optionsList={formikProps.values.Lists}
                            readOnly={
                              pageStatus !== "Editing" && pageStatus !== "New"
                            }
                            optionsListID="ListID"
                            optionsListValue="ListName"
                            readOnlyValue={
                              formikProps.values.selectedList.ListName
                            }
                            disabled={false}
                          />
                        </Col>
                      </Form.Group>
                    ) : (
                      <Form.Group as={Row}>
                        <Form.Label column sm="2">
                          Chosen List
                        </Form.Label>
                        <Col sm="6">
                          <Link
                            to={
                              formikProps.values.selectedList
                                .ListTypeColumnName !== "DocumentID"
                                ? `/settings/list/${formikProps.values.selectedList.ListID}`
                                : "/settings/list/documents"
                            }>
                            {formikProps.values.selectedList.ListName}
                          </Link>
                        </Col>
                      </Form.Group>
                    )}
                  </>
                ) : null}

                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Help Text
                  </Form.Label>

                  <Col sm="6">
                    <Text
                      value={formikProps.values.CustomFieldHelpText}
                      name="CustomFieldHelpText"
                      onUpdate={formikProps.handleChange}
                      readOnly={
                        pageStatus !== "Editing" && pageStatus !== "New"
                      }
                    />
                  </Col>
                </Form.Group>
                {data.CustomFieldTypeID !== CustomFieldNameToID.LongText &&
                data.CustomFieldTypeID !== CustomFieldNameToID.Link &&
                data.CustomFieldTypeID !== CustomFieldNameToID.Upload ? (
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Units
                    </Form.Label>

                    <Col sm="6">
                      <Text
                        value={
                          formikProps.values.CustomFieldUnitLabel
                            ? formikProps.values.CustomFieldUnitLabel
                            : ""
                        }
                        name="CustomFieldUnitLabel"
                        onUpdate={formikProps.handleChange}
                        readOnly={
                          pageStatus !== "Editing" && pageStatus !== "New"
                        }
                      />
                    </Col>
                  </Form.Group>
                ) : null}
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Mandatory
                  </Form.Label>

                  <Col sm="6">
                    <CheckType
                      value={
                        formikProps.values.CustomFieldUnitLabel
                          ? formikProps.values.CustomFieldUnitLabel
                          : "-"
                      }
                      name="CustomFieldMandatory"
                      onChange={formikProps.handleChange}
                      disabled={
                        pageStatus !== "Editing" && pageStatus !== "New"
                      }
                      checked={
                        formikProps.values.CustomFieldMandatory
                          ? formikProps.values.CustomFieldMandatory
                          : false
                      }
                      label=""
                      id="CustomFieldMandatory"
                      type="checkbox"
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Usage
                  </Form.Label>
                  {(formikProps.values.ObjectTypes.length > 0 ||
                    data.ActionQuestionUsage.length > 0) &&
                  pageStatus !== "New" ? (
                    <Col sm="6">
                      {data.ObjectTypes.map(
                        (objectType: any, index: number) => {
                          if (index < 1) {
                            return (
                              <Link
                                to={`/settings/pages/fields?objecttype=${objectType.ObjectTypeID}`}
                                target="_blank">
                                {" "}
                                {objectType.ObjectTypeName}{" "}
                              </Link>
                            );
                          }
                          return (
                            <>
                              {" "}
                              <text>{", "} </text>
                              <Link
                                to={`/settings/pages/fields?objecttype=${objectType.ObjectTypeID}`}
                                target="_blank">
                                {objectType.ObjectTypeName}{" "}
                              </Link>
                            </>
                          );
                        },
                      )}
                      {data.ActionQuestionUsage.map(
                        (action: any, index: number) => (
                          <>
                            {" "}
                            {index === 0 &&
                            formikProps.values.ObjectTypes.length ===
                              0 ? null : (
                              <text>{", "}</text>
                            )}
                            <Link
                              to={`/action/${action.ActionID}`}
                              target="_blank">
                              {action.ActionReadableID}
                            </Link>
                          </>
                        ),
                      )}
                    </Col>
                  ) : (
                    <Col sm="6">
                      Not currently used on any pages{" "}
                      {pageStatus !== "New" ? (
                        <Link to="/settings/pages"> - Add it now</Link>
                      ) : null}{" "}
                    </Col>
                  )}
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Document Tag
                  </Form.Label>
                  <Col sm="6">
                    <Row>
                      <Col>
                        <Text
                          value={formikProps.values.DocumentTagName}
                          name="DocumentTagName"
                          onUpdate={formikProps.handleChange}
                          readOnly={
                            !(pageStatus === "Editing" || pageStatus === "New")
                          }
                        />
                      </Col>
                      <Col>
                        <HelpText
                          id="CustomFieldTagHelp"
                          text="Define the tag for Document Templates to be replaced with the value of this field - e.g. {MyTag}"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>

                {pageStatus === "Editing" ||
                pageStatus === "Submitting" ||
                pageStatus === "New" ? (
                  <>
                    <Form.Group as={Row}>
                      <Col sm="2" />
                      <Col sm="auto">
                        <Button
                          type="submit"
                          onClick={() => {
                            const extraErrors = [];
                            if (
                              !checkUniquenessOfDocTag(
                                formikProps.values.DocumentTagName,
                                formikProps.values.CustomFieldID,
                                formikProps.values.DocumentTagNames,
                              )
                            ) {
                              extraErrors.push(
                                "The entered document tag is used by another field. Unique document tag is required.",
                              );
                            }
                            if (
                              Object.values(formikProps.errors).length > 0 ||
                              extraErrors.length > 0
                            ) {
                              let errorMessage: string = Object.values(
                                formikProps.errors,
                              ).join("\n");
                              errorMessage = errorMessage
                                .concat("\n")
                                .concat(extraErrors.join("\n"));
                              displayToast({
                                status: "error",
                                title: `Failed to ${
                                  pageStatus === "New" ? "create" : "update"
                                } Custom Field:\n\n ${errorMessage}`,
                              });
                            }
                          }}>
                          {" "}
                          Submit{" "}
                        </Button>
                      </Col>

                      <Col sm="auto">
                        <Button
                          type="button"
                          variant="light"
                          onClick={() => {
                            if (pageStatus === "New") {
                              navigate("/settings/customfields");
                            } else {
                              setData(data);
                              formikProps.resetForm();
                              setPageStatus("Ready");
                            }
                          }}>
                          Cancel{" "}
                        </Button>
                      </Col>
                    </Form.Group>
                  </>
                ) : null}
              </Form>
            )}
          />
        </section>
      </>
    </LegacyScreenContainer>
  ) : (
    <></>
  );
};

export { ScreensSingleCustomField };
