import {
  Badge,
  Box,
  Button,
  chakra,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Text,
  useDisclosure,
  VStack
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { RiLock2Fill, RiMacbookLine } from "react-icons/ri";
import { useQueryClient } from "react-query";
import CenteredAlert from "../../../../components/chakra/common/CenteredAlert";
import Editable from "../../../../components/chakra/common/Editable";
import { MoreMenuIcon } from "../../../../constants/commonIcons";
import {
  useApiMutation,
  useApiQuery,
} from "../../../../utilities/apibelRequest";
import {
  formatDateSinceNow
} from "../../../../utilities/dateUtils";
import {
  useAuthSessionsQuery,
  useAuthTOTP
} from "../../../../utilities/useAuth";
import useEditableState from "../../../../utilities/useEditableState";
import useToast from "../../../../utilities/useToast";
import ChangeEmailModal from "./ChangeEmailModal";
import ChangePasswordModal from "./ChangePasswordModal";
import SetupMFAModal from "./SetupMFAModal";

export default function UserDetailsCard() {
  const changePasswordModalState = useDisclosure({ defaultIsOpen: false });
  const setupMFAModalState = useDisclosure({ defaultIsOpen: false });

  const changeEmailModalState = useDisclosure({ defaultIsOpen: false });
  const queryClient = useQueryClient();

  const totpUser = useAuthTOTP();
  const sessionsQuery = useAuthSessionsQuery();

  const { displayToast } = useToast();

  const userDetails = useApiQuery("user/profile", null);

  const updateUserDetailsMutation = useApiMutation(
    "userSettings/updateDetails",
  );

  const firstNameState = useEditableState(userDetails.data?.firstName || "");
  const lastNameState = useEditableState(userDetails.data?.lastName || "");
  const secondaryEmailState = useEditableState(
    userDetails.data?.secondaryEmail || "",
  );

  const invalidateQuery = () => {
    queryClient.invalidateQueries(["user", "profile"]);
  };

  const handleUpdateFirstName = async () => {
    const firstName = firstNameState.currentValue;
    firstNameState.setIsLoading(true);
    try {
      const res = await updateUserDetailsMutation.mutateAsync({
        firstName,
      });
      if (res) {
        displayToast({
          status: "success",
          title: "First Name successfully updated",
        });
      }
      queryClient.setQueriesData<typeof userDetails.data>(
        ["user", "profile"],
        (prev) => (prev === undefined ? undefined : { ...prev, firstName }),
      );
      invalidateQuery();
    } catch (error) {
      console.warn(error);
      displayToast({
        status: "error",
        title: "Failed to update user details",
      });
      firstNameState.resetValue();
    }
    firstNameState.setIsLoading(false);
  };

  const handleUpdateLastName = async () => {
    const lastName = lastNameState.currentValue;
    lastNameState.setIsLoading(true);
    try {
      const res = await updateUserDetailsMutation.mutateAsync({
        lastName,
      });
      if (res) {
        displayToast({
          status: "success",
          title: "Last Name successfully updated",
        });
      }
      queryClient.setQueriesData<typeof userDetails.data>(
        ["user", "profile"],
        (prev) => (prev === undefined ? undefined : { ...prev, lastName }),
      );
      invalidateQuery();
    } catch (error) {
      console.warn(error);
      displayToast({
        status: "error",
        title: "Failed to update user details",
      });
      lastNameState.resetValue();
    }
    lastNameState.setIsLoading(false);
  };

  const handleUpdateSecondaryEmail = async () => {
    const secondaryEmail = secondaryEmailState.currentValue;
    secondaryEmailState.setIsLoading(true);
    try {
      const res = await updateUserDetailsMutation.mutateAsync({
        secondaryEmail,
      });
      if (res) {
        displayToast({
          status: "success",
          title: "Secondary Email successfully updated",
        });
      }
      queryClient.setQueriesData<typeof userDetails.data>(
        ["user", "profile"],
        (prev) =>
          prev === undefined ? undefined : { ...prev, secondaryEmail },
      );
      invalidateQuery();
    } catch (error) {
      console.warn(error);
      displayToast({
        status: "error",
        title: "Failed to update user details",
      });
      secondaryEmailState.resetValue();
    }
    secondaryEmailState.setIsLoading(false);
  };

  const form = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      roles: [],
    },
  });
  const { handleSubmit } = form;

  return (
    <>
      <SetupMFAModal
        onRefresh={invalidateQuery}
        modalState={setupMFAModalState}
      />
      <ChangePasswordModal
        onRefresh={invalidateQuery}
        modalState={changePasswordModalState}
      />
      <ChangeEmailModal
        onRefresh={invalidateQuery}
        modalState={changeEmailModalState}
      />
      <VStack align="start" flex="1" minW="250px" spacing="6">
        <Heading size="md">Your Details</Heading>

        <VStack align="start" w="full" spacing={4}>
          {userDetails.isLoading ? (
            <>
              <Skeleton h="8" w="20%" />
              <Skeleton h="6" w="40%" />
              <Skeleton h="8" w="30%" />

              <Skeleton h="8" w="20%" />
              <Skeleton h="8" w="30%" />
              <Skeleton h="8" w="80%" />
            </>
          ) : userDetails.isSuccess ? (
            <>
              <VStack align="start" w="full">
                <Heading size="sm">Email</Heading>
                <Text>{userDetails.data.email}</Text>
                <Button
                  size="sm"
                  colorScheme="gray"
                  onClick={changeEmailModalState.onOpen}>
                  Change Email
                </Button>
                <Divider />
              </VStack>
              <VStack align="start" w="full">
                <FormProvider {...form}>
                  <chakra.form w="full" onSubmit={handleSubmit(() => {})}>
                    <VStack w="full" align="start">
                      <FormControl>
                        <FormLabel>First Name</FormLabel>
                        <Editable
                          isLoading={firstNameState.isLoading}
                          isDisabled={userDetails.isFetching}
                          onSubmit={handleUpdateFirstName}
                          onCancel={firstNameState.resetValue}
                          value={firstNameState.currentValue}
                          onChange={(value) => firstNameState.setValue(value)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Last Name</FormLabel>
                        <Editable
                          isLoading={lastNameState.isLoading}
                          isDisabled={userDetails.isFetching}
                          onSubmit={handleUpdateLastName}
                          onCancel={lastNameState.resetValue}
                          value={lastNameState.currentValue}
                          onChange={(value) => lastNameState.setValue(value)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Secondary Contact Email</FormLabel>
                        <Editable
                          isLoading={secondaryEmailState.isLoading}
                          isDisabled={userDetails.isFetching}
                          placeholder="None"
                          onSubmit={handleUpdateSecondaryEmail}
                          onCancel={secondaryEmailState.resetValue}
                          value={secondaryEmailState.currentValue}
                          onChange={(value) =>
                            secondaryEmailState.setValue(value)
                          }
                        />
                        <FormHelperText>
                          A secondary email is optional, but it may be used by
                          us for verifying your identity if you lose access to
                          your account.
                        </FormHelperText>
                      </FormControl>
                    </VStack>
                  </chakra.form>
                </FormProvider>
                <Divider />
              </VStack>
              <VStack align="start" w="full" spacing="4">
                <Heading size="sm">Security</Heading>
                <HStack w="100%" align="center">
                  <Box w="16rem">
                    <Text>Password</Text>
                  </Box>
                  <HStack
                    align="center"
                    minW="0px"
                    width="100%"
                    justifyContent="space-between">
                    <Text>••••••••••</Text>
                    <Button
                      size="sm"
                      variant="ghost"
                      onClick={changePasswordModalState.onOpen}>
                      Change Password
                    </Button>
                  </HStack>
                </HStack>
                <HStack w="100%" align="center">
                  <Box w="16rem">
                    <Text>Multifactor</Text>
                  </Box>
                  <HStack
                    align="center"
                    minW="0px"
                    width="100%"
                    justifyContent="space-between">
                    {totpUser?.totpEnabled ? (
                      <>
                        <Text as="span" display="flex" alignItems="center" gap="2"><Icon color="brand.700" as={RiLock2Fill} />Secured</Text>
                        <Menu size="sm">
                          <MenuButton
                            size="sm"
                            variant="ghost"
                            as={IconButton}
                            icon={<MoreMenuIcon />}
                          />
                          <MenuList>
                            <MenuItem onClick={totpUser.disableTOTP}>
                              Disable MFA
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </>
                    ) : (
                      <Button
                        w="100%"
                        size="sm"
                        variant="ghost"
                        onClick={setupMFAModalState.onOpen}>
                        Add multifactor authentication
                      </Button>
                    )}
                  </HStack>
                </HStack>
                {totpUser?.totpEnabled ? (
                  <Text color="gray.600">
                    Multifactor Authentication is set up. If you need to reset
                    your Multifactor Authentication please contact our support.
                  </Text>
                ) : (
                  <Text color="gray.600">
                    Multifactor Authentication not set up.
                  </Text>
                )}
              </VStack>{" "}
              <VStack align="start" w="full" spacing="4">
                <Heading size="sm">Active devices</Heading>
                {sessionsQuery.isLoading ? (
                  <VStack w="full" spacing={4}>
                    <Skeleton h="8" w="20%" />
                    <Skeleton h="6" w="40%" />
                    <Skeleton h="8" w="30%" />
                  </VStack>
                ) : sessionsQuery.isSuccess ? (
                  <VStack w="full" spacing={4} align="start">
                    {sessionsQuery.data.sessions.map((session) => (
                      <HStack
                        align="space-between"
                        spacing="6"
                        w="100%"
                        maxW="72">
                        <HStack align="start" flexGrow="1">
                          <Box>
                            <Icon
                              color="gray.600"
                              boxSize="8"
                              as={RiMacbookLine}
                            />
                          </Box>
                          <Box key={session.sessionID} pt="0.5">
                            <Text>
                              {session.host.deviceType}
                              {session.isCurrent ? (
                                <>
                                  {" "}
                                  <Badge
                                    variant="subtle"
                                    colorScheme="gray"
                                    fontSize="2xs">
                                    This Device
                                  </Badge>
                                </>
                              ) : null}
                            </Text>
                            <Text fontSize="sm" color="gray.600">
                              {session.host.browserName}{" "}
                              {session.host.browserVersion}
                            </Text>
                            <Text fontSize="sm" color="gray.600">
                              {session.host.location}
                            </Text>
                            <Text
                              _firstLetter={{ textTransform: "capitalize" }}
                              fontSize="sm"
                              color="gray.600">
                              {formatDateSinceNow(session.lastActiveDate)}
                            </Text>
                          </Box>
                        </HStack>
                        <Box>
                          {!session.isCurrent && (
                            <Menu size="sm">
                              <MenuButton
                                size="xs"
                                variant="ghost"
                                as={IconButton}
                                icon={<MoreMenuIcon />}
                              />
                              <MenuList>
                                <MenuItem onClick={session.revokeSession}>
                                  Revoke
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          )}
                        </Box>
                      </HStack>
                    ))}
                  </VStack>
                ) : null}
              </VStack>
            </>
          ) : (
            <CenteredAlert w="full" status="error" title="Something went wrong">
              Failed to load user details
            </CenteredAlert>
          )}
        </VStack>
      </VStack>
    </>
  );
}
