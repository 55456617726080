import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getObjectTypeGroupIDFromName } from "../../../shared/v2/constants/ObjectTypeGroupID";
import { ObjectTypeEntity } from "../../../shared/v2/definitions/coreEntityTypes";
import { isValidObjectTypeGroupKey } from "../../../shared/v2/definitions/permissions";
import * as V1Request from "../../../utilities/request";
import { useAppState } from "../../App/AppProvider";
import Icon from "../../UI/Icon";
import { useAuth } from "../../../utilities/useAuth";

type Props = {
  objectTypeGroupName: string;
};

/**
 * This component still uses APIv1 to get object types
 * TODO: Create an api v2 endpoint that can provide the needed data
 */
const NewTemplateMenu = ({ objectTypeGroupName }: Props) => {
  const { permissions } = useAppState().app;
  const navigate = useNavigate();
  const { getToken } = useAuth();
  const objectTypeGroupID = getObjectTypeGroupIDFromName(objectTypeGroupName);
  const objectTypes = useQuery(["v1", "newtypes", objectTypeGroupID], () =>
    V1Request.get(`app/objecttypegroup/${objectTypeGroupID}/objecttype`, getToken),
  );
  return (
    <Menu placement="bottom">
      <MenuButton
        isLoading={objectTypes.isFetching}
        loadingText={`Fetching ${objectTypeGroupName} types...`}
        as={Button}
        variant="ghost"
        leftIcon={<Icon name="Plus" />}>
        Create New {objectTypeGroupName} Template
      </MenuButton>
      <MenuList>
        {objectTypes.isSuccess &&
          objectTypes.data.data.ObjectTypes.filter((objectType: ObjectTypeEntity) => {
            const groupKey = objectTypeGroupName.toUpperCase();
            if (!isValidObjectTypeGroupKey(groupKey)) {
              return false;
            }
            return permissions.OBJECTS[groupKey].CREATE.ALL.includes(
              objectType.ObjectTypeID,
            );
          }).map((objectType: ObjectTypeEntity) => (
            <MenuItem
              icon={<Icon name={objectType.ObjectTypeIcon} />}
              onClick={() =>
                navigate(
                  `template/${objectTypeGroupName}/new?type=${objectType.ObjectTypeID}`,
                )
              }>
              {objectType.ObjectTypeName}
            </MenuItem>
          ))}
      </MenuList>
    </Menu>
  );
};

export default NewTemplateMenu;
