import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Icon,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
  chakra,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { RiFilter3Fill } from "react-icons/ri";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import DebouncedInput from "../../components/DebouncedInput";
import ChakraScreenContainer from "../../components/chakra/ChakraScreenContainer";
import Spinner from "../../components/chakra/common/Spinner";
import {
  DropdownIcon,
  RefreshIcon,
  WarningIcon,
} from "../../constants/commonIcons";
import { UserServicePermissions } from "../../shared/v2/constants/ServicePermissions";
import { GlobalAccountSummary } from "../../shared/v2/definitions/accounts";
import { useApiQuery } from "../../utilities/apibelRequest";
import NotFoundScreen from "../NotFound";

const filters = [
  {
    name: "demo",
    label: "Is Demo/Free Trial",
  },
  {
    name: "blueprint",
    label: "Is Blueprint",
  },
  {
    name: "exceeding-licenses",
    label: "Exceeding Licenses",
  },
  {
    name: "all",
    label: "All",
  },
] as const;

type FilterTypes = (typeof filters)[number];

const TagColorSchemMap = {
  demo: "pink",
  blueprint: "blue",
} as const;

type Props = {
  servicePermissions: UserServicePermissions;
};

export default function ServiceAccountsScreen({ servicePermissions }: Props) {
  const [filterBy, setFilterBy] = useState<FilterTypes>({
    name: "all",
    label: "All",
  });
  const [query, setQuery] = useState<string | undefined>();
  const [page, setPage] = useState(0); // Page is 0 indexed, should be changed for view
  const accountQuery = useApiQuery(
    "service/manyAccounts",
    {
      pagination: {
        page,
      },
      filters: {
        type: filterBy.name === "all" ? undefined : filterBy.name,
        showInactiveAccounts: true,
        query,
      },
    },
    {
      keepPreviousData: true,
    },
  );
  const queryClient = useQueryClient();

  if (!servicePermissions["read-accounts"]) {
    return <NotFoundScreen />;
  }

  const handleChangeQuery = useCallback(
    (value?: string) => {
      setPage(0);
      setQuery(value);
    },
    [setPage, setQuery],
  );

  const handleClickRefresh = () => {
    queryClient.invalidateQueries(["service"]);
  };

  const renderRow = (account: GlobalAccountSummary) => (
    <Tr
      key={account.accountID}
      opacity={account.isAccountActive ? 1 : 0.5}
      color={account.status === "exceeding-licenses" ? "red" : undefined}>
      <LinkBox _hover={{ textDecor: "underline" }} as={Td}>
        <LinkOverlay
          ps="2"
          as={Link}
          display="flex"
          alignItems="center"
          to={`/_ccserviceportal/account/${account.accountID}`}>
          <Flex flexDir="column" align="start" as="span">
            <chakra.span>
              {" "}
              <Badge colorScheme="gray"> {account.accountID} </Badge>
            </chakra.span>
            <Text
              color={
                account.status === "exceeding-licenses" ? "red" : undefined
              }>
              {account.accountName}
            </Text>
          </Flex>
        </LinkOverlay>
      </LinkBox>
      <Td>
        {account.systemName ? (
          account.systemName
        ) : (
          <chakra.span fontStyle="italic" color="gray.400">
            No system name
          </chakra.span>
        )}
      </Td>
      <Td>{account.accountOwnerName}</Td>
      <Td>
        <HStack as="span" align="center">
          <span>
            {account.activeUsersCount} /{" "}
            {account.userLicenses === null
              ? "10"
              : account.userLicenses <= 0
              ? "infinite"
              : account.userLicenses}
          </span>
          {account.userLicenses === null && (
            <Tooltip label="No license set - Assuming '10'" shouldWrapChildren>
              <Icon color="yellow.700" boxSize="4" as={WarningIcon} />
            </Tooltip>
          )}
        </HStack>
      </Td>
      <Td>
        <Badge colorScheme={account.status === "ok" ? "brand" : "orange"}>
          {account.status}
        </Badge>
      </Td>
      <Td>{account.pendingUsersCount}</Td>
      <Td>{account.internalUsersCount}</Td>
      <Td>
        <HStack as="span">
          {account?.isAccountActive ? (
            <Badge colorScheme="blue"> Active </Badge>
          ) : (
            <Badge colorScheme="red"> Disabled </Badge>
          )}
        </HStack>
      </Td>
      <Td>
        <HStack as="span">
          {account?.tags.map((tag) => (
            <Badge colorScheme={TagColorSchemMap[tag]} key={tag}>
              {tag}
            </Badge>
          ))}
        </HStack>
      </Td>
    </Tr>
  );

  return (
    <ChakraScreenContainer
      pageTitle="Manage Accounts"
      contentSize="full"
      breadcrumbs={[{ label: "Service Portal", link: "/_ccserviceportal" }]}>
      <Flex gap={4}>
        <HStack align="end" w="full" justify="space-between">
          <HStack align="end">
            <chakra.div w="240px">
              <DebouncedInput
                onChange={handleChangeQuery}
                value={query || undefined}
                placeholder="Search by account name"
              />
            </chakra.div>
            <Menu size="sm">
              <MenuButton
                disabled={accountQuery.isFetching}
                as={Button}
                variant="ghost"
                size="sm"
                leftIcon={<Icon as={RiFilter3Fill} />}
                rightIcon={<Icon as={DropdownIcon} />}>
                Filtered by {filterBy.label}
              </MenuButton>
              <MenuList zIndex="99">
                {filters.map((item) => (
                  <MenuItem
                    key={item.name}
                    hidden={filterBy.name === item.name}
                    onClick={() => setFilterBy(item)}>
                    {item.label}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </HStack>
          <Button
            isLoading={accountQuery.isRefetching}
            disabled={accountQuery.isFetching}
            onClick={handleClickRefresh}
            leftIcon={<Icon as={RefreshIcon} />}
            variant="ghost"
            loadingText="Refreshing"
            size="sm">
            Refresh
          </Button>
        </HStack>
      </Flex>
      <Box position="relative">
        {accountQuery.isFetching && (
          <Spinner
            zIndex={1}
            opacity={1}
            size="lg"
            position="absolute"
            top="10"
            left="50%"
            alignContent="center"
          />
        )}
        <TableContainer w="full" pt={2} pb={4}>
          <Table
            isFetching={accountQuery.isFetching}
            variant="data-table"
            size="sm"
            position="relative">
            <Thead position="relative" maxH="fit-content">
              <Tr>
                <Th>Account Name</Th>
                <Th>System Name</Th>
                <Th>Account Owner</Th>
                <Th>Active Users / Licenses</Th>
                <Th>License Status</Th>
                <Th>Pending Users</Th>
                <Th>CC/System Users</Th>
                <Th>Account Status</Th>
                <Th>Account Tags</Th>
              </Tr>
            </Thead>

            {accountQuery.isSuccess && (
              <>
                <Tbody>{accountQuery.data.accounts.map(renderRow)}</Tbody>
                {accountQuery.data?.accounts.length > 0 && (
                  <Tfoot>
                    <Tr>
                      <Th>Account Name</Th>
                      <Th>System Name</Th>
                      <Th>Account Owner</Th>
                      <Th>Active Users / Licenses</Th>
                      <Th>License Status</Th>
                      <Th>Pending Users</Th>
                      <Th>CC/System Users</Th>
                      <Th>Account Status</Th>
                      <Th>Account Tags</Th>
                    </Tr>
                  </Tfoot>
                )}
              </>
            )}
          </Table>
          {accountQuery.isSuccess && accountQuery.data.accounts.length <= 0 && (
            <HStack justify="center" w="full">
              <Text color="blackAlpha.700" fontStyle="italic">
                No Data...
              </Text>
            </HStack>
          )}
        </TableContainer>
      </Box>
      <ButtonGroup
        mt="2"
        isDisabled={accountQuery.isFetching}
        size="sm"
        variant="outline"
        isAttached>
        <Button
          onClick={() => {
            setPage(accountQuery.data?.page.prev || 0);
          }}
          isDisabled={accountQuery.data?.page.prev === undefined}>
          Prev
        </Button>
        <Button isLoading={accountQuery.isFetching} isActive>
          {(accountQuery.data?.page.current || page) + 1}
        </Button>
        <Button
          onClick={() => {
            setPage(accountQuery.data?.page.next || 0);
          }}
          isDisabled={accountQuery.data?.page.next === undefined}>
          Next
        </Button>
      </ButtonGroup>
    </ChakraScreenContainer>
  );
}
