import {
  Box,
  Button,
  ButtonGroup,
  Text,
  Link,
  HStack,
  IconButton,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tooltip,
  useDisclosure,
  Badge,
} from "@chakra-ui/react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useState } from "react";
import { RiArrowRightLine } from "react-icons/ri";
import { useApiMutation, useApiQuery } from "../../utilities/apibelRequest";
import { Card } from "../../components/UI/Card/Card";
import { ManualSectionDropdown } from "./ManualSectionDropdown";
import { DeleteIcon } from "../../constants/commonIcons";
import RequirementManualRichText from "./RequirementManualRichText";

type DeleteProps = {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  title: string;
};
type Props = {
  recordID: string;
};

function ConfirmDeleteModal({ isOpen, onClose, onDelete, title }: DeleteProps) {
  return (
    <chakra-scope>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <chakra-scope>
            <ModalHeader>Delete {title} </ModalHeader>
            <ModalCloseButton variant="ghost" />
            <ModalBody>
              <Text>Are you sure you want to delete this section?</Text>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button isDisabled={false} colorScheme="gray" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  variant="solid"
                  isLoading={false}
                  onClick={onDelete}>
                  Delete
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </chakra-scope>
        </ModalContent>
      </Modal>
    </chakra-scope>
  );
}

export function RequirementManualSectionLinks({ recordID }: Props) {
  const linkedItemsQuery = useApiQuery("manual/manySectionsForLinkedRecord", {
    recordID,
  });
  const deleteRelationshipMutation = useApiMutation(
    "manual/deleteSectionRecordLink",
  );
  const [title, setTitle] = useState("");
  const [requirementManualSectionLinkID, setRequirementManualSectionLinkID] =
    useState("");
  const confirmModal = useDisclosure();
  const queryClient = useQueryClient();
  const invalidateQuery = () => {
    queryClient.invalidateQueries(["manual"]);
  };
  const navigate = useNavigate();
  const handleDelete = async (relationshipID: string) => {
    await deleteRelationshipMutation.mutateAsync({
      requirementManualSectionID: relationshipID,
    });
    invalidateQuery();
    confirmModal.onClose();
  };

  return (
    <Card
      collapsible
      title="Manual"
      headerColsRight
      headerCols={[
        {
          colProps: { style: { textAlign: "right" } },
          children: (
            <ManualSectionDropdown selectedIDs={[]} recordID={recordID} />
          ),
        },
      ]}>
      <chakra-scope>
        {linkedItemsQuery.isSuccess &&
          (linkedItemsQuery.data.sections.length <= 0 ? (
            <Text>No linked sections...</Text>
          ) : (
            linkedItemsQuery.data.sections.map((section) => (
              <>
                <Accordion defaultIndex={[0]} allowMultiple>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          <Badge colorScheme="gray">
                            {section.manual.name}
                          </Badge>
                          <Link
                            as={RouterLink}
                            to={`/manual/${section.manual.manualID}`}
                            key={section.manualSectionID}>
                            {" "}
                            - {section.title}
                          </Link>
                        </Box>
                        <Link
                          as={RouterLink}
                          to={`/manual/${section.manual.manualID}`}
                          key={section.manualSectionID}>
                          Go To {section.manual.name}
                        </Link>
                        <Tooltip label="Delete" placement="top">
                          <IconButton
                            variant="ghost"
                            aria-label="Delete"
                            icon={<DeleteIcon />}
                            onClick={() => {
                              setTitle(section.title);
                              setRequirementManualSectionLinkID(
                                section.requirementManualSectionLinkID,
                              );
                              confirmModal.onOpen();
                            }}
                          />
                        </Tooltip>
                        <Tooltip label="Go to Manual section" placement="top">
                          <IconButton
                            variant="ghost"
                            aria-label="Go to Manual section"
                            icon={<RiArrowRightLine />}
                            onClick={() => {
                              navigate(`/manual/${section.manual.manualID}`);
                            }}
                          />
                        </Tooltip>
                        <AccordionIcon ml="6" />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel>
                      <RequirementManualRichText
                        contentJSON={section.content.jsonStr || ""}
                        preview={section.content.rawPreview}
                      />
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
                <ConfirmDeleteModal
                  isOpen={confirmModal.isOpen}
                  onClose={confirmModal.onClose}
                  onDelete={() => handleDelete(requirementManualSectionLinkID)}
                  title={title}
                />
              </>
            ))
          ))}
      </chakra-scope>
    </Card>
  );
}
