import React from "react";
import { Dropdown, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Feather from "react-feather";
import { Text } from "../Forms/Field/Text";

import { Loading } from "./Loading/Loading";

import * as Request from "../../utilities/request";
import { useAuthState } from "../App/AppProvider";
import { useAuth } from "../../utilities/useAuth";

interface OnDemandAction {
  RequirementOnDemandActionID: string;
  RequirementName: string;
  ActionName: string;
  ActionObjectTypeIcon: keyof typeof Feather;
}

interface NewOnDemandActionButtonProps {
  title: string;
  variant: "primary" | "danger";
  pageStatus?: string;
}

const NewOnDemandActionButton = ({ title, variant, pageStatus }: NewOnDemandActionButtonProps) => {
  const authState = useAuthState();
  const {getToken} = useAuth();
  const { isLoggedIn } = authState;
  const navigate = useNavigate();
  const [onDemandActions, updateOnDemandActions] = React.useState<
    OnDemandAction[] | null
  >(null);
  const [actionSearch, updateActionSearch] = React.useState<string>("");

  const getOnDemandActions = async () => {
    const req = await Request.get(
      "app/ondemandaction",
      getToken,
    );
    updateOnDemandActions(req.data.OnDemandActions);
  };

  React.useEffect(() => {
    if (isLoggedIn) {
      getOnDemandActions();
    }
  }, [isLoggedIn]);

  React.useEffect(() => {
    if (
      isLoggedIn &&
      pageStatus === "UpdatingOnDemandActions"
    ) {
      getOnDemandActions();
    }
  }, [pageStatus, isLoggedIn]);

  const onSelect = (RequirementOnDemandActionID: string | null) => {
    if (RequirementOnDemandActionID === null) return;
    navigate(`/ondemandaction/${RequirementOnDemandActionID}`);
  };

  if (onDemandActions && onDemandActions.length === 0) {
    return (
      <OverlayTrigger
        placement="auto"
        overlay={
          <Tooltip id="tooltip-not-authorised-to-edit">
            You do not have permission to create this
          </Tooltip>
        }>
        <Dropdown
          id="dropdown-basic-button"
          alignRight
          style={{ minWidth: "250px" }}>
          <Dropdown.Toggle
            disabled
            variant={variant}
            id="dropdown-newobjecttype">
            <Feather.File size="16" className="feather" />
            {title}
          </Dropdown.Toggle>
          <Dropdown.Menu className="newObjectDropdown">
            <Dropdown.Header />
            <Dropdown.Divider />
            <Dropdown.Item>
              <Row>
                <Col>
                  You do not have permisison to create new on demand action
                </Col>
              </Row>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </OverlayTrigger>
    );
  }
  return (
    <Dropdown id="dropdown-basic-button" alignRight onSelect={onSelect}>
      <Dropdown.Toggle variant={variant} id="dropdown-newobjecttype">
        <Feather.File size="16" className="feather" />
        {title}
      </Dropdown.Toggle>
      <Dropdown.Menu className="newObjectDropdown">
        <Dropdown.Header>
          <div style={{ padding: "10px" }}>
            <Text
              name="Action Search"
              value={actionSearch}
              readOnly={false}
              onUpdate={(event: any) => {
                updateActionSearch(event.target.value);
              }}
              placeHolder="Search for Action"
            />
          </div>
        </Dropdown.Header>
        <Dropdown.Divider />
        {onDemandActions ? (
          onDemandActions
            .filter((action: any) =>
              `${action.RequirementName} ${action.ActionName}`
                .toLowerCase()
                .includes(actionSearch.toLowerCase()),
            )
            .map((action) => {
              const IconType: keyof typeof Feather =
                action.ActionObjectTypeIcon;
              const Icon = Feather[IconType];

              return (
                <Dropdown.Item
                  key={action.RequirementOnDemandActionID}
                  eventKey={action.RequirementOnDemandActionID}>
                  <Row>
                    <Col>
                      <Icon className="feather" size="18" />
                      {action.RequirementName} {action.ActionName}
                    </Col>
                  </Row>
                </Dropdown.Item>
              );
            })
        ) : (
          <Dropdown.Item>
            <Row>
              <Col>
                <Loading size={"xl"} />
              </Col>
            </Row>
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export { NewOnDemandActionButton };
