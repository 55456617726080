import { ColorModeScript } from "@chakra-ui/react";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/js/plugins.pkgd.min.js";
import "./init"; // must be before react-dates/initialize
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.css";
import "./styles/chakra-date-picker.css";
import initLogging from "./utilities/Logging";

initLogging();

const container = document.getElementById("root");

const root = createRoot(container!);
root.render(
  <>
    <ColorModeScript initialColorMode="light" />
    <App />
  </>,
);
