import { FieldArray, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import * as Feather from "react-feather";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppState } from "../../components/App/AppProvider";
import { Text } from "../../components/Forms/Field/Text";
import { ListType } from "../../components/Forms/ListTypes/ListTypes";
import { Card } from "../../components/UI/Card/Card";
import { Header } from "../../components/UI/Header/Header";
import LegacyScreenContainer from "../../components/UI/LegacyScreenContainer";
import { Loading } from "../../components/UI/Loading/Loading";
import {
  ListIDToColumnName,
  ListIDToType,
  ListTypeToID,
} from "../../constants/listType";
import * as Permissions from "../../shared/constants/permission";
import * as Access from "../../utilities/access";
import { useLegacyApi } from "../../utilities/request";
import useToast from "../../utilities/useToast";

const ScreensSingleList = () => {
  const { get, post, put, del } = useLegacyApi();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { auth, app } = useAppState();
  const [pageStatus, setPageStatus] = useState<any>("Loading");
  const [data, setData] = useState<any>(null);
  const { displayToast } = useToast();

  const listID = params.listID || "";

  const deleteArray: any[] = [];
  const newListItem: any = {
    Lists: {
      ListID: "",
      ListName: "",
      ListTypeID: "",
      ListTypeName: "",
      ListTypeColumnName: "",
      ListValues: [
        {
          ListValueID: Math.random(),
          ListValueVARCHAR: "",
          DocumentID: "",
          ListValueDATETIME: "",
          ListValueINT: "",
        },
      ],
    },
  };

  useEffect(() => {
    const fetchList = async () => {
      setPageStatus("Loading");

      if (listID.indexOf("New") > -1) {
        const listType = location.search.split("?")[1];
        newListItem.Lists.ListTypeID = ListTypeToID[listType];
        // newListItem.Lists.ListTypeColumnName = ListTypeToColumnName[listType]

        setData(newListItem);
        setPageStatus("New");
      } else {
        const reply = await get(`settings/list/${listID}`);

        if (reply.status !== 200 || !reply.data) {
          displayToast({
            status: "error",
            title: `Failed to retrieve List`,
          });
        } else {
          setData(reply.data);
          setPageStatus("Ready");
        }
      }
    };
    fetchList();
  }, []); //eslint-disable-line
  if (pageStatus === "Loading") {
    return <Loading size="xl" />;
  }
  return Access.checkAccess(
    app.permissions_LEGACY,
    Permissions.CodeUserSettings,
    Permissions.TypeRead,
    false,
  ) ? (
    <LegacyScreenContainer
      pageTitle={pageStatus !== "New" ? data.Lists.ListName : "New"}
      breadcrumbs={[{ label: "Lists", link: "/settings/lists" }]}>
      <section>
        {/* <Header
            breadcrumbs={[
              { title: "Settings", link: "/settings" },
              { title: "Pages & Fields", link: "" },
              { title: "Lists", link: "/settings/lists" },
              { title: pageStatus !== "New" ? data.Lists.ListName : "New" },
            ]}
            title="List Details"
          /> */}
        <Card
          title="Details"
          collapsable={false}
          headerCols={[
            { colProps: {}, children: <span /> },
            {
              colProps: { sm: "auto" },
              children:
                pageStatus === "New" ? (
                  <span />
                ) : pageStatus !== "Editing" ? (
                  /* Needs to be changed once the permissions work for things other than Requirements. Will check to see
                              if a user has permission to Edit Lists */
                  Access.checkAccess(
                    app.permissions_LEGACY,
                    Permissions.CodeUserSettings,
                    Permissions.TypeUpdate,
                  ) ? (
                    <Button
                      type="button"
                      variant="outline-dark"
                      onClick={() => {
                        if (pageStatus !== "New") {
                          setPageStatus("Editing");
                        }
                      }}>
                      <Feather.Edit2 className="feather" size="16" />
                      Edit
                    </Button>
                  ) : (
                    <OverlayTrigger
                      placement="auto"
                      overlay={
                        <Tooltip id="tooltip-not-authorised-to-edit">
                          You do not have permission to edit this
                        </Tooltip>
                      }>
                      <Button
                        type="button"
                        variant="outline-dark"
                        className="disabled">
                        <Feather.Edit2 className="feather" />
                        Edit
                      </Button>
                    </OverlayTrigger>
                  )
                ) : data.Delete ? (
                  <DropdownButton
                    id="dropdown-basic-button"
                    variant="outline-dark"
                    title="Delete">
                    <Dropdown.Item
                      as="button"
                      onClick={async () => {
                        const reply = await del(
                          `settings/lists/${data.Lists.ListID}`,
                        );
                        if (reply.status === 200) {
                          navigate("/settings/lists");
                        }
                      }}>
                      Confirm delete
                    </Dropdown.Item>
                  </DropdownButton>
                ) : (
                  <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip id="tooltip-not-authorised-to-edit">
                        You cannot delete a List that is attached to a Custom
                        Field
                      </Tooltip>
                    }>
                    <Button
                      type="button"
                      variant="outline-dark"
                      className="disabled">
                      Delete
                    </Button>
                  </OverlayTrigger>
                ),
            },
          ]}>
          <Formik
            enableReinitialize
            initialValues={data}
            onSubmit={async (values, actions) => {
              if (pageStatus !== "New") {
                if (
                  values.Lists.ListName === "" ||
                  (values.Lists.ListValues.length <= 0
                    ? true
                    : values.Lists.ListValues.length <= 1 &&
                      values.Lists.ListValues[0].ListValueVARCHAR === "" &&
                      values.Lists.ListValues[0].ListValueINT === "" &&
                      values.Lists.ListValues[0].ListValueDATETIME === "")
                ) {
                  actions.setSubmitting(false);
                } else {
                  values.Lists.deleted = deleteArray;
                  setPageStatus("Submitting");
                  const reply = await post(
                    `settings/list/${values.Lists.ListID}`,
                    values.Lists,
                  );

                  if (reply.status !== 200) {
                    displayToast({
                      status: "error",
                      title: `Failed to update List`,
                    });
                  } else {
                    displayToast({
                      status: "success",
                      title: `List updated successfully`,
                    });
                  }
                  setPageStatus("Ready");
                  window.location.reload();
                }
              } else if (
                values.Lists.ListName === "" ||
                (values.Lists.ListValues.length <= 0
                  ? true
                  : values.Lists.ListValues.length <= 1 &&
                    values.Lists.ListValues[0].ListValueVARCHAR === "" &&
                    values.Lists.ListValues[0].ListValueINT === "" &&
                    values.Lists.ListValues[0].ListValueDATETIME === "")
              ) {
                actions.setSubmitting(false);
              } else {
                values.Lists.ListTypeColumnName =
                  ListIDToColumnName[values.Lists.ListTypeID];
                setPageStatus("Submitting");
                const reply = await put("settings/lists", values.Lists);
                if (reply.status === 200) {
                  displayToast({
                    status: "success",
                    title: `List created successfully`,
                  });
                  setData({ ...reply.data });
                  navigate(`/settings/list/${reply.data.Lists.ListID}`);
                  setPageStatus("Ready");
                } else {
                  displayToast({
                    status: "error",
                    title: `Failed to create List`,
                  });
                }
              }
            }}
            render={(formikProps) => (
              <Form onSubmit={formikProps.handleSubmit}>
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    List Name
                  </Form.Label>
                  <Col sm="6">
                    <Text
                      value={formikProps.values.Lists.ListName}
                      name="Lists.ListName"
                      onUpdate={formikProps.handleChange}
                      readOnly={
                        pageStatus !== "Editing" && pageStatus !== "New"
                      }
                    />
                  </Col>
                </Form.Group>

                {pageStatus === "New" ? (
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      List Type
                    </Form.Label>
                    <Col sm="6">
                      <Form.Control
                        as="select"
                        onChange={formikProps.handleChange}
                        id="Lists.ListTypeID"
                        readOnly={false}
                        value={formikProps.values.Lists.ListTypeID}>
                        <option key={1} value={ListTypeToID.Text}>
                          {" "}
                          Text
                        </option>
                        <option key={2} value={ListTypeToID.Number}>
                          {" "}
                          Number{" "}
                        </option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                ) : (
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      List Type
                    </Form.Label>
                    <Col sm="6">
                      <Text
                        value={
                          ListIDToType[formikProps.values.Lists.ListTypeID]
                        }
                        name="Lists.ListTypeID"
                        onUpdate={formikProps.handleChange}
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                )}

                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    List Values
                  </Form.Label>

                  <Col>
                    <FieldArray
                      name="Lists.ListValues"
                      // onUpdate={formikProps.handleChange}
                      render={({ insert, remove, push, replace }) => (
                        <>
                          {formikProps.values.Lists.ListValues.map(
                            (listValue: any, index: number) => (
                              <Row key={index} className="list-item">
                                <Col xs={12} md={8}>
                                  <ListType
                                    style={
                                      pageStatus === "Editing"
                                        ? null
                                        : { border: "white" }
                                    }
                                    name={
                                      ListIDToType[
                                        formikProps.values.Lists.ListTypeID
                                      ] !== "Date"
                                        ? `Lists.ListValues[${index}][${
                                            ListIDToColumnName[
                                              formikProps.values.Lists
                                                .ListTypeID
                                            ]
                                          }]`
                                        : undefined
                                    }
                                    value={listValue}
                                    onUpdate={
                                      ListIDToType[
                                        formikProps.values.Lists.ListTypeID
                                      ] !== "Date"
                                        ? formikProps.handleChange
                                        : (
                                            values: any,
                                            something: any,
                                            somethingelse: any,
                                          ) => {
                                            formikProps.setFieldValue(
                                              `Lists.ListValues[${index}][${
                                                ListIDToColumnName[
                                                  formikProps.values.Lists
                                                    .ListTypeID
                                                ]
                                              }]`,
                                              moment
                                                .utc(values)
                                                .format("YYYY-MM-DDTHH:mm:ssZ"),
                                            );
                                          }
                                    }
                                    authState={auth}
                                    readOnly={
                                      !(
                                        pageStatus === "Editing" ||
                                        pageStatus === "New"
                                      )
                                    }
                                    // onChange={(values: any) => { props.setFieldValue(`Lists.ListValues[${index}][${ListIDToColumnName[formikProps.values.Lists.ListTypeID]}]`, moment.utc(values).format('YYYY-MM-DDTHH:mm:ssZ')) }}
                                    type={formikProps.values.Lists.ListTypeID}
                                  />
                                </Col>
                                <Col xs={12} md={4}>
                                  {pageStatus === "Editing" ? (
                                    formikProps.values.UsedListValues.find(
                                      (usedListValue: any) =>
                                        listValue.ListValueID ===
                                        usedListValue.ListValueID,
                                    ) ? (
                                      <OverlayTrigger
                                        placement="auto"
                                        overlay={
                                          <Tooltip id="tooltip-not-authorised-to-edit">
                                            {" "}
                                            You cannot delete a List Value that
                                            is used in a Record
                                          </Tooltip>
                                        }>
                                        <Button
                                          variant="outline-dark"
                                          className="disabled">
                                          {" "}
                                          Remove List Item
                                        </Button>
                                      </OverlayTrigger>
                                    ) : (
                                      <Button
                                        variant="outline-dark"
                                        onClick={() => {
                                          deleteArray.push({
                                            listValueID: listValue.ListValueID,
                                          });
                                          remove(index);
                                        }}>
                                        {" "}
                                        Remove List Item{" "}
                                      </Button>
                                    )
                                  ) : null}

                                  {pageStatus === "New" ? (
                                    <Button
                                      variant="outline-dark"
                                      onClick={() => {
                                        deleteArray.push({
                                          listValueID: listValue.ListValueID,
                                        });
                                        remove(index);
                                      }}>
                                      {" "}
                                      Remove List Item{" "}
                                    </Button>
                                  ) : null}
                                </Col>
                              </Row>
                            ),
                          )}
                          {pageStatus === "Editing" || pageStatus === "New" ? (
                            <Row style={{ width: "100%" }}>
                              <Col sm={{ span: "auto" }}>
                                <Button
                                  variant="outline-dark"
                                  onClick={() => {
                                    // const listType = data.Lists.ListTypeColumnName
                                    push({ ListValueID: Math.random() });
                                  }}>
                                  Add List Item
                                </Button>
                              </Col>
                            </Row>
                          ) : null}
                        </>
                      )}
                    />
                  </Col>
                </Form.Group>

                {pageStatus === "Editing" || pageStatus === "New" ? (
                  <Form.Group as={Row}>
                    <Col sm="2" />
                    <Col sm="auto">
                      <Button
                        type="submit"
                        onClick={() => {
                          const errors = [];
                          if (formikProps.values.Lists.ListName === "") {
                            errors.push(`"Name is required"`);
                          }
                          if (
                            formikProps.values.Lists.ListValues.length <= 0
                              ? true
                              : formikProps.values.Lists.ListValues.length <=
                                  1 &&
                                formikProps.values.Lists.ListValues[0]
                                  .ListValueVARCHAR === "" &&
                                formikProps.values.Lists.ListValues[0]
                                  .ListValueINT === "" &&
                                formikProps.values.Lists.ListValues[0]
                                  .ListValueDATETIME === ""
                          ) {
                            errors.push(
                              '"At least one List Value is required"',
                            );
                          }
                          if (
                            errors.length > 0 &&
                            formikProps.isSubmitting === false
                          ) {
                            const message = `Failed to ${
                              pageStatus === "New" ? "create" : "update"
                            } List:\n\n${errors.join("\n")}`;
                            displayToast({
                              status: "error",
                              title: message,
                            });
                          }
                        }}>
                        {" "}
                        Submit{" "}
                      </Button>
                    </Col>

                    <Col sm="auto">
                      <Button
                        type="button"
                        variant="light"
                        onClick={() => {
                          if (pageStatus !== "New") {
                            formikProps.setValues(data);
                            setPageStatus("Ready");
                          } else {
                            navigate("/settings/lists");
                          }
                        }}>
                        Cancel{" "}
                      </Button>
                    </Col>
                  </Form.Group>
                ) : null}
              </Form>
            )}
          />
        </Card>
        <section></section>
      </section>
    </LegacyScreenContainer>
  ) : (
    <section />
  );
};

export type ListPageStatus =
  | "Loading"
  | "Ready"
  | "Editing"
  | "Submitting"
  | "New";
export { ScreensSingleList };
