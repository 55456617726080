import {
  Badge,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  LinkBox,
  LinkOverlay,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
  chakra,
  useClipboard,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import lodashGet from "lodash/get";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { Link as RouterLink, useParams } from "react-router-dom";
import ChakraScreenContainer from "../../components/chakra/ChakraScreenContainer";
import Spinner from "../../components/chakra/common/Spinner";
import { CopyToClipboardIcon, RefreshIcon } from "../../constants/commonIcons";
import { UserServicePermissions } from "../../shared/v2/constants/ServicePermissions";
import {
  UserAccountStatus,
  UserAccountStatusIDToName,
} from "../../shared/v2/constants/UserAccountStatusID";
import { UserEntity } from "../../shared/v2/definitions/coreEntityTypes";
import {
  isAxiosError,
  useApiMutation,
  useApiQuery,
} from "../../utilities/apibelRequest";
import { formatUtcDateReadableLong } from "../../utilities/dateUtils";
import useToast from "../../utilities/useToast";
import NotFoundScreen from "../NotFound";

type User = {
  userID: UserEntity["UserID"];
  email: UserEntity["UserEmail"];
  firstName: UserEntity["UserFirstName"];
  lastName: UserEntity["UserLastName"];
  isInternal: UserEntity["UserIsInternal"];
  userAccounts: UserAccount[];
  lastAccount: {
    accountID: string;
    accountName: string;
  };
  modifiedTs: string;
  createdTs: string;
};
type UserAccount = {
  accountID: string;
  accountName: string;
  accountSystemName: string;
  createdTs: string;
  modifiedTs: string;
  accountStatus: UserAccountStatus;
};
type Props = {
  servicePermissions: UserServicePermissions;
};

export default function ServiceUserScreen({ servicePermissions }: Props) {
  const emptyColor = useColorModeValue("blackAlpha.500", "whiteAlpha.500");
  const { userID } = useParams();
  const { displayToast } = useToast();
  const userModalState = useDisclosure();
  const passwordModalState = useDisclosure();
  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const [isShowChecked, setIsShowChecked] = useState(false);
  const [tempPassword, setTempPassword] = useState<string | null>(null);
  const { onCopy, value, setValue } = useClipboard(tempPassword || "");
  const clearMFA = useApiMutation("service/clearMFA");
  const resetPassword = useApiMutation("service/resetUserPassword");
  const userQuery = useApiQuery("service/user", {
    userId: userID || "",
  });
  const makeInternal = useApiMutation("service/markUserInternal");

  const { isFetching, isRefetching, isLoading, isSuccess } = userQuery;
  const usersDetails = userQuery.data;
  const dataToDisplay = [
    {
      key: "firstName",
      label: "First Name",
    },
    {
      key: "lastName",
      label: "Last Name",
    },
    {
      key: "email",
      label: "Email",
    },
    {
      key: "secondaryEmail",
      label: "Secondary Email",
    },
    {
      key: "cognitoID",
      label: "Cognito ID",
    },
    {
      key: "confirmationStatus",
      label: "Cognito Status",
    },
    {
      key: "mfa",
      label: "MFA Active",
    },
    {
      key: "lastAccount.accountID",
      label: "Last Account",
    },
    {
      key: "isInternal",
      label: "Is Internal User?",
    },
  ];
  const queryClient = useQueryClient();
  const handleClickRefresh = () => {
    queryClient.invalidateQueries("service");
  };
  const getData = (key: string) => {
    const data = lodashGet(usersDetails, key);

    if (data === undefined) {
      return (
        <Text fontStyle="italic" color={emptyColor}>
          None
        </Text>
      );
    }
    if (typeof data === "boolean") {
      return data ? "True" : "False";
    }
    return data;
  };

  const userAccounts = usersDetails?.userAccounts;
  const sortbyAccountName = (a: UserAccount, b: UserAccount) =>
    a.accountName?.localeCompare(b.accountName);
  userAccounts?.sort(sortbyAccountName);

  const handleClickResetPassword = async () => {
    if (!usersDetails) return;
    try {
      const password = await resetPassword.mutateAsync({
        userId: usersDetails.userID,
        notifyUser: isEmailChecked,
        returnTemporaryPassword: isShowChecked,
      });
      displayToast({
        title: "Success",
        description: "Temporary Password Sent",
        status: "success",
      });
      setTempPassword(password);
      setValue(password);
      if (!isShowChecked) passwordModalState.onClose();
    } catch (err) {
      displayToast({
        title: "Error",
        description: `Error creating temporary password: ${err.m}`,
        status: "error",
      });
    }
  };
  const handleClickClearMFA = async () => {
    try {
      if (!usersDetails) {
        return;
      }
      await clearMFA.mutateAsync({
        userID: usersDetails.userID,
      });

      displayToast({
        title: "Success",
        description: "MFA Cleared",
        status: "success",
      });
    } catch (err) {
      displayToast({
        title: "Error",
        description: `Error clearing MFA: ${err.m}`,
        status: "error",
      });
      userModalState.onClose();
    }
  };
  const handleClickMarkInternal = async () => {
    if (!usersDetails) {
      return;
    }
    try {
      await makeInternal.mutateAsync({
        userId: usersDetails.userID,
        isInternal: !usersDetails?.isInternal,
      });
      displayToast({
        title: "Success",
        description: "User has been updated",
        status: "success",
      });
      queryClient.invalidateQueries(["service", "markInternal"]);
      queryClient.invalidateQueries(["service", "user"]);
    } catch (err) {
      if (isAxiosError(err)) {
        displayToast({
          title: "Error",
          description: `Error updating user: ${err.response?.data.error}`,
          status: "error",
        });
      }
      displayToast({
        title: "Error",
        description: `Error updating user`,
        status: "error",
      });
    }
  };

  const renderRow = (userAccountDetails: UserAccount) => (
    <Tr key={userAccountDetails.accountID}>
      <Td display="flex">
        <Flex flexDir="column" align="start" as="span">
          <chakra.span>
            {" "}
            <Badge colorScheme="gray"> {userAccountDetails.accountID} </Badge>
          </chakra.span>
          <Text>{userAccountDetails.accountName}</Text>
        </Flex>
      </Td>
      <Td>{userAccountDetails.accountSystemName}</Td>
      <Td>{UserAccountStatusIDToName[userAccountDetails.accountStatus]}</Td>
      <Td>
        {formatUtcDateReadableLong(new Date(userAccountDetails.modifiedTs))}
      </Td>
      <Td>
        {formatUtcDateReadableLong(new Date(userAccountDetails.createdTs))}
      </Td>
    </Tr>
  );
  if (!servicePermissions["manage-users"]) {
    return <NotFoundScreen />;
  }
  return (
    <chakra-scope>
      <ChakraScreenContainer
        contentSize="container.xl"
        pageTitle="Manage User"
        pageSubtitle={usersDetails?.userID}
        breadcrumbs={[
          { label: "Service Portal", link: "/_ccserviceportal" },
          { label: "Manage Users", link: "/_ccserviceportal/users" },
        ]}>
        <Button
          isLoading={isRefetching}
          disabled={isFetching}
          onClick={handleClickRefresh}
          leftIcon={<Icon as={RefreshIcon} />}
          variant="ghost"
          loadingText="Refreshing"
          size="sm">
          Refresh
        </Button>
        <VStack w="full" align="start">
          <HStack w="full" align="start" spacing="4">
            {isLoading ? (
              <VStack w="full" spacing={4}>
                <Skeleton h={6} w="90%" />
                <Skeleton h={6} w="90%" />
                <Skeleton h={6} w="90%" />
                <Skeleton h={6} w="90%" />
                <Skeleton h={6} w="90%" />
                <Skeleton h={6} w="90%" />
                <Skeleton h={6} w="90%" />
                <Skeleton h={6} w="90%" />
                <Skeleton h={6} w="90%" />
              </VStack>
            ) : (
              <TableContainer w="full">
                <Table size="sm" colorScheme="gray" variant="striped" w="full">
                  <Tbody>
                    {dataToDisplay.map(({ label, key }) => (
                      <Tr key={key} opacity={isFetching ? 0.4 : undefined}>
                        <Td fontWeight="bold">{label}</Td>
                        <Td>{getData(key)}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            )}

            <VStack align="start" w="full">
              <Heading size="sm">Actions</Heading>
              <ButtonGroup
                isDisabled={isFetching}
                isAttached
                orientation="vertical"
                variant="outline">
                <Tooltip
                  label={
                    usersDetails?.mfa
                      ? "Reset users multi factor authentication"
                      : "User does not have multi factor authentication setup"
                  }>
                  <Button
                    isDisabled={!usersDetails?.mfa || isFetching}
                    isLoading={clearMFA.isLoading}
                    onClick={userModalState.onOpen}>
                    Clear MFA
                  </Button>
                </Tooltip>

                <Button
                  isLoading={resetPassword.isLoading}
                  isDisabled={isFetching}
                  onClick={passwordModalState.onOpen}>
                  Send Password Reset
                </Button>
                <Button
                  isLoading={makeInternal.isLoading}
                  isDisabled={isFetching}
                  onClick={handleClickMarkInternal}>
                  Mark as {usersDetails?.isInternal ? "External" : "Internal"}
                </Button>
              </ButtonGroup>
            </VStack>
          </HStack>
        </VStack>

        <VStack paddingTop="28">
          <TableContainer w="full">
            <Heading size="md">Accounts</Heading>
            <Table size="sm" colorScheme="gray" variant="striped">
              <Thead position="relative">
                <Tr>
                  <Th>Account Name</Th>
                  <Th>System Name</Th>
                  <Th>Status</Th>
                  <Th>Last Modified</Th>
                  <Th>Created</Th>
                </Tr>
              </Thead>

              {isLoading && (
                <Tbody>
                  <Tr>
                    <Spinner
                      zIndex={1}
                      opacity={1}
                      size="lg"
                      position="absolute"
                      left="50%"
                    />
                  </Tr>
                </Tbody>
              )}
              {userQuery.isSuccess && (
                <Tbody
                  opacity={
                    userQuery.isLoading || userQuery.isFetching ? 0.4 : 1
                  }>
                  {usersDetails?.userAccounts.map((u) => (
                    <LinkBox as={Tr} key={u.accountID}>
                      <Td display="flex">
                        <LinkOverlay
                          ps="2"
                          as={RouterLink}
                          flexDir="column"
                          alignItems="start"
                          to={`/_ccserviceportal/account/${u.accountID}`}>
                          <Flex flexDir="column" align="start" as="span">
                            <chakra.span>
                              {" "}
                              <Badge colorScheme="gray"> {u.accountID} </Badge>
                            </chakra.span>
                            <Text>{u.accountName}</Text>
                          </Flex>
                        </LinkOverlay>
                      </Td>
                      <Td>{u.accountSystemName}</Td>
                      <Td>{UserAccountStatusIDToName[u.accountStatus]}</Td>
                      <Td>
                        {formatUtcDateReadableLong(new Date(u.modifiedTs))}
                      </Td>
                      <Td>
                        {formatUtcDateReadableLong(new Date(u.createdTs))}
                      </Td>
                    </LinkBox>
                  ))}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </VStack>

        <Modal
          motionPreset="slideInBottom"
          size="2xl"
          isOpen={userModalState.isOpen}
          onClose={userModalState.onClose}>
          <ModalOverlay />

          <ModalContent>
            <chakra-scope>
              <ModalHeader>Confirm MFA Reset</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text>
                  Are you sure you want to reset{" "}
                  <strong>
                    <i>
                      {usersDetails?.firstName} {usersDetails?.lastName}{" "}
                    </i>
                  </strong>
                  multifactor authentication?
                </Text>
              </ModalBody>

              <ModalFooter>
                <Button
                  colorScheme="brand"
                  mr={3}
                  onClick={userModalState.onClose}>
                  Close
                </Button>
                <Button
                  variant="ghost"
                  isDisabled={clearMFA.isLoading}
                  isLoading={clearMFA.isLoading}
                  onClick={handleClickClearMFA}>
                  Confirm
                </Button>
              </ModalFooter>
            </chakra-scope>
          </ModalContent>
        </Modal>
        <Modal
          motionPreset="slideInBottom"
          size="2xl"
          isOpen={passwordModalState.isOpen}
          onClose={passwordModalState.onClose}>
          <ModalOverlay />

          <ModalContent>
            <chakra-scope>
              <ModalHeader>Confirm Password Reset</ModalHeader>
              <ModalCloseButton
                onClick={async () => {
                  setTempPassword(null);
                  passwordModalState.onClose();
                }}
              />
              <ModalBody>
                {tempPassword === null ? (
                  <>
                    {" "}
                    <Text>
                      You are about to reset{" "}
                      <strong>
                        <i>
                          {usersDetails?.firstName} {usersDetails?.lastName}{" "}
                        </i>
                      </strong>
                      password
                    </Text>
                    <FormControl display="flex" alignItems="center">
                      <FormLabel htmlFor="isEmailed" mb="0">
                        Email password to user?
                      </FormLabel>
                      <Switch
                        id="email-alerts"
                        isChecked={isEmailChecked}
                        onChange={(e) => setIsEmailChecked(e.target.checked)}
                      />
                    </FormControl>
                    <FormControl display="flex" alignItems="center">
                      <FormLabel htmlFor="email-alerts" mb="0">
                        Show me the password?
                      </FormLabel>
                      <Switch
                        id="isShown"
                        isChecked={isShowChecked}
                        onChange={(e) => setIsShowChecked(e.target.checked)}
                      />
                    </FormControl>
                    <ModalFooter>
                      <Button
                        colorScheme="gray"
                        mr={3}
                        isDisabled={resetPassword.isLoading}
                        onClick={passwordModalState.onClose}>
                        Close
                      </Button>
                      <Tooltip
                        label={isShowChecked || isEmailChecked ? null : "👉👈"}>
                        <Button
                          isDisabled={!(isShowChecked || isEmailChecked)}
                          isLoading={resetPassword.isLoading}
                          onClick={handleClickResetPassword}>
                          Reset
                        </Button>
                      </Tooltip>
                    </ModalFooter>
                  </>
                ) : (
                  <>
                    <InputGroup>
                      <Input
                        value={value}
                        pr="10"
                        isReadOnly
                        onChange={(e) => {
                          setValue(e.target.value);
                        }}
                      />
                      <InputRightElement width="10">
                        <IconButton
                          variant="outline"
                          colorScheme="gray"
                          aria-label="Copy to clipboard"
                          icon={<Icon as={CopyToClipboardIcon} />}
                          onClick={onCopy}
                        />
                      </InputRightElement>
                    </InputGroup>
                    <ModalFooter>
                      <Button
                        onClick={async () => {
                          setTempPassword(null);
                          passwordModalState.onClose();
                        }}>
                        Finish
                      </Button>
                    </ModalFooter>
                  </>
                )}
              </ModalBody>
            </chakra-scope>
          </ModalContent>
        </Modal>
      </ChakraScreenContainer>
    </chakra-scope>
  );
}
