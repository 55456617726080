import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import * as Feather from "react-feather";
import { useNavigate } from "react-router-dom";
import { useAppState } from "../../components/App/AppProvider";
import { MultiSelect } from "../../components/Forms/Field/MultiSelect";
import { Text } from "../../components/Forms/Field/Text";
import { Textarea } from "../../components/Forms/Field/Textarea";
import { Card } from "../../components/UI/Card/Card";
import { Header } from "../../components/UI/Header/Header";
import LegacyScreenContainer from "../../components/UI/LegacyScreenContainer";
import {
  AccountCalPageStatus,
  AccountExcludedDate,
  AccountWorkDays,
} from "../../shared/definitions/accounts";
import { useLegacyApi } from "../../utilities/request";
import useToast from "../../utilities/useToast";
import SettingsScreenContainer from "../_chakra/settings/SettingsScreenContainer";

const getDefaultWorkDays = () => [
  { name: "Monday", field: "AccountWorkDaysMon" },
  { name: "Tuesday", field: "AccountWorkDaysTue" },
  { name: "Wednesday", field: "AccountWorkDaysWed" },
  { name: "Thursday", field: "AccountWorkDaysThu" },
  { name: "Friday", field: "AccountWorkDaysFri" },
  { name: "Saturday", field: "AccountWorkDaysSat" },
  { name: "Sunday", field: "AccountWorkDaysSun" },
];

const ScreensAccountCalendar = () => {
  const { get, post, del } = useLegacyApi();
  const { app, auth } = useAppState();
  const navigate = useNavigate();
  const [pageStatus, setPageStatus] = useState<AccountCalPageStatus>("Loading");
  const [workDays, setWorkDays] = useState<AccountWorkDays[]>([]);
  const [excludedDates, setExcludedDates] = useState<AccountExcludedDate[]>([]);
  const { displayToast } = useToast();

  useEffect(() => {
    const fetchCalendar = async () => {
      try {
        const request: any = await get("settings/accountcal");
        const { workDays } = request.data;
        const excludedDates = request.data.excludedDates
          ? request.data.excludedDates.map((date: any) => ({
              To: date.AccountExcludedDateTo,
              From: date.AccountExcludedDateFrom,
              Description: date.AccountExcludedDateDescription,
              ID: date.AccountExcludedDateID,
            }))
          : [];

        if (!workDays) {
          const defaultWorkDays: any[] = getDefaultWorkDays();
          setWorkDays(defaultWorkDays);
          setExcludedDates(excludedDates);
          setPageStatus("Ready");
        } else {
          setWorkDays(workDays);
          setExcludedDates(excludedDates);
          setPageStatus("Ready");
        }
      } catch (ex) {
        console.log(ex);
      }
    };

    if (app.attributes !== undefined) {
      fetchCalendar();
    }
  }, [auth]);

  return (
    <>
      <Card
        title="Calendar"
        collapsable={false}
        headerColsRight
        headerCols={[
          {
            colProps: { sm: "auto" },
            children: (
              <Col sm="auto">
                {" "}
                <Button
                  disabled={pageStatus !== "Ready"}
                  type="button"
                  variant="outline-dark"
                  onClick={() => setPageStatus("Editing")}>
                  <Feather.Edit2 className="feather" size="16" />
                  Edit
                </Button>{" "}
              </Col>
            ),
          },
        ]}>
        <Row>
          <Col>
            <Text
              name="CalendarText"
              readOnly
              value={`Letting us know about your Working Days and Excluded Dates allows us to avoid Actions falling on dates when you aren't working. If a re-occurring Action has an Instance on a non working day it will automatically move it to the next available date,
                                unless there is already an instance there (e.g. a daily), in that case it will remove the instance`}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col sm={4}>
            <h3> Working Days </h3>
          </Col>
        </Row>

        <Row>
          <Col>
            {pageStatus !== "Loading" ? (
              <MultiSelect
                available
                name="workingDays"
                value={workDays}
                optionsList={getDefaultWorkDays()}
                onUpdate={(value: any[]) => {
                  if (value && value.length > 0) {
                    const updatedValues = value.map((val: any) => ({
                      name: val.label,
                      field: val.value,
                    }));
                    setWorkDays(updatedValues);
                  } else {
                    displayToast({
                      status: "error",
                      title: "At Least 1 Working Day Required",
                    });
                  }
                }}
                optionsListID="field"
                optionsListValue="name"
                readOnly={pageStatus !== "Editing"}
                readOnlyValue={workDays.map((day: any) => day.name).join(", ")}
              />
            ) : (
              <Spinner size="sm" animation="border" />
            )}
          </Col>
        </Row>
        <br />
        <Row>
          <Col sm={4}>
            <h3> Excluded Dates </h3>
          </Col>
          <Col sm={4} />
          <Col sm="auto">
            {" "}
            <Button
              type="button"
              variant="primary"
              onClick={() =>
                navigate(`/settings/account/calendar/excludeddate`)
              }>
              Create New Excluded Date
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table responsive borderless>
              <thead>
                <tr>
                  <th>From</th>
                  <th>To</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {excludedDates.length > 0
                  ? excludedDates.map((date: any, index: number) => (
                      <tr key={date.ID}>
                        <td>
                          {moment.utc(date.From).local().format("DD-MMM-YYYY")}{" "}
                        </td>
                        <td>
                          {moment.utc(date.To).local().format("DD-MMM-YYYY")}
                        </td>

                        {date.Description.length > 30 ? (
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id={date.ID}>
                                <Textarea
                                  value={date.Description}
                                  name={`Description${index}`}
                                  readOnly
                                  richText
                                />
                              </Tooltip>
                            }>
                            <td style={{ whiteSpace: "nowrap" }}>
                              <Textarea
                                value={date.Description.substring(0, 28).concat(
                                  "...",
                                )}
                                name={`Description${index}`}
                                readOnly
                                richText
                              />
                            </td>
                          </OverlayTrigger>
                        ) : (
                          <td>
                            <Textarea
                              value={date.Description}
                              name={`Description${index}`}
                              readOnly
                              richText
                            />
                          </td>
                        )}
                        <td>
                          <Button
                            disabled={pageStatus !== "Editing"}
                            variant="outline-dark"
                            id={date.Description}
                            onClick={async (event: any) => {
                              setPageStatus("Submitting");

                              const reply = await del(
                                `settings/accountcalendar/excludeddate/${date.ID}`,
                              );

                              if (reply.status === 200) {
                                const updatedExcludedDates: any[] =
                                  excludedDates.filter(
                                    (exDate: any) => exDate.ID !== date.ID,
                                  );
                                setExcludedDates(updatedExcludedDates);
                                setPageStatus("Ready");
                                displayToast({
                                  status: "success",
                                  title: "Excluded Date Deleted Successfully",
                                });
                              } else {
                                displayToast({
                                  status: "error",
                                  title: "Failed to Delete Excluded Date",
                                });
                              }
                            }}>
                            Remove
                          </Button>{" "}
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          </Col>
        </Row>
        <br />
        {pageStatus === "Editing" ? (
          <Row>
            <Col sm={2}>
              <Button
                type="button"
                variant="primary"
                onClick={async () => {
                  setPageStatus("Submitting");
                  const reply = await post(
                    "settings/accountworkdays",
                    workDays,
                    
                  );
                  if (reply.status === 200) {
                    setPageStatus("Ready");
                    displayToast({
                      status: "success",
                      title: "Account Calendar Updated Successfully",
                    });
                  }
                }}>
                Submit
              </Button>
            </Col>
            <Col sm={2}>
              <Button
                type="button"
                variant="light"
                onClick={() => {
                  setPageStatus("Ready");
                }}>
                Cancel{" "}
              </Button>
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </Card>
      <section />
    </>
  );
};

export { ScreensAccountCalendar, getDefaultWorkDays };
