import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { Col, Container, Row } from "react-bootstrap";
import { Info } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useAppState } from "../../components/App/AppProvider";
import { Card } from "../../components/UI/Card/Card";
import LegacyScreenContainer from "../../components/UI/LegacyScreenContainer";
import { Loading } from "../../components/UI/Loading/Loading";
import { Search, SearchHook } from "../../components/UI/Search/Search";
import BlurredBarChart from "../../images/BlurredBarChart.png";
import BlurredPieChart from "../../images/BlurredPieChart.png";
import { get } from "../../utilities/request";
import useToast, { DisplayToastFn } from "../../utilities/useToast";
import { useAuth } from "../../utilities/useAuth";

type HomePageStatus = "Loading" | "Normal" | "Template";

interface Widget {
  type: (props: any) => JSX.Element;
  props: any;
  title: string;
}

interface Widgets {
  [key: string]: Widget;
}

interface LayoutColumn {
  widget: string;
}

interface LayoutRow {
  columns: LayoutColumn[];
}

interface Layout {
  rows: LayoutRow[];
}

interface HomeScreenState {
  widgets: Widgets;
  layout: Layout;
}

const initialState: HomeScreenState = {
  widgets: {},
  layout: {
    rows: [],
  },
};

interface WidgetFrameProps {
  title: string;
  editable: boolean;
  children: any;
  onRemove: any;
}

// dashboard

const WidgetFrame = ({ title, editable, children }: WidgetFrameProps) => (
  <Card
    title={title}
    collapsable={false}
    headerColsRight
    headerCols={
      editable
        ? [
            { colProps: { sm: "auto" }, children: <span /> }, // <Trash2 onClick={onRemove}>Remove</Trash2>
          ]
        : []
    }>
    {children}
  </Card>
);

// const AddWidget = (props: any) => (
//     <div style={{ display: 'none' }}></div>
// )

// charts
const getValue = (propValue: string, selectedValue: string) => {
  if (propValue === "Picked") {
    return selectedValue;
  }
  if (propValue === "Secondary") {
    return selectedValue;
  }
  return propValue;
};

const colours = [
  "#655AEF",
  "#3A474F",
  "#999386",
  "#EE7069",
  "#C2BCF7",
  "#B0B5B9",
  "#D7D4CF",
  "#FFC3BF",
]; // '#9389F2', , '#757E83', , '#B7B3A9', , '#FF9B96',
const stackedColours = [
  ["#2114ce", "#5f7582", "#cb2017", "#7a7467"],
  ["#3e30ea", "#7c919f", "#e73c33", "#979184"],
  ["#6e64ef", "#9cadb7", "#ed6d66", "#b1aca2"],
  ["#9e97f4", "#bdc8cf", "#f39d99", "#cbc8c1"],
  ["#cecbf9", "#dee3e7", "#f9cecc", "#e5e3e0"],
];

const generateStackedColours = (xWidth: number, zDepth: number) => {
  const colours: any[] = [];

  for (let z = 0; z < zDepth; z++) {
    colours[z] = [];
    for (let x = 0; x < xWidth; x++) {
      const zValue =
        zDepth === stackedColours.length
          ? z
          : Math.ceil((5 / zDepth) * z) % stackedColours.length;
      colours[z][x] = stackedColours[zValue][x % 4];
    }
  }

  return colours;
};

const ScreensHome = (props: any) => {
  const [pageStatus, setPageStatus] = useState<HomePageStatus>("Loading");
  const [state, setState] = React.useState<HomeScreenState>(initialState);
  const { displayToast } = useToast();
  const { getToken } = useAuth();

  const appState = useAppState();
  const authState = appState.auth;
  const navigate = useNavigate();
  const templateType =
    window.location.href.indexOf("requirement?") > -1
      ? "requirement"
      : window.location.href.indexOf("issue?") > -1
      ? "issue"
      : window.location.href.indexOf("action?") > -1
      ? "action"
      : window.location.href.indexOf("risk?") > -1
      ? "risk"
      : window.location.href.indexOf("register?") > -1
      ? "register"
      : "";
  const [data, dispatch] = SearchHook();
  const [isCollapsed, setCollapsed] = useState<boolean>(true);
  const [hoverValue, setHoverValue] = useState<any>();

  useEffect(() => {
    const getData = async () => {
      setPageStatus("Loading");

      if (window.location.href.indexOf("template") > -1) {
        setPageStatus("Template");
        await get("app/nav/template", getToken);
      } else {
        setPageStatus("Normal");
        const data = await get("app/dashboard", getToken);

        setState({
          widgets: data.data.reduce((widgets: any, chart: any) => {
            const reply = {
              ...widgets,
              [chart.key]: {
                type: getChart(
                  chart.type,
                  dispatch,
                  setCollapsed,
                  hoverValue,
                  setHoverValue,
                ),
                props: chart.props,
                title: chart.name,
              },
            };
            return reply;
          }, {}),
          layout: {
            rows: data.data.reduce((rows: any, chart: any, index: number) => {
              if (
                rows.length === 0 ||
                rows[rows.length - 1].columns.length > 1
              ) {
                // start new row
                return [
                  ...rows,
                  {
                    columns: [
                      {
                        widget: chart.key,
                      },
                    ],
                  },
                ];
              }
              // add to last existing row
              rows[rows.length - 1].columns.push({
                widget: chart.key,
              });
              return rows;
            }, []),
          },
        });
      }
    };

    getData();
  }, [authState]); // eslint-disable-line react-hooks/exhaustive-deps

  const onRemove = (event: any) => {
    const newLayout = state.layout;

    // remove the widget from the row and column, leave the column there so it's easy to move a widget into the space
    newLayout.rows[event.row].columns.splice(event.column, 1);
    // check if row is empty, if so remove the row
    if (
      newLayout.rows[event.row].columns.filter(
        (column: LayoutColumn) => column.widget,
      ).length === 0
    ) {
      newLayout.rows.splice(event.row, 1);
    }

    setState({ widgets: state.widgets, layout: newLayout });
  };

  const onAdd = (widgetKey: string) => {
    if (state.layout.rows.length > 0) {
      const currentRowsCols =
        state.layout.rows[state.layout.rows.length - 1].columns.length;
      if (currentRowsCols === 1) {
        const { rows } = state.layout;
        rows[rows.length - 1].columns.push({
          widget: widgetKey,
        });
        setState({
          widgets: state.widgets,
          layout: {
            rows,
          },
        });

        return;
      }
    }

    setState({
      widgets: state.widgets,
      layout: {
        rows: [
          ...state.layout.rows,
          {
            columns: [
              {
                widget: widgetKey,
              },
            ],
          },
        ],
      },
    });
  };

  const getList = (rowIndex: number) => state.layout.rows[rowIndex].columns;

  return (
    <LegacyScreenContainer breadcrumbs={[]} pageTitle="Home">
      {state && pageStatus !== "Loading" && pageStatus !== "Template" ? (
        <></>
      ) : pageStatus === "Loading" ? (
        <div className="progress-spinner" style={{ marginTop: "20%" }}>
          <Loading size={"xl"} />
        </div>
      ) : (
        <Row>
          <Container>
            <div style={{ textAlign: "center", marginTop: "8%" }}>
              <Row style={{ marginBottom: "20px" }}>
                <Col>
                  <Info />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h1>You are in Template Management mode.</h1>
                  <h1>
                    You can exit at any time by pressing the 'Get Out' button.
                  </h1>
                </Col>
              </Row>
            </div>
          </Container>
        </Row>
      )}
    </LegacyScreenContainer>
  );
};

export { ScreensHome };
