import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Row,
  Table
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useAppState } from "../../components/App/AppProvider";
import { Text } from "../../components/Forms/Field/Text";
import { Uploader } from "../../components/Forms/Field/Uploader";
import { Card } from "../../components/UI/Card/Card";
import LegacyScreenContainer from "../../components/UI/LegacyScreenContainer";
import { Loading } from "../../components/UI/Loading/Loading";
import { Paginator } from "../../components/UI/Paginator/Paginator";
import { isJobsAccount } from "../../constants/JobsVisibleCheck";
import { useLegacyApi } from "../../utilities/request";
import useToast from "../../utilities/useToast";

export type PageStatus = "Loading" | "Ready" | "Editing" | "Submitting";

const ScreensPageDocuments = () => {
  const { auth } = useAppState();
  const { get, put } = useLegacyApi();
  const location = useLocation();
  const [pageStatus, setPageStatus] = useState<PageStatus>("Loading");
  const { displayToast } = useToast();
  const [objectTypes, setObjectTypes] = useState<any>(null);
  const [selectedObjectType, setSelectedObjectType] = useState<any>(null);

  useEffect(() => {
    const fetchPageData = async () => {
      setPageStatus("Loading");
      const result = await get("settings/pages");

      if (result.status !== 200 || !result.data) {
        displayToast({
          status: "error",
          title: "Failed to retrieve data",
        });
      } else {
        setObjectTypes(result.data.objectTypeInformation);
        setSelectedObjectType(
          result.data.objectTypeInformation.filter(
            (o: any) => o.ObjectTypeID === location.search.split("=")[1],
          )[0],
        );
      }
      setPageStatus("Ready");
    };
    fetchPageData();
  }, []);

  return (
    <LegacyScreenContainer
      pageTitle={
        selectedObjectType
          ? `${selectedObjectType.ObjectTypeGroupName}: ${selectedObjectType.ObjectTypeName} (Documents)`
          : ""
      }
      breadcrumbs={[ { label: "Pages", link: "/settings/pages" }]}>
      <>
        {/* <Header
          breadcrumbs={[
            { title: "Settings", link: "" },
            { title: "Documents" },
            { title: "Pages", link: "/settings/pages" },
            !selectedObjectType
              ? { title: "Fields" }
              : { title: selectedObjectType.ObjectTypeName },
            ...(selectedObjectType ? [{ title: "Fields" }] : []),
          ]}
          title="Pages / Forms / Screens"
        /> */}
        <ObjectTypeSelector
          selectedObjectType={selectedObjectType}
          objectTypes={objectTypes}
          setSelectedObjectType={setSelectedObjectType}
        />

        <Card title="Add Template Document" collapsable={false}>
          <DocumentUploader selectedObjectType={selectedObjectType} />
        </Card>

        <Card title="Available Tags" collapsable>
          <DocumentTagsSearcher selectedObjectType={selectedObjectType} />
        </Card>

        <section></section>
      </>
    </LegacyScreenContainer>
  );
};

type DocumentUploaderProps = {
  selectedObjectType: { ObjectTypeID: string };
};
const DocumentUploader = ({ selectedObjectType }: DocumentUploaderProps) => {
  const { auth } = useAppState();
  const { get, put } = useLegacyApi();
  const { displayToast } = useToast();
  const [fileList, setFileList] = useState<any>([]);
  const [pageStatus, setPageStatus] = useState<PageStatus>("Loading");

  useEffect(() => {
    const fetchPageData = async () => {
      setPageStatus("Loading");
      const existingDocuments = await get(
        `templatedocument/${selectedObjectType.ObjectTypeID}`,
      );

      if (existingDocuments.status !== 200 || !existingDocuments.data) {
        useToast({
          status: "error",
          title: "Failed to retrieve documents",
        });
      } else {
        setFileList(existingDocuments.data);
        setPageStatus("Ready");
      }
    };

    if (selectedObjectType) {
      fetchPageData();
    }
  }, [selectedObjectType]);

  const submitDocuments = async () => {
    setPageStatus("Loading");

    const SubmitObject = {
      ObjectType: selectedObjectType,
      fileList,
    };
    const putResult = await put("templatedocument", SubmitObject);

    if (putResult.status !== 200 || !putResult.data) {
      displayToast({
        status: "error",
        title: "Failed to update documents",
      });
    } else {
      displayToast({
        status: "success",
        title: "Successfully updated documents",
      });
      setPageStatus("Ready");
    }
  };

  if (pageStatus === "Loading") {
    return <Loading />;
  }
  return (
    <div>
      <Row>
        <Col>
          <Uploader
            fileList={fileList}
            onChange={(event) => {
              setFileList(event.fileList);
            }}
            uploading={false}
            readOnly={false}
            disabled={false}
            name="FileUploader"
            limitTypes=".docx"
            limitTypesExplanation=".docx"
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "10px", textAlign: "right" }}>
        <Col>
          <Button type="submit" onClick={submitDocuments}>
            Submit
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Link
            to={`/settings/pages/fields?objecttype=${selectedObjectType.ObjectTypeID}`}>
            {" "}
            Manage the Custom Fields for this page
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <Link
            to={`/settings/pages/settings?objecttype=${selectedObjectType.ObjectTypeID}`}>
            {" "}
            Manage the Settings for this page
          </Link>
        </Col>
      </Row>
    </div>
  );
};

type DocumentTagsSearcherProps = {
  selectedObjectType: { ObjectTypeName: string; ObjectTypeGroupName: string };
};
const DocumentTagsSearcher = ({
  selectedObjectType,
}: DocumentTagsSearcherProps) => {
  const { get } = useLegacyApi();
  const appState = useAppState();
  const { displayToast } = useToast();
  const { auth } = appState;
  /* State Declarations */
  const [searchText, setSearchText] = useState<string>("");
  const [pageStatus, setPageStatus] = useState<PageStatus>("Loading");
  const [filters, setFilters] = React.useState<any>({ page: 1, pageSize: 10 });
  const [documentTags, setDocumentTags] = useState<any>(null);

  /* Filters */
  const stringFilter = (list: any[], text: string) =>
    list.filter(
      (listItem: any) =>
        listItem.DocumentTagName.toLowerCase().indexOf(text.toLowerCase()) !==
          -1 ||
        listItem.DocumentTagDescription.toLowerCase().indexOf(
          text.toLowerCase(),
        ) !== -1 ||
        listItem.DocumentTagTableReference.toLowerCase().indexOf(
          text.toLowerCase(),
        ) !== -1,
    );

  const pageFilter = (filters: any, index: number) =>
    index < filters.page * filters.pageSize &&
    index >= (filters.page - 1) * filters.pageSize;

  const renderTags = (data: any) => {
    if (data.length > 0 && selectedObjectType) {
      return data.map((e: any, i: number) => {
        /* Custom display rules */
        if (e.DocumentTagTableReference === "Requirement") {
          if (isJobsAccount(appState)) {
            e.DocumentTagTableReference = "Requirement | Issue | Job";
          } else {
            e.DocumentTagTableReference = "Requirement | Issue";
          }
        } else if (e.DocumentTagTableReference === "ObjectType") {
          e.DocumentTagDescription = e.DocumentTagDescription.replace(
            "${ObjectTypeName}",
            selectedObjectType.ObjectTypeName,
          );
        } else if (e.DocumentTagTableReference === "ObjectTypeGroup") {
          e.DocumentTagDescription = e.DocumentTagDescription.replace(
            "${ObjectTypeGroupName}",
            selectedObjectType.ObjectTypeGroupName,
          );
        }

        return (
          <tr key={i}>
            <td style={{ whiteSpace: "nowrap" }}>{e.DocumentTagName}</td>
            <td>{e.DocumentTagDescription}</td>
            <td>{e.DocumentTagTableReference}</td>
          </tr>
        );
      });
    }
    return (
      <tr>
        <td>No Records (yet...)</td>
      </tr>
    );
  };

  useEffect(() => {
    const fetchPageData = async () => {
      const documentTags = await get("settings/pages/documenttags");

      if (documentTags.status !== 200 || !documentTags.data) {
        displayToast({
          status: "error",
          title: "Failed to retrieve Document Tags",
        });
      } else {
        setDocumentTags(documentTags.data);
        setPageStatus("Ready");
      }
    };

    fetchPageData();
  }, []);

  /* Apply Filters */
  useEffect(() => {
    setFilters({ ...filters, page: 1 });
  }, [searchText]);

  let filteredRecords: any[] = documentTags ? documentTags.tags : [];
  let paginatedFilteredRecords: any[] = [];
  if (filteredRecords.length > 0) {
    // Filter by string search
    filteredRecords = stringFilter(filteredRecords, searchText);

    // Pagination
    paginatedFilteredRecords = filteredRecords.filter(
      (record: any, index: number) => pageFilter(filters, index),
    );
  }

  return (
    <div>
      <Row style={{ marginBottom: "10px", color: "#374750" }}>
        <Col sm="auto">
          <h1>Search Tags</h1>
        </Col>
        <Col sm={10}>
          <Text
            disabled={!documentTags || pageStatus === "Loading"}
            placeHolder={
              !documentTags || pageStatus === "Loading"
                ? "Loading..."
                : "Search by Name, Description, ID or Type"
            }
            value={searchText}
            name="searchText"
            onUpdate={(event: any) => setSearchText(event.target.value)}
            readOnly={false}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Table responsive borderless>
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>{renderTags(paginatedFilteredRecords)}</tbody>
          </Table>
          <Row className="justify-content-sm-center">
            <Col sm="auto">
              <Paginator
                filters={filters}
                setFilterDispatch={setFilters}
                allPaginatedRecords={filteredRecords}
                pageKey="page"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

type ObjectTypeSelectorProps = {
  selectedObjectType: {};
  objectTypes: {}[];
  setSelectedObjectType: (object: {}) => void;
};
const ObjectTypeSelector = ({
  selectedObjectType,
  objectTypes,
  setSelectedObjectType,
}: ObjectTypeSelectorProps) => {
  if (selectedObjectType !== null) {
    return (
      <Row style={{ marginBottom: "10px" }}>
        <Col sm="auto"> </Col>
        <Col sm="auto">
          <h1>
            {`${selectedObjectType.ObjectTypeGroupName}: 
                            ${
                              objectTypes.find(
                                (element: any) =>
                                  element.ObjectTypeID ===
                                  selectedObjectType.ObjectTypeID,
                              ).ObjectTypeName
                            }`}
          </h1>
        </Col>
        <DropdownButton
          id="pageSelect"
          title="Select Page"
          onSelect={async (selected: any) => {
            const selectedObjectType = objectTypes.find(
              (objectType: any) => objectType.ObjectTypeID === selected,
            );
            const objectTypeState: any = {
              ObjectTypeGroupName: selectedObjectType.ObjectTypeGroupName,
              ObjectTypeID: selectedObjectType.ObjectTypeID,
              ObjectTypeName: selectedObjectType.ObjectTypeName,
            };
            setSelectedObjectType(objectTypeState);
          }}>
          {objectTypes.map((requirement: any, index: any) => (
            <Dropdown.Item
              eventKey={requirement.ObjectTypeID}
              key={
                index
              }>{`${requirement.ObjectTypeGroupName}:  ${requirement.ObjectTypeName}`}</Dropdown.Item>
          ))}
        </DropdownButton>
      </Row>
    );
  }
  return <></>;
};

export { ScreensPageDocuments };
