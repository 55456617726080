import React from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import {
  Col,
  Container,
  Form,
  Row,
  Table,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import * as Feather from "react-feather";
import { Card } from "../../components/UI/Card/Card";
import { Header } from "../../components/UI/Header/Header";
import { LifeCycle } from "../../components/UI/Status/LifeCycle";
import { Loading } from "../../components/UI/Loading/Loading";
import * as ObjectStatus from "../../shared/constants/objectStatus";
import * as Request from "../../utilities/request";
import * as ObjectTypeGroup from "../../constants/objectTypeGroup";
import { NewObjectTypeButton } from "../../components/UI/NewObjectTypeButton";
import { text_truncate } from "../../utilities/strings";
import { formatDateTime } from "../../utilities/formatDate";
import { getUrlSearchParam } from "../../utilities/url";
import { Textarea } from "../../components/Forms/Field/Textarea";
import { Paginator } from "../../components/UI/Paginator/Paginator";
import { ObjectTypeGroupIDToName } from "../../shared/constants/objectTypeGroup";
import { isJobsAccount } from "../../constants/JobsVisibleCheck";
import { useAppState } from "../../components/App/AppProvider";
import LegacyScreenContainer from "../../components/UI/LegacyScreenContainer";
import { useAuth } from "../../utilities/useAuth";

interface ReportDashboardState {
  reports?: any[];
  error?: any;
}

interface CategoryFilter {
  name: string;
  id: string;
  selected: boolean;
}

interface ReportDashboardFiltersState {
  importantReportsPage: number;
  pageSize: number;
  categories: CategoryFilter[];
  selectedCategory: string;
}

const getIcon = (type: string) => {
  switch (type) {
    case "Requirement":
      return "FileText";
    case "Action":
      return "Edit";
    case "Issue":
      return "AlertCircle";
    case "Risk":
      return "AlertTriangle";
    case "Job":
      return "Briefcase";
    case "Register":
      return "Layers";
    default:
      return "File";
  }
};

const categoryFilter = (filters: ReportDashboardFiltersState, report: any) => {
  let isIncluded = false;
  filters.categories.forEach((category: CategoryFilter) => {
    if (category.selected && report.ObjectTypeGroupID === category.id) {
      isIncluded = true;
    }
  });
  return isIncluded;
};
const pageFilter = (page: number, pageSize: number, index: number) =>
  index < page * pageSize && index >= (page - 1) * pageSize;

const ScreensReportDashboard = () => {
  const { getToken } = useAuth();

  const location = useLocation();
  const { auth } = useAppState();
  const [data, setData] = React.useState<ReportDashboardState>({});
  const [filters, setFilters] = React.useState<ReportDashboardFiltersState>({
    importantReportsPage: 1,
    pageSize: 10,
    categories: [],
    selectedCategory: "All",
  });
  const isMainPage = !location.search;

  const getUrlFilter = (categories?: any) => {
    if (!categories) {
      categories = filters.categories;
    }
    let filter = getUrlSearchParam(location.search, "filter");
    filter = filter !== null ? filter.replace("reports", "") : filter;
    let selected = "All";
    const newCategories = categories.map((category: CategoryFilter) => {
      if (category.id === filter) {
        selected = category.name;
        return { name: category.name, id: category.id, selected: true };
      }
      return {
        name: category.name,
        id: category.id,
        selected: !!(!filter || filter === "All"),
      };
    });
    setFilters({
      ...filters,
      importantReportsPage: 1,
      categories: newCategories,
      selectedCategory: selected,
    });
  };

  React.useEffect(() => {
    const getData = async () => {
      try {
        const [pageData, reportObjectTypes] = await Promise.all([
          Request.get("report", getToken),
          Request.get(
            `app/objecttypegroup/Report/objecttype`,
            getToken,
          ),
        ]);

        const categories: CategoryFilter[] =
          reportObjectTypes.data.ObjectTypes.map((objectType: any) => ({
            name: objectType.ObjectTypeName,
            id: objectType.ObjectTypeID,
            selected: true,
          }));
        const data = {
          reports: pageData.data.reports,
        };
        setData(data);
        getUrlFilter(categories);
      } catch (err) {
        setData({
          error: err,
        });
      }
    };
    getData();
  }, [auth]); // eslint-disable-line

  React.useEffect(() => {
    getUrlFilter();
  }, [location.search, location.pathname, data]); // eslint-disable-line

  // Report Card Filters and Pagination
  let filteredReports: any[] = data.reports ? data.reports : [];
  let paginatedFilteredReports: any[] = [];
  if (filteredReports.length > 0) {
    // Filter based off Category
    filteredReports = filteredReports.filter((report: any) =>
      categoryFilter(filters, report),
    );

    // Order Results
    filteredReports = filteredReports.sort((a: any, b: any) =>
      a.ReportReadableID.localeCompare(b.ReportReadableID, "en", {
        numeric: true,
      }),
    );

    // Pagination
    paginatedFilteredReports = filteredReports.filter(
      (report: any, index: number) =>
        pageFilter(filters.importantReportsPage, filters.pageSize, index),
    );
  }

  return (
    <LegacyScreenContainer pageTitle="Reports Dashboard" breadcrumbs={[]}>
      <>
      {data.reports ? (
        <>
          {/* <Header
            breadcrumbs={[
              { title: "Home", link: "/" },
              { title: "Report Dashboard" },
            ]}
            title="Report Dashboard"
          /> */}
          <Card
            title={
              isMainPage ? "All Reports" : `${filters.selectedCategory} Reports`
            }
            collapsable={false}
            headerCols={[
              { colProps: {}, children: <span /> },
              {
                colProps: { sm: "auto" },
                children: (
                  <NewObjectTypeButton
                    variant="primary"
                    title="Create New Report"
                    header="New Report"
                    objectTypeGroupID="Report"
                    parentType="Report"
                  />
                ),
              },
            ]}>
            <Table responsive borderless>
              <thead>
                <tr>
                  <th />
                  <th>Name</th>
                  <th>Report Type</th>
                  <th>Description</th>
                  <th>Person Responsible</th>
                </tr>
              </thead>
              <tbody>
                {paginatedFilteredReports.length > 0 ? (
                  paginatedFilteredReports.map((report: any, index: number) => {
                    const IconType: keyof typeof Feather = getIcon(
                      report.ObjectTypeGroupName,
                    );
                    const Icon = Feather[IconType];
                    return (
                      <tr key={index}>
                        <td>
                          <Icon />
                        </td>
                        {report.ReportName.length > 25 ? (
                          <td style={{ whiteSpace: "nowrap" }}>
                            <OverlayTrigger
                              placement="right"
                              overlay={
                                <Tooltip id={report.ReportName}>
                                  {report.ReportReadableID} -{" "}
                                  {report.ReportName}
                                </Tooltip>
                              }>
                              <Link
                                to={`/report/${report.ReportID}?display=reports`}>
                                {report.ReportReadableID} -{" "}
                                {text_truncate(report.ReportName, 25)}
                              </Link>
                            </OverlayTrigger>
                          </td>
                        ) : (
                          <td>
                            <Link
                              to={`/report/${report.ReportID}?display=reports`}>
                              {report.ReportReadableID} - {report.ReportName}
                            </Link>
                          </td>
                        )}
                        <td>{report.ObjectTypeGroupName}</td>
                        {report.ReportDescription.length > 25 ? (
                          <OverlayTrigger
                            placement="auto"
                            overlay={
                              <Tooltip id={report.ReportDescription}>
                                <Textarea
                                  value={report.ReportDescription}
                                  name={`ReportDescription${index}`}
                                  readOnly
                                  richText
                                />
                              </Tooltip>
                            }>
                            <td style={{ whiteSpace: "nowrap" }}>
                              <Textarea
                                value={text_truncate(
                                  report.ReportDescription,
                                  25,
                                )}
                                name={`ReportDescription${index}`}
                                readOnly
                                richText
                              />
                            </td>
                          </OverlayTrigger>
                        ) : (
                          <td>
                            <Textarea
                              value={report.ReportDescription}
                              name={`ReportDescription${index}`}
                              readOnly
                              richText
                            />
                          </td>
                        )}
                        <td>{report.ReportResponsibleUserName}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td />
                    <td colSpan={4}>No Reports</td>
                  </tr>
                )}
              </tbody>
            </Table>
            <Row className="justify-content-sm-center">
              <Col sm="auto">
                <Paginator
                  filters={filters}
                  setFilterDispatch={setFilters}
                  allPaginatedRecords={filteredReports}
                  pageKey="importantReportsPage"
                />
              </Col>
            </Row>
          </Card>
        </>
      ) : (
        <>
          <div className="progress-spinner" style={{ marginTop: "20%" }}>
            <Loading size={"xl"} />
          </div>
        </>
      )}
    </>
    </LegacyScreenContainer>
  );
};

export { ScreensReportDashboard };
