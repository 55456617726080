import { format, formatRelative, isValid, parse, subMinutes } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc, formatInTimeZone } from "date-fns-tz";

export const NativeDatePickerFormat = "yyyy-MM-dd";

export const getBrowserTimeZone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * Formats a date into a string usable by the native date-picker
 * Does not care about time zones
 */
export const formatDateForNativePicker = (date: Date) =>
  format(date, NativeDatePickerFormat);

export const parseDateFromNativePicker = (dateStr: string) =>
  parse(dateStr, NativeDatePickerFormat, new Date());

/**
 * Converts a date from the browser timezone to UTC
 */
export const userDateToUtcDate = (date: Date) =>
  zonedTimeToUtc(date, getBrowserTimeZone());

/**
 * Converts a date from UTC to the browser timezone
 */
export const utcDateToUserDate = (date: Date) =>
  utcToZonedTime(date, getBrowserTimeZone());

/**
 * Formats a UTC date into a short readable date for the current timezone
 */
export const formatUtcDateReadableShort = (date: Date) => {
  if (!isValid(date)) return "Invalid Date";
  const zonedDate = utcDateToUserDate(date);
  return format(zonedDate, "do MMM yyyy");
};

export const formatUtcDateIsoShort = (date: Date) => {
  const zonedDate = utcDateToUserDate(date);
  return format(zonedDate, "yyyy-MM-dd");
};

export const formatUtcDateReadableLong = (date: Date) => {
  if (!isValid(date)) return "Invalid Date";
  const zonedDate = utcDateToUserDate(date);
  return format(zonedDate, "do MMM yyyy p");
};

export const getTaskZonedAndAdjustedDate = (date: Date) => {
  const zonedDate = utcDateToUserDate(date);
  // If the date is midnight, we subtract a minute so that it counts as the 'previous' day.
  // This is because a task due on a specific day is actually due at midnight on that day.
  const isMidnight =
    zonedDate.getHours() === 0 &&
    zonedDate.getMinutes() === 0 &&
    zonedDate.getSeconds() === 0;
  return isMidnight ? subMinutes(zonedDate, 1) : zonedDate;
};

export const formatTaskDueDateShort = (date: Date) => {
  if (!isValid(date)) return "Invalid Date";
  const dateToFormat = getTaskZonedAndAdjustedDate(date);
  return format(dateToFormat, "do MMM yyyy");
};

export const formatTaskDueDate = (date: Date) => {
  if (!isValid(date)) return "Invalid Date";
  const dateToFormat = getTaskZonedAndAdjustedDate(date);
  return format(dateToFormat, "do MMM yyyy p");
};

export const formatDateForFileName = (date: Date) => {
  if (!isValid(date)) return "Invalid Date";
  const dateToFormat = utcDateToUserDate(date);
  return format(dateToFormat, "yyyy-MM-dd");
};

export const formatDateString = (date: Date) => {
  if (!isValid(date)) return "Invalid Date";
  const dateToFormat = utcDateToUserDate(date);
  return format(dateToFormat, "do MMM yyyy");
};

type FormatType = "date" | "datetime" | "datetime-short";

/**
 * Formats the given date or date string ts into a readable date string
 * This function assumes the date has a timezone attached
 * Dates returned from drizzle queries or mysql2 queries have timezones attached as the libraries add them
 */
export const formatDateOrIsoString = (
  date: Date | string,
  type: FormatType,
) => {
  const parsed = new Date(date);
  if (!isValid(parsed)) return "Invalid Date";
  switch (type) {
    case "date":
      return format(parsed, "do MMM yyyy");
    case "datetime":
      return format(parsed, "do MMM yyyy p");
    case "datetime-short":
      return format(parsed, "dd/MM/yyyy p");
    default:
      return "Invalid Date";
  }
};

export const formatFullDateOrIsoStringInTz = (
  date: Date | string,
  type: FormatType,
  tz?: string,
) => {
  const actualTz = tz ?? getBrowserTimeZone();
  switch (type) {
    case "date":
      return formatInTimeZone(date, actualTz, "PPPP OOO");
    case "datetime":
      return formatInTimeZone(date, actualTz, "PPPP p OOO");

    default:
      return "Invalid Date";
  }
};

/**
 * Formats a date string in the format of yyyy-mm-dd as a readable date string
 * TEMPORARY: This is currently just formatted a normal datestring as needed
 * IN THE FUTURE: Custom Field dates will be completely timezone independant (ie: if it says 11/10/2023 in one timezone it says exactly that everywhere)
 */
export const formatCustomFieldDateForRender = (date: string) => {
  // const parsed = parse(date, "yyyy-mm-dd", new Date());
  const parsed = new Date(date);
  if (!isValid(parsed)) return "Invalid Date";
  return format(parsed, "do MMM yyyy");
};


export const formatDateSinceNow = (then: Date) => {
  return formatRelative(then, new Date());
}
