import {
  extendTheme,
  ThemeOverride,
  withDefaultColorScheme,
} from "@chakra-ui/react";

import tableStyleExtension from "./components/table";
import tabsStyleExtension from "./components/tabs";
import menuStyleExtension from "./components/menu";
import cardStyleExtension from "./components/card";
import popoverStyleExtension from "./components/popover";

export const clerkStyle = {
  variables: {
    colorPrimary: "#0051FF", // brand.500
  colorDanger: "#9B2C2C", // red.700
  colorSuccess: "#6ABF4B", // primary2.main
  colorWarning: "#D69E2E", // yellow.500
  fontSize: "1rem",
  },
  elements: {
    cardBox: {
      boxShadow: "none",
      borderWidth: "1px",
    }
  }
}

export const colorExtension: ThemeOverride = {
  colors: {
    primary1: {
      main: "#0033A1",
      light: "#4CC8ED",
    },
    primary2: {
      main: "#6ABF4B",
      light: "#C2D500",
    },
    brand: {
      "50": "#E5EEFF",
      "100": "#B8CEFF",
      "200": "#8AAFFF",
      "300": "#5C8FFF",
      "400": "#2E70FF",
      "500": "#0051FF",
      "600": "#0041CC",
      "700": "#003099",
      "800": "#002066",
      "900": "#001033",
    },
  },
  shadows: {
    xs: "0 0 0 1px rgba(0, 0, 0, 0.10)",
  },
};

// New sizes
const styleSizesExtension: ThemeOverride = {
  sizes: {
    container: {
      "2xl": "1536px",
      "3xl": "1792px",
    },
    "top-bar": "54px",
  },
  space: {
    "top-bar": "54px",
  },
};

const colorModeExtension: ThemeOverride = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

// This is to fix problems with bootstrap interacting with chakra css
const styleFixExtension: ThemeOverride = {
  components: {
    Button: {
      baseStyle: {
        outline: "2px solid transparent !important",
      },
    },
    Menu: {
      baseStyle: {
        item: {
          outline: "2px solid transparent !important",
        },
      },
    },
  },
};

const fontExtension: ThemeOverride = {
  fonts: {
    heading: "Open Sans",
    body: "Open Sans",
  },
};

const theme = extendTheme(
  colorExtension,
  colorModeExtension,
  styleFixExtension,
  styleSizesExtension,
  fontExtension,
  tableStyleExtension,
  menuStyleExtension,
  tabsStyleExtension,
  cardStyleExtension,
  popoverStyleExtension,
  withDefaultColorScheme({ colorScheme: "brand" }),
);

export default theme;
