import {
  useAuth as useAuthClerk,
  useUser,
  useUser as useUserClerk,
} from "@clerk/clerk-react";
import { useQuery, useQueryClient, UseQueryResult } from "react-query";

/**
 * All front end stuff that touches clerk should be here, isntead of importing clerk directly
 */

export type AuthState = {
  userID: string | null;
  isLoaded: boolean;
  getToken: () => Promise<string | null>;
  signOut: ReturnType<typeof useAuthClerk>["signOut"];
};

/**
 * Returns the api token for the current authenticated user
 * This should trigger a refresh of the api token if needed
 */
export function useAuth(): AuthState {
  const { getToken, userId, isLoaded, signOut } = useAuthClerk();
  return { getToken, isLoaded, userID: userId || null, signOut };
}

/**
 *
 */
export function useAuthUser() {
  const { user } = useUserClerk();
  return user;
}

type AuthSession = {
  sessionID: string;
  isCurrent: boolean;
  lastActiveDate: Date;
  revokeSession: () => Promise<void>;
  host: {
    browserName?: string;
    browserVersion?: string;
    deviceType?: string;
    location: string;
    isMobile: boolean;
  };
};

type AuthSessions = {
  sessions: AuthSession[];
};

/**
 *
 */
export function useAuthSessionsQuery(): UseQueryResult<AuthSessions, Error> {
  const { user } = useUserClerk();
  const queryClient = useQueryClient();
  const auth = useAuthClerk();
  return useQuery<AuthSessions, Error>(
    ["clerk:auth", "useAuthSessions"],
    async () => {
      const sessionsData = await user?.getSessions();
      const sessions: AuthSession[] =
        sessionsData
          ?.filter((sess) => sess.status === "active")
          .map((sess) => {
            return {
              sessionID: sess.id,
              lastActiveDate: sess.lastActiveAt,
              isCurrent: sess.id === auth.sessionId,
              revokeSession: async () => {
                await sess.revoke();
                queryClient.invalidateQueries([
                  "clerk:auth",
                  "useAuthSessions",
                ]);
              },
              host: {
                browserName: sess.latestActivity.browserName,
                browserVersion: sess.latestActivity.browserVersion,
                deviceType: sess.latestActivity.deviceType,
                location: `${sess.latestActivity.city}, ${sess.latestActivity.country}`,
                isMobile: !!sess.latestActivity.isMobile,
              },
            } satisfies AuthSession;
          }) || [];
      sessions.sort((a, b) => {
        return a.isCurrent ? -1 : b.isCurrent ? 1 : 0;
      });
      return {
        sessions,
      };
    },
  );
}

export function useAuthTOTP() {
  const { user } = useUser();
  if (!user) return null;
  const { verifyTOTP, createTOTP, totpEnabled, disableTOTP } = user;
  return {
    verifyTOTP,
    createTOTP,
    totpEnabled,
    disableTOTP,
  };
}
