import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card as ReactCard,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import { Meh } from "react-feather";
import { useAppState } from "../../components/App/AppProvider";
import { Select } from "../../components/Forms/Field/Select";
import { Card } from "../../components/UI/Card/Card";
import { Header } from "../../components/UI/Header/Header";
import { Loading } from "../../components/UI/Loading/Loading";
import * as Permissions from "../../shared/constants/permission";
import { Account } from "../../shared/definitions/accounts";
import * as Access from "../../utilities/access";
import { get, post } from "../../utilities/request";
import useToast from "../../utilities/useToast";
import { useAuth } from "../../utilities/useAuth";

type SuspendAccountPageStatus = "Loading" | "Ready";

const ScreensAdminSuspendAccount = () => {
  const { getToken } = useAuth();

  const { auth, app } = useAppState();
  const [data, setData] = useState<any[]>([]);
  const [pageStatus, setPageStatus] =
    useState<SuspendAccountPageStatus>("Loading");
  const [selectedAccountID, setSelectedAccountID] = useState<string>("");

  useEffect(() => {
    const fetchAccounts = async () => {
      const accounts = await get("admin/accounts", getToken);
      setData(accounts.data);
      setPageStatus("Ready");
    };

    if (
      Access.checkAccess(
        app.permissions_LEGACY,
        Permissions.CodeAdminAccounts,
        Permissions.TypeRead,
        false,
      )
    ) {
      fetchAccounts();
    } else {
      setPageStatus("Ready");
    }
  }, [auth, app.permissions_LEGACY]);

  return pageStatus === "Ready" ? (
    data.length > 0 ? (
      <Container>
        <Header
          breadcrumbs={[
            { title: "Admin", link: "/caadmin" },
            {
              title: "Disable/Enable Account",
              link: "/caadmin/suspendaccount",
            },
          ]}
          title=""
        />
        <Card title="Disable/Enable account" collapsable={false}>
          <Row>
            <Col>Select an account:</Col>
            <Col>
              <Select
                onUpdate={(e: any) =>
                  e !== null && setSelectedAccountID(e.value)
                }
                name="Account"
                value={selectedAccountID}
                unselectedOption="Select an account"
                optionsList={data}
                optionsChild={(listItem: any) => ({
                  value: listItem.AccountID,
                  label: `${listItem.AccountName} (${listItem.AccountID})`,
                })}
                readOnly={false}
              />
            </Col>
          </Row>
        </Card>
        {selectedAccountID ? (
          <ScreensAdminAccountDetails accountDetailsID={selectedAccountID} />
        ) : (
          ""
        )}
      </Container>
    ) : (
      <Container>
        <ReactCard style={{ marginTop: "20%", textAlign: "center" }}>
          <Row style={{ marginBottom: "20px" }}>
            <Col>
              <Meh />
            </Col>
          </Row>
          <Row>
            <Col>
              <h1>You do not have permission to view this page.</h1>
            </Col>
          </Row>
        </ReactCard>
      </Container>
    )
  ) : (
    <Loading />
  );
};

type AccountDetailsPageStatus = "Loading" | "Ready" | "Submitting";

type ScreensAdminAccountDetailsProps = {
  accountDetailsID: string;
};

const ScreensAdminAccountDetails = ({
  accountDetailsID,
}: ScreensAdminAccountDetailsProps) => {
  const { getToken } = useAuth();

  const { app, auth } = useAppState();
  const [data, setData] = useState<Account>();
  const [pageStatus, setPageStatus] =
    useState<AccountDetailsPageStatus>("Loading");
  const { displayToast } = useToast();
  const buttonDisabled = pageStatus === "Submitting";
  useEffect(() => {
    const fetchAccount = async () => {
      setPageStatus("Loading");
      const account = await get(`admin/account/${accountDetailsID}`, getToken);
      setData(account.data[0]);
      setPageStatus("Ready");
    };

    if (
      Access.checkAccess(
        app.permissions_LEGACY,
        Permissions.CodeAdminAccounts,
        Permissions.TypeRead,
        false,
      )
    ) {
      fetchAccount();
    }
  }, [auth, app.permissions_LEGACY, accountDetailsID]);

  const updateAccount = async (account: Account) => {
    setPageStatus("Submitting");
    account.AccountActive = account.AccountActive ? 0 : 1;
    try {
      const results = await post(
        `admin/account/${account.AccountID}`,
        data,
        getToken,
      );
      setData(results.data[0]);
      displayToast({
        status: "success",
        title: `Account updated successfully`,
      });
    } catch (err) {
      displayToast({
        status: "error",
        title: `Failed to update Account`,
      });
    }
    setPageStatus("Ready");
  };

  return pageStatus !== "Loading" ? (
    data ? (
      <Card title="Account details" collapsable={false}>
        <Form>
          <Form.Group as={Row}>
            <Form.Label column sm="2">
              Account Name
            </Form.Label>
            <Col sm={9}>{data.AccountName}</Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2">
              Account ID
            </Form.Label>
            <Col sm={9}>{data.AccountID}</Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2">
              Account Owner
            </Form.Label>
            <Col sm={9}>{data.AccountOwnerName}</Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2">
              Account Created
            </Form.Label>
            <Col sm={9}>
              {moment.utc(data.CreateTs).local().format("DD/MM/YYYY HH:mm")}
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2">
              Account Modified
            </Form.Label>
            <Col sm={9}>
              {moment.utc(data.ModifiedTs).local().format("DD/MM/YYYY HH:mm")}
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2">
              Account Status
            </Form.Label>
            <Col sm={9}>{data.AccountActive ? "Active" : "Disabled"}</Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2">
              Available actions
            </Form.Label>
            {Access.checkAccess(
              app.permissions_LEGACY,
              Permissions.CodeAdminAccounts,
              Permissions.TypeUpdate,
              false,
            ) ? (
              <Col sm="auto">
                <Button
                  disabled={buttonDisabled}
                  onClick={() => updateAccount(data)}>
                  {data.AccountActive ? "Disable account" : "Enable account"}
                </Button>
              </Col>
            ) : (
              "You do not have permission to edit this account."
            )}
          </Form.Group>
        </Form>
      </Card>
    ) : (
      <Container>
        <ReactCard style={{ marginTop: "20%", textAlign: "center" }}>
          <Row style={{ marginBottom: "20px" }}>
            <Col>
              <Meh />
            </Col>
          </Row>
          <Row>
            <Col>
              <h1>You do not have permission to view this page.</h1>
            </Col>
          </Row>
        </ReactCard>
      </Container>
    )
  ) : (
    <Loading />
  );
};

export default ScreensAdminSuspendAccount;
