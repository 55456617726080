import { Upload } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import OneDriveLogo from "../../../images/OneDriveLogo.png";
import { OneDriveConfig } from "../../../shared/constants/oneDriveConfig";
import { get } from "../../../utilities/request";
import { useAuthState } from "../../App/AppProvider";
import { typesAccepted, UploaderGetType, UploaderParseType } from "./Uploader";
import { useAuth } from "../../../utilities/useAuth";

/*
    OneDrive Uploader is based off the existing Uploader Component but also contains authorisation and token control code
*/

interface OneDriveUploaderProps {
  fileList: any[];
  uploading: boolean;
  previewVisible?: boolean;
  previewImage?: string;
  onChange: (event: any) => void;
  readOnly: boolean;
  disabled?: boolean;
  name?: string;
  limitTypes?: string;
  limitTypesExplanation?: string;
}

const OneDriveUploader = (props: OneDriveUploaderProps): any => {
  const { getToken } = useAuth();
  const [files, setFiles] = useState<any>({
    fileList: props.fileList
      ? props.fileList.length > 0
        ? props.fileList
        : []
      : [],
    uploading: false,
    previewVisible: false,
    previewImage: "",
  });
  const [isAuthed, setIsAuthed] = useState<boolean>(false);

  useEffect(() => {
    const checkAuth = async () => {
      const info: any = await get("settings/onedrive", getToken);
      console.log("info", info.data);
      setIsAuthed(info.data.connection !== null);
    };

    const logger = async () => {
      if (files.fileList.length > 0) {
        await Promise.all(
          files.fileList.map(async (file: any) => {
            if (!file.status) {
              if (typeof file.arrayBuffer === "function") {
                const content = await file.arrayBuffer();
                if (!file.buffer) {
                  const prestring = `data:${UploaderParseType(
                    file.type,
                  )};base64,`;
                  const poststring = Buffer.from(content).toString("base64");
                  file.buffer = prestring.concat(poststring);
                }
              }
            }
          }),
        );
      }
    };

    checkAuth();
    logger();
  }, [files]);

  if (props.readOnly) {
    return props.fileList && props.fileList.length > 0 ? (
      props.fileList.map((file: any, index: number) => (
        <p>
          {UploaderGetType(file.type)}
          <Link
            key={index}
            to={file.url}
            target="_blank"
            onClick={(event) => {
              event.preventDefault();
              window.open(file.url);
            }}>
            {" "}
            {file.name}{" "}
          </Link>
        </p>
      ))
    ) : (
      <Form.Label>0 files uploaded</Form.Label>
    );
  }
  if (!props.readOnly && !isAuthed) {
    return props.fileList && props.fileList.length > 0 ? (
      props.fileList
        .map((file: any, index: number) => (
          <p>
            {UploaderGetType(file.type)}
            <Link
              key={index}
              to={file.url}
              target="_blank"
              onClick={(event) => {
                event.preventDefault();
                window.open(file.url);
              }}>
              {" "}
              {file.name}{" "}
            </Link>
          </p>
        ))
        .concat(
          <p>
            <MicrosoftIntegrationSetupButton isAuthed={isAuthed} />
          </p>,
        )
    ) : (
      <>
        <p>
          <Form.Label>0 files uploaded</Form.Label>
        </p>
        <p>
          <MicrosoftIntegrationSetupButton isAuthed={isAuthed} />
        </p>
      </>
    );
  }
  return (
    <div>
      <span>
        <div
          className="ant-upload ant-upload-drag"
          style={{ color: "#6b52f8" }}>
          <span
            tabIndex={0}
            className="ant-upload ant-upload-btn"
            role="button"
            style={{ color: "#6b52f8" }}>
            <Upload
              multiple
              accept={props.limitTypes ? props.limitTypes : typesAccepted}
              onChange={props.onChange}
              fileList={props.fileList}
              disabled={props.disabled}
              name={props.name}
              showUploadList
              onRemove={(file: any) => {
                setFiles((state: any) => {
                  const index = state.fileList.indexOf(file);
                  const newFileList = state.fileList.slice();
                  newFileList.splice(index, 1);
                  return { ...files, fileList: newFileList };
                });
              }}
              onPreview={async (file: any) => {
                window.open(file.url);
              }}
              beforeUpload={(file: any) => {
                // here we need to scan for viruses
                // then see if extension is modified as well or it is an executable
                setFiles((state: any) => ({
                  ...files,
                  fileList: [...state.fileList, file],
                }));
                return false;
              }}>
              <p className="ant-upload-drag-icon" style={{ color: "#6b52f8" }}>
                <img
                  src={OneDriveLogo}
                  alt="OneDriveLogo"
                  style={{ width: "200px" }}
                />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload a file to OneDrive
              </p>
              <p className="ant-upload-hint">
                Accepts files of type:{" "}
                {props.limitTypesExplanation
                  ? props.limitTypesExplanation
                  : ".pdf, .png, .jpeg, .jpg, .gif, all Microsoft Office types"}
              </p>
              <p className="ant-upload-hint">
                Files are only saved when 'Submit' is pressed
              </p>
            </Upload>
          </span>
        </div>
      </span>
    </div>
  );
};

const MicrosoftIntegrationSetupButton = (props: { isAuthed: boolean }) => {
  const client_id = OneDriveConfig.ClientID;
  const scope = OneDriveConfig.Scopes;
  const redirect_uri = `${window.location.protocol}//${window.location.host}${OneDriveConfig.RedirectEndpoint}`;

  const OneDriveUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${client_id}&scope=${scope}&response_type=code&redirect_uri=${redirect_uri}`;

  const OneDriveCallback = `${window.location.pathname}${window.location.search}`;
  window.localStorage.setItem(`OneDriveCallback`, OneDriveCallback);

  if (!props.isAuthed) {
    return (
      <a href={OneDriveUrl}>
        <Button variant="outline-dark">Login for OneDrive Uploads</Button>
      </a>
    );
  }
  return null;
};

export { OneDriveUploader };
