import {
  Box,
  Center,
  HStack,
  Text,
  Button,
  VStack,
  Link,
} from "@chakra-ui/react";
import { SignIn } from "@clerk/clerk-react";

export function LoginScreen() {
  // const isLoggedIn = baseAppState.type !== "loggedout";

  // const [urlSearchParams] = useSearchParams();
  // const redirect = urlSearchParams.get("redirect");
  // return (
  // <div>
  //   {isLoggedIn ? (
  //     <Redirect
  //       to={redirect ? decodeURIComponent(redirect) : "/"}
  //     />
  //   ) : null}
  // </div>
  return (
    <Center h="100vh">
      <VStack justify="space-between" h="100%">
        
          <VStack flexGrow="1" align="center" justify="center">
          <SignIn path="/login" />
          </VStack>
        <Box>
          <chakra-scope>
            <HStack w="full" justifyContent="center">
              <Text>Don't have an Account?</Text>
              <Button
                size="md"
                variant="link"
                as={Link}
                href="https://certcrowd.com/contact/">
                Contact Us
              </Button>
            </HStack>
            <Box pb={6}>
              <VStack w="full" justifyContent="center">
                <Text fontSize="xs" textAlign="center">
                  By continuing you agree to CertCrowd's{" "}
                  <Link
                    textDecor="underline"
                    fontWeight="semibold"
                    href="https://certcrowd.com/terms/">
                    {" "}
                    Terms & Conditions
                  </Link>{" "}
                  and{" "}
                  <Link
                    fontWeight="semibold"
                    textDecor="underline"
                    href="https://certcrowd.com/privacy-policy/">
                    Privacy Policy
                  </Link>
                  .
                </Text>
              </VStack>
            </Box>
          </chakra-scope>
        </Box>
      </VStack>
    </Center>
  );
}
