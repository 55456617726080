import {
  Box,
  Heading,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useUser } from "@clerk/clerk-react";
import { useState } from "react";
import { useAppState } from "../../App/AppProvider";
import Spinner from "../../chakra/common/Spinner";
import Icon from "../Icon";
import { useAuth } from "../../../utilities/useAuth";
import { useApiMutation } from "../../../utilities/apibelRequest";

const TopbarAccount = () => {

  const changeAccountMutation = useApiMutation("app/changeUserAccount");
  const { app } = useAppState();
  const { userAccounts, accountInfo } = app;
  const [status, setStatus] = useState<"changing" | "signingout" | "ready">(
    "ready",
  );
  const { signOut } = useAuth();
  const accountsToDisplay = userAccounts
    .filter((acc) => acc.accountID !== accountInfo?.accountID && acc.active)
    .sort((a, b) => a.accountName.localeCompare(b.accountName));

  const handleChangeAccount = async (newAccountID: string) => {
    setStatus("changing");
    try {
      await changeAccountMutation.mutateAsync({
        accountID: newAccountID,
      });
      window.location.href = "/";
    } catch (e) {
      console.error(e);
      window.location.reload(); 
    }
  };

  const handleSignOut = async () => {
    setStatus("signingout");
    window.localStorage.clear();
    try {
      await signOut({ redirectUrl: "/", });
    } catch (e) {
      console.error(e);
    }
    window.location.reload();
  };

  return (
    <MenuList
      sx={{
        ".chakra-menu__group": {
          maxHeight: "60vh",
          overflowX: "auto",
          position: "relative",
        },
      }}>
      <VStack w="full">
        <Heading size="sm">{accountInfo.accountName}</Heading>
        <Text fontWeight="bold" fontSize="xs">
          Powered by CertCrowd
        </Text>
      </VStack>

      {accountsToDisplay.length >= 1 ? (
        <>
          <MenuDivider />
          <MenuGroup
            title={
              status === "changing" ? "Changing Account..." : "Change Account"
            }>
            {accountsToDisplay.map(({ accountName, accountID }) => (
              <MenuItem
                disabled={status === "changing"}
                color={status === "changing" ? "gray.400" : undefined}
                onClick={() => handleChangeAccount(accountID)}
                key={accountID}>
                {accountName}
              </MenuItem>
            ))}
            {status === "changing" && (
              <Box
                pos="absolute"
                top="40%"
                left="50%"
                transform="translate(-50%)">
                <Spinner size="lg" />
              </Box>
            )}
          </MenuGroup>
        </>
      ) : null}
      <MenuDivider />
      <MenuItem onClick={handleSignOut} icon={<Icon name="LogOut" />}>
        Log Out
      </MenuItem>
    </MenuList>
  );
};

export default TopbarAccount;
