import { Route, Routes, useLocation } from "react-router-dom";
import Redirect from "./components/common/Redirect";
import { LoginScreen } from "./screens/Login/LoginScreen";

export function LoginRedirect() {

   console.log("LoginRedirect"); 
  const location = useLocation();

  if (location.pathname.length <= 1 && location.search.length <= 1) { // Ignore empty, since its just a /
    return <Redirect to="/login" />;
  }

  const redirectLocation = encodeURIComponent(
    `${location.pathname}${location.search}`,
  );

  return <Redirect to={`/login?redirect=${redirectLocation}`} />;
}


export function UnauthenticatedApp() {
    return <Routes>
    <Route
      path="/login/*"
      element={<LoginScreen />}
    />
    <Route
      path="*"
      element={<LoginRedirect />}
    />
  </Routes>
}