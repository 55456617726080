import React from "react";
import { Container } from "react-bootstrap";
import { useAppState } from "../../../components/App/AppProvider";
import { Card } from "../../../components/UI/Card/Card";
import LegacyScreenContainer from "../../../components/UI/LegacyScreenContainer";
import { isJobsAccount } from "../../../constants/JobsVisibleCheck";
import { WorkflowMaxIntegration } from "./WorkflowMaxIntegration";

const Integrations = () => {
  const appState = useAppState();
  return (
    <LegacyScreenContainer pageTitle="Integration Settings" breadcrumbs={[]}>
    </LegacyScreenContainer>
  );
};

export { Integrations };
