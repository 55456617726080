import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  Text,
  Flex,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { Button } from "react-bootstrap";
import { useCallback, useState } from "react";
import { createPortal } from "react-dom";
import { debounce } from "lodash";
import { useQueryClient } from "react-query";
import Icon from "../../components/UI/Icon";
import { useApiMutation, useApiQuery } from "../../utilities/apibelRequest";

type Props = {
  selectedIDs: string[];
  recordID: string;
};

type SearchableMenuListProps = {
  selectedIDs: string[];
  onSelect: (manualSectionID: string) => void;
};
const SearchableMenuList = ({
  onSelect,
  selectedIDs,
}: SearchableMenuListProps) => {
  const [rawValue, setRawValue] = useState("");
  const [value, setValue] = useState("");

  const setValueDebounced = useCallback(
    debounce((v: string) => {
      setValue(v);
    }, 500),
    [setValue],
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRawValue(e.target.value);
    setValueDebounced(e.target.value);
  };

  const sectionsQuery = useApiQuery("manual/manySectionsForSelection", {
    query: value,
  });

  const sections = (
    sectionsQuery.isSuccess ? [...sectionsQuery.data.sections] : []
  ).filter((section) => !selectedIDs.includes(section.manualSectionID));

  const { body } = document;
  return createPortal(
    <chakra-scope>
      <MenuList maxH={380} p={0} overflowY="auto" zIndex={12}>
        <Box w="full">
          <Box
            position="sticky"
            top={0}
            zIndex={1}
            backgroundColor="white"
            padding={2}>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <Icon style={{ color: "#a0aec0" }} name="Search" />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search sections"
                value={rawValue}
                onChange={handleChange}
                autoFocus
              />
            </InputGroup>
          </Box>
          {sections.map((section) => (
            <MenuItem
              key={section.manualSectionID}
              onClick={() => onSelect(section.manualSectionID)}>
              <Flex gap="2" as="span">
                {section.title}{" "}
                <Text as="span" color="gray.600" fontStyle="italic">
                  ({section.manual.name})
                </Text>
              </Flex>
            </MenuItem>
          ))}
        </Box>
      </MenuList>
    </chakra-scope>,
    body,
  );
};

export function ManualSectionDropdown({ recordID, selectedIDs }: Props) {
  const queryClient = useQueryClient();

  const createLinkedSectionMutation = useApiMutation(
    "manual/createSectionRecordLink",
  );

  const handleLink = async (manualSectionID: string) => {
    await createLinkedSectionMutation.mutateAsync({
      recordID,
      manualSectionID,
    });
    queryClient.invalidateQueries(["manual"]);
  };

  return (
    <Menu size="sm" preventOverflow isLazy>
      <MenuButton size="sm" as={Button} className="dropdown-toggle">
        Link manual section
      </MenuButton>
      {!createLinkedSectionMutation.isLoading && (
        <SearchableMenuList onSelect={handleLink} selectedIDs={selectedIDs} />
      )}
    </Menu>
  );
}
