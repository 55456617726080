import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Tooltip,
  InputGroup,
} from "react-bootstrap";

import moment from "moment";
import * as Feather from "react-feather";
import { Formik } from "formik";
import * as Yup from "yup";
import { Auth } from "aws-amplify";
import { Link, useNavigate } from "react-router-dom";
import { DateField } from "../../components/Forms/Field/Date";
import * as Access from "../../utilities/access";
import * as Permissions from "../../shared/constants/permission";
import * as RecordStatus from "../../shared/constants/recordStatus";
import { ScreenProps } from "../../App.d";
import { Header } from "../../components/UI/Header/Header";
import { Card } from "../../components/UI/Card/Card";
import { Loading } from "../../components/UI/Loading/Loading";
import { Text } from "../../components/Forms/Field/Text";
import { Textarea } from "../../components/Forms/Field/Textarea";
import { HelpText } from "../../components/Forms/HelpText/HelpText";

import * as Request from "../../utilities/request";
import { getUserAccountStatus } from "../../utilities/userAccountStatus";
import * as UserAccountStatus from "../../shared/constants/userAccountStatus";
import { CheckType } from "../../components/Forms/Field/Radio";
import {
  getOrdinal,
  renderNotificationRecurrence,
  renderNotificationMedium,
  renderMonthDropdown,
  mySqlDayOfWeekEquivilant,
} from "../../components/App/notificationSettings";

import { SpinnyButton } from "../../components/Forms/Field/SpinnyButton";
import { useAppState } from "../App/AppProvider";
import useToast from "../../utilities/useToast";
import NotFoundScreen from "../../screens/NotFound";
import { AuthState, useAuth } from "../../utilities/useAuth";

const validationSchema: any = Yup.object().shape({
  UserEmail: Yup.string()
    .email()
    .required("Must provide a valid email address"),
  UserFirstName: Yup.string().required("Must provide first name"),
  UserLastName: Yup.string().required("Must provide a last name"),
});

type SingleUserParams = "userID";

type Props = {
  userID: string;
  setParentUserName?: (name: { firstName: string; lastName: string }) => void;
  listMode?: boolean;
  accountiCalActive?: boolean;
};

const SingleUserSettings = ({
  userID,
  setParentUserName,
  listMode,
  accountiCalActive,
}: Props) => {
  const { app } = useAppState();
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const { displayToast } = useToast();
  const [state, setState] = React.useState<any>(null);
  const [pageStatus, setStatus] = React.useState<any>("Loading");

  React.useEffect(() => {
    const getState = async () => {
      try {
        const results = await Request.get(`settings/user/${userID}`, getToken);
        // Hardcoded "Email" until we have more than one medium available
        results.data.NotificationSettings.NotificationMediumName = "Email";
        results.data.NotificationSettings.NotificationMediumID =
          "5f00e51f-37e2-4e6c-b892-9fa92990c759";
        setState(results.data);
        setStatus(userID === "new" ? "New" : "Ready");
        if (setParentUserName) {
          setParentUserName({
            firstName: results.data.UserFirstName,
            lastName: results.data.UserLastName,
          });
        }
      } catch (err) {
        displayToast({
          status: "error",
          title: "Failed to retrieve User details",
        });
      }
    };

    if (auth.isLoggedIn) {
      getState();
    }
  }, [userID, auth]);

  React.useEffect(() => {
    if (auth.isLoggedIn) {
      setState((prev) => ({ ...prev, iCalIsActive: accountiCalActive }));
    }
  }, [accountiCalActive, auth.isLoggedIn]);

  const deleteUserAccount = async (state: any) => {
    const doDelete = async (userID: string) => {
      await Request.del(`settings/user/${userID}`, getToken);
      navigate(`/settings/users`);
    };

    if (state.UserID === app.attributes.userID) {
      const result = window.confirm(
        "WAIT: You are about to delete the account you are currently logged in as. Are you sure you want to do this?",
      );
      if (result) {
        const result2 = window.confirm(
          "Pressing OK will delete your own account, do you want to go through with this?",
        );
        if (result2) {
          await doDelete(state.UserID);
        }
      }
    } else {
      await doDelete(state.UserID);
    }
  };

  const changeEmailVerify = async (
    state: any,
    email: string,
    code: string,
    setFieldValue: any,
  ) => {
    const result = await Auth.verifyCurrentUserAttributeSubmit("email", code);
    if (result === "SUCCESS") {
      displayToast({
        status: "success",
        title: "Email address successfully updated",
      });

      setFieldValue("UserEmail", email);
      setFieldValue("resetEmailPassword", null);
      setFieldValue("resetEmailOne", null);
      setFieldValue("resetEmailTwo", null);
      setFieldValue("changeEmail", false);
      setFieldValue("enterVerification", false);
      setFieldValue("verificationCode", null);
    } else {
      displayToast({
        status: "error",
        title: "Unable to verify",
      });
    }
  };

  const changeEmail = async (
    state: any,
    emailOne: string,
    emailTwo: string,
    Password: string,
    setFieldValue: any,
  ) => {
    if (emailOne === emailTwo) {
      /* AWS Amplify does not have a method to check the password, but calling ChangePassword with the existing password
              will throw an error if it is incorrect, or change it to itself if its correct. */

      Auth.currentAuthenticatedUser()
        .then((user) => Auth.changePassword(user, Password, Password))
        .then(async (data) => {
          state.UserEmail = emailOne;
          const result = await Request.post(
            `settings/user/${userID}`,
            state,
            getToken,
          );

          if (result.status === 200) {
            const user = await Auth.currentAuthenticatedUser();
            const authResult = await Auth.updateUserAttributes(user, {
              email: emailOne,
            });

            setFieldValue("verificationCode", "");
            setFieldValue("enterVerification", true);
          } else {
            displayToast({
              status: "error",
              timeout: 3000,
              title: "Error updating your email address",
            });
          }
        })
        .catch((err) => {
          console.log("err", err);

          if (err.message === "Incorrect username or password.") {
            err.message = "Incorrect password.";
          }

          displayToast({
            status: "error",
            title: "Error",
            description: err.message,
          });
        });
    } else {
      displayToast({
        status: "error",
        title: "Email addresses do not match",
        description: "Please check new email address and try again",
      });
    }
  };

  const resetPassword = async (
    setFieldValue: any,
    newPassword: string,
    oldPassword: string,
  ) => {
    Auth.currentAuthenticatedUser()
      .then((user) => Auth.changePassword(user, oldPassword, newPassword))
      .then((data) => {
        displayToast({
          status: "success",
          title: "Password successfully updated",
        });
        setFieldValue("changePassword", false);
      })
      .catch((err) => {
        console.log("err", err);
        displayToast({
          status: "error",
          title: "Error",
          description: err.message,
        });
      });
  };

  const retryUserAccount = async (state: any) => {
    try {
      const result = await Request.post(
        `settings/user/${state.UserID}/retry`,
        { UserEmail: state.UserEmail },
        getToken,
      );
      setState({ ...state, UserAccountStatusID: UserAccountStatus.Pending });
      displayToast({
        status: "success",
        title: "Email containing new invite has been sent",
      });
    } catch {
      displayToast({
        status: "danger",
        title: "Error attempting to resend email",
      });
    }
  };

  const renderHeaderCols = (
    state: any,
    pageStatus: any,
    setFieldValue: any,
  ) => {
    const editButton = Access.checkAccess(
      app.permissions_LEGACY,
      Permissions.CodeUserSettings,
      Permissions.TypeUpdate,
      true,
    )
      ? {
          colProps: { sm: "auto" },
          children: (
            <Col sm="auto">
              <Button
                type="button"
                variant="outline-dark"
                onClick={() => {
                  if (
                    state.NotificationSettings.RecordStatusID ===
                    RecordStatus.Deleted
                  ) {
                    setFieldValue(
                      "NotificationSettings.ActionRecurrenceText",
                      "No Notification",
                    );
                  }
                  setStatus("Editing");
                }}>
                <Feather.Edit2 className="feather" size="16" />
                Edit
              </Button>
            </Col>
          ),
        }
      : {
          colProps: { sm: "auto" },
          children: (
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip id="not-authorised-to-edit">
                  You are not authorised to edit this
                </Tooltip>
              }>
              <Button type="button" variant="outline-dark" className="disabled">
                <Feather.Edit2 className="feather" size="16" />
                Edit
              </Button>
            </OverlayTrigger>
          ),
        };

    const deleteButton = {
      colProps: { sm: "auto" },
      children: (
        <Col sm="auto">
          <DeleteButton
            onClick={deleteUserAccount.bind(null, state)}
            responsibilities={state.Responsibilities}
          />
        </Col>
      ),
    };

    const retryButton = {
      colProps: { sm: "auto" },
      children: (
        <Col sm="auto">
          <SpinnyButton
            text="Retry invite"
            onClick={retryUserAccount.bind(null, state)}
          />
        </Col>
      ),
    };

    if (
      pageStatus === "Ready" &&
      (state.UserAccountStatusID === UserAccountStatus.Pending ||
        state.UserAccountStatusID === UserAccountStatus.Inactive)
    ) {
      return [retryButton, editButton];
    }
    if (pageStatus === "Ready") {
      return [editButton];
    }
    if (
      pageStatus === "Editing" &&
      Access.checkAccess(
        app.permissions_LEGACY,
        Permissions.CodeUserSettings,
        Permissions.TypeDelete,
        state.UserID === app.attributes.userID,
      ) &&
      state.UserAccountStatusID !== UserAccountStatus.Inactive
    ) {
      return [deleteButton];
    }

    return [];
  };

  const renderUserList = (UserList: any[]) =>
    UserList.map((User: any, i: number) => {
      if (User.UserID !== state.UserID) {
        return (
          <Dropdown.Item
            key={`UserListDropDown${i}`}
            eventKey={JSON.stringify({
              value: User.UserID,
              key: `${User.UserFirstName} ${User.UserLastName}`,
            })}>{`${User.UserFirstName} ${User.UserLastName}`}</Dropdown.Item>
        );
      }
    });

  if (
    !Access.checkAccess(
      app.permissions_LEGACY,
      Permissions.CodeUserSettings,
      Permissions.TypeRead,
      true,
    )
  ) {
    return null;
  }
  if (pageStatus !== "Loading") {
    if (state.UserID) {
      return (
        <Formik
          initialValues={state}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            setState(values);
            setStatus("Submitting");

            if (pageStatus === "New") {
              try {
                values.UserEmail = values.UserEmail.toLowerCase();
                const result = await Request.put("settings/user", values, getToken);

                navigate(`/settings/users`);
              } catch (err) {
                setStatus("New");
                displayToast({
                  status: "error",
                  title: "Unable to invite user",
                  description:
                    err.response.data.name === "CustomError"
                      ? err.response.data.message
                      : undefined,
                });
              }
            } else {
              try {
                /* Handle case where user is removing notifications */
                if (
                  values.NotificationSettings.RecordStatusID ===
                  RecordStatus.Deleted
                ) {
                  values.NotificationSettings.ActionRecurrenceText =
                    state.NotificationSettings.ActionRecurrenceText;
                  values.NotificationSettings.ActionRecurrenceID =
                    state.NotificationSettings.ActionRecurrenceID;
                }

                const result = await Request.post(
                  `settings/user/${userID}`,
                  values,
                  getToken,
                );

                if (result.status === 200) {
                  displayToast({
                    status: "success",
                    title: "Updated User details",
                  });
                }

                setStatus("Ready");

                actions.setSubmitting(false);
              } catch (err) {
                setStatus("Editing");
                actions.setSubmitting(false);
                displayToast({
                  status: "error",
                  title: "Unable to update user details",
                });
              }
            }
          }}
          render={(formikProps) => (
            <Card
              title="User Details"
              collapsable={false}
              headerColsRight
              headerCols={renderHeaderCols(
                state,
                pageStatus,
                formikProps.setFieldValue,
              )}>
              <Form onSubmit={formikProps.handleSubmit}>
                {/* General Details */}
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Email
                  </Form.Label>
                  <Col sm="9">
                    <Text
                      value={formikProps.values.UserEmail}
                      name="UserEmail"
                      readOnly={
                        pageStatus === "Ready" ||
                        pageStatus === "Submitting" ||
                        pageStatus === "Editing"
                      }
                      onUpdate={(event: any) => {
                        event.target.value = event.target.value
                          .trim()
                          .toLowerCase();
                        formikProps.handleChange(event);
                      }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    First Name
                  </Form.Label>
                  <Col sm="9">
                    <Text
                      value={formikProps.values.UserFirstName}
                      name="UserFirstName"
                      readOnly={
                        pageStatus === "Ready" || pageStatus === "Submitting"
                      }
                      onUpdate={formikProps.handleChange}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Last Name
                  </Form.Label>
                  <Col sm="9">
                    <Text
                      value={formikProps.values.UserLastName}
                      name="UserLastName"
                      readOnly={
                        pageStatus === "Ready" || pageStatus === "Submitting"
                      }
                      onUpdate={formikProps.handleChange}
                    />
                  </Col>
                </Form.Group>

                {/* Status and Role Settings */}
                {listMode ? (
                  <>
                    <Form.Group as={Row}>
                      <Form.Label column sm="2">
                        Status
                      </Form.Label>
                      <Col sm="9">
                        <Text
                          value={getUserAccountStatus(
                            state.UserAccountStatusID,
                          )}
                          name="UserAccountStatusID"
                          readOnly
                          onUpdate={formikProps.handleChange}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="2">
                        Roles
                      </Form.Label>
                      <Col sm="9">
                        {state.Roles.sort((a: any, b: any) =>
                          a.RoleName.localeCompare(b.RoleName, "en", {
                            numeric: true,
                          }),
                        ).map((role: any) => (
                          <Row key={role.RoleID}>
                            <Col>
                              <CheckType
                                label={
                                  <Link
                                    to={`/settings/role/${role.RoleID}`}
                                    target="_blank">
                                    {role.RoleName}
                                  </Link>
                                }
                                name={role.RoleName}
                                id={role.RoleID}
                                value={role.RoleID}
                                checked={formikProps.values.UserRoles.some(
                                  (userRole: any) =>
                                    userRole.RoleID === role.RoleID,
                                )}
                                onChange={(event: any) => {
                                  if (event.target.checked) {
                                    formikProps.setFieldValue("UserRoles", [
                                      ...formikProps.values.UserRoles,
                                      { RoleID: event.target.value },
                                    ]);
                                  } else {
                                    formikProps.setFieldValue(
                                      "UserRoles",
                                      formikProps.values.UserRoles.filter(
                                        (role: any) =>
                                          role.RoleID !== event.target.value,
                                      ),
                                    );
                                  }
                                }}
                                disabled={
                                  pageStatus !== "Editing" &&
                                  pageStatus !== "New"
                                }
                                type="checkbox"
                              />
                            </Col>
                          </Row>
                        ))}
                      </Col>
                    </Form.Group>
                  </>
                ) : null}

                {/* Notification Settings */}
                {pageStatus !== "New" ? (
                  <Form.Group as={Row} className="largeText">
                    <Form.Label column sm="2">
                      Notification Settings
                    </Form.Label>
                    <Col style={{ display: "inline-block" }}>
                      {pageStatus === "Editing" &&
                      Access.checkAccess(
                        app.permissions_LEGACY,
                        Permissions.CodeUserSettings,
                        Permissions.TypeUpdate,
                        state.UserID === app.attributes.userID,
                      ) ? (
                        <>
                          {formikProps.values.NotificationSettings
                            .RecordStatusID !== RecordStatus.Deleted ? (
                            <span>Send on a</span>
                          ) : null}

                          <Dropdown
                            onSelect={(e: any) => {
                              e = JSON.parse(e);

                              formikProps.setFieldValue(
                                "NotificationSettings.ActionRecurrenceText",
                                e.key,
                              );
                              formikProps.setFieldValue(
                                "NotificationSettings.ActionRecurrenceID",
                                e.value,
                              );

                              if (e.key !== "Once Off") {
                                formikProps.setFieldValue(
                                  "NotificationSettings.NotificationOnceOff",
                                  null,
                                ); // Remove when not in use
                              }

                              if (e.key === "No Notification") {
                                formikProps.setFieldValue(
                                  "NotificationSettings.RecordStatusID",
                                  RecordStatus.Deleted,
                                );
                              } else {
                                formikProps.setFieldValue(
                                  "NotificationSettings.RecordStatusID",
                                  RecordStatus.Active,
                                );
                              }

                              if (e.key === "Weekly") {
                                formikProps.setFieldValue(
                                  "NotificationSettings.NotificationSendOn",
                                  "2",
                                ); // Default to Monday
                              } else if (e.key === "Monthly") {
                                formikProps.setFieldValue(
                                  "NotificationSettings.NotificationSendOn",
                                  "1",
                                ); // Default to 1st
                              }
                            }}>
                            <Dropdown.Toggle
                              variant="outline-dark"
                              id="dropdown-statusFilter">
                              {formikProps.values.NotificationSettings
                                .ActionRecurrenceText
                                ? ` ${formikProps.values.NotificationSettings.ActionRecurrenceText}`
                                : "Set"}{" "}
                              Recurrence
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                key="NoNotification"
                                eventKey={JSON.stringify({
                                  value: null,
                                  key: "No Notification",
                                })}>
                                No Notification
                              </Dropdown.Item>
                              {renderNotificationRecurrence(
                                formikProps.values.NotificationSettings
                                  .NotificationOptions,
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </>
                      ) : formikProps.values.NotificationSettings
                          .RecordStatusID === RecordStatus.Active &&
                        state.UserAccountStatusID !==
                          UserAccountStatus.Inactive ? (
                        `Notifications will be sent ${formikProps.values.NotificationSettings.ActionRecurrenceText}`
                      ) : (
                        `No notifications set up`
                      )}
                      {formikProps.values.NotificationSettings
                        .RecordStatusID === RecordStatus.Active &&
                      formikProps.values.NotificationSettings
                        .ActionRecurrenceText === "Weekly" ? (
                        pageStatus === "Editing" &&
                        Access.checkAccess(
                          app.permissions_LEGACY,
                          Permissions.CodeUserSettings,
                          Permissions.TypeUpdate,
                          state.UserID === app.attributes.userID,
                        ) ? (
                          <>
                            <span>on</span>
                            <Dropdown
                              onSelect={(e: any) => {
                                e = JSON.parse(e);
                                formikProps.setFieldValue(
                                  "NotificationSettings.NotificationSendOn",
                                  e.value,
                                );
                              }}
                              className="inlineDropdown">
                              <Dropdown.Toggle
                                className="darkText"
                                variant="outline-dark"
                                id="dropdown-statusFilter">
                                {formikProps.values.NotificationSettings
                                  .NotificationSendOn
                                  ? mySqlDayOfWeekEquivilant(
                                      formikProps.values.NotificationSettings
                                        .NotificationSendOn,
                                    )
                                  : "Select day of week"}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  eventKey={JSON.stringify({
                                    value: "2",
                                    key: "Monday",
                                  })}>
                                  Monday
                                </Dropdown.Item>
                                <Dropdown.Item
                                  eventKey={JSON.stringify({
                                    value: "3",
                                    key: "Tuesday",
                                  })}>
                                  Tuesday
                                </Dropdown.Item>
                                <Dropdown.Item
                                  eventKey={JSON.stringify({
                                    value: "4",
                                    key: "Wednesday",
                                  })}>
                                  Wednesday
                                </Dropdown.Item>
                                <Dropdown.Item
                                  eventKey={JSON.stringify({
                                    value: "5",
                                    key: "Thursday",
                                  })}>
                                  Thursday
                                </Dropdown.Item>
                                <Dropdown.Item
                                  eventKey={JSON.stringify({
                                    value: "6",
                                    key: "Friday",
                                  })}>
                                  Friday
                                </Dropdown.Item>
                                <Dropdown.Item
                                  eventKey={JSON.stringify({
                                    value: "7",
                                    key: "Saturday",
                                  })}>
                                  Saturday
                                </Dropdown.Item>
                                <Dropdown.Item
                                  eventKey={JSON.stringify({
                                    value: "1",
                                    key: "Sunday",
                                  })}>
                                  Sunday
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </>
                        ) : formikProps.values.NotificationSettings
                            .RecordStatusID === RecordStatus.Active &&
                          state.UserAccountStatusID !==
                            UserAccountStatus.Inactive ? (
                          ` on ${mySqlDayOfWeekEquivilant(
                            formikProps.values.NotificationSettings
                              .NotificationSendOn,
                          )}`
                        ) : null
                      ) : null}
                      {formikProps.values.NotificationSettings
                        .RecordStatusID === RecordStatus.Active &&
                      formikProps.values.NotificationSettings
                        .ActionRecurrenceText === "Monthly" ? (
                        pageStatus === "Editing" &&
                        Access.checkAccess(
                          app.permissions_LEGACY,
                          Permissions.CodeUserSettings,
                          Permissions.TypeUpdate,
                          state.UserID === app.attributes.userID,
                        ) ? (
                          <>
                            <span>on the</span>
                            <Dropdown
                              onSelect={(e: any) => {
                                e = JSON.parse(e);
                                formikProps.setFieldValue(
                                  "NotificationSettings.NotificationSendOn",
                                  e.value,
                                );
                              }}
                              className="inlineDropdown">
                              <Dropdown.Toggle
                                className="darkText"
                                variant="outline-dark"
                                id="dropdown-statusFilter">
                                {getOrdinal(
                                  formikProps.values.NotificationSettings
                                    .NotificationSendOn,
                                )}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {renderMonthDropdown()}
                              </Dropdown.Menu>
                            </Dropdown>
                          </>
                        ) : formikProps.values.NotificationSettings
                            .RecordStatusID === RecordStatus.Active &&
                          state.UserAccountStatusID !==
                            UserAccountStatus.Inactive ? (
                          ` on the ${getOrdinal(
                            formikProps.values.NotificationSettings
                              .NotificationSendOn,
                          )}`
                        ) : null
                      ) : null}
                      {formikProps.values.NotificationSettings
                        .ActionRecurrenceText === "Once Off" ? (
                        pageStatus === "Editing" &&
                        Access.checkAccess(
                          app.permissions_LEGACY,
                          Permissions.CodeUserSettings,
                          Permissions.TypeUpdate,
                          state.UserID === app.attributes.userID,
                        ) ? (
                          <>
                            <span>on</span>
                            <div className="inlineDropdown">
                              <DateField
                                name="NotificationOnceOff"
                                allDates
                                selected={
                                  formikProps.values.NotificationSettings
                                    .NotificationOnceOff
                                }
                                onUpdate={(e: any) => {
                                  formikProps.setFieldValue(
                                    "NotificationSettings.NotificationOnceOff",
                                    e,
                                  );
                                }}
                                className="form-control dropdown-toggle btn btn-outline-dark darkText"
                                authState={auth}
                              />
                            </div>
                          </>
                        ) : formikProps.values.NotificationSettings
                            .RecordStatusID === RecordStatus.Active &&
                          state.UserAccountStatusID !==
                            UserAccountStatus.Inactive ? (
                          ` on ${moment
                            .utc(
                              formikProps.values.NotificationSettings
                                .NotificationOnceOff,
                            )
                            .local()
                            .format("DD-MMM-YYYY")}`
                        ) : null
                      ) : null}
                      {/* Remove the false &&, and the hardcoded setting of NotificationMediumName / NotificationMediumID when we have more than one medium */}
                      {false &&
                      pageStatus === "Editing" &&
                      Access.checkAccess(
                        app.permissions_LEGACY,
                        Permissions.CodeUserSettings,
                        Permissions.TypeUpdate,
                        state.UserID === app.attributes.userID,
                      ) ? (
                        <>
                          <span>via</span>
                          <Dropdown
                            onSelect={(e: any) => {
                              e = JSON.parse(e);
                              formikProps.setFieldValue(
                                "NotificationSettings.NotificationMediumName",
                                e.key,
                              );
                              formikProps.setFieldValue(
                                "NotificationSettings.NotificationMediumID",
                                e.value,
                              );
                            }}
                            className="inlineDropdown">
                            <Dropdown.Toggle
                              className="darkText"
                              variant="outline-dark"
                              id="dropdown-statusFilter">
                              {formikProps.values.NotificationSettings
                                .NotificationMediumName
                                ? formikProps.values.NotificationSettings
                                    .NotificationMediumName
                                : "Set"}{" "}
                              Medium
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {renderNotificationMedium(
                                formikProps.values.NotificationSettings
                                  .NotificationOptions,
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </>
                      ) : formikProps.values.NotificationSettings
                          .RecordStatusID === RecordStatus.Active &&
                        state.UserAccountStatusID !==
                          UserAccountStatus.Inactive ? (
                        ` via ${formikProps.values.NotificationSettings.NotificationMediumName}`
                      ) : null}
                    </Col>
                  </Form.Group>
                ) : null}

                {/* iCal Feed setup */}
                {state.iCalIsActive && pageStatus !== "New" ? (
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Calendar Feed
                    </Form.Label>
                    {formikProps.values.UserAccountiCalfeed ? (
                      <>
                        <Col sm="8">
                          <InputGroup>
                            <Form.Control
                              type="text"
                              value={formikProps.values.UserAccountiCalfeed}
                              name="UserAccountiCalfeed"
                              readOnly={false}
                              disabled
                              onChange={formikProps.handleChange}
                              style={{ height: "35px" }}
                            />
                            <InputGroup.Append>
                              <OverlayTrigger
                                placement="auto"
                                overlay={
                                  <Tooltip id="tooltip-download-iCalURL">
                                    <Textarea
                                      value="Open link"
                                      name={`tooltip-download-${userID}`}
                                      readOnly
                                      richText
                                    />
                                  </Tooltip>
                                }>
                                <Button
                                  type="button"
                                  variant="outline-dark"
                                  onClick={() => {
                                    window.open(
                                      formikProps.values.UserAccountiCalfeed,
                                    );
                                  }}>
                                  <Feather.Download size="16" />
                                </Button>
                              </OverlayTrigger>
                            </InputGroup.Append>
                            <InputGroup.Append>
                              <SpinnyButton
                                text={<Feather.Copy size="16" />}
                                OverlayText="Copy Calendar feed URL to clipboard"
                                onClick={async () => {
                                  const elem =
                                    document.createElement("textarea");
                                  elem.value =
                                    formikProps.values.UserAccountiCalfeed;
                                  document.body.appendChild(elem);
                                  elem.select();
                                  document.execCommand("copy");
                                  document.body.removeChild(elem);
                                }}
                              />
                            </InputGroup.Append>
                          </InputGroup>
                        </Col>
                        <Col sm="1">
                          <SpinnyButton
                            text={<Feather.Trash2 size="16" />}
                            OverlayText="Delete Calendar feed"
                            onClick={async () => {
                              const result = await Request.del(
                                "iCalFeed",
                                getToken,
                              );
                              formikProps.setFieldValue(
                                "UserAccountiCalfeed",
                                null,
                              );
                            }}
                          />
                        </Col>
                      </>
                    ) : (
                      <Col sm="9">
                        <SpinnyButton
                          text="Setup Calendar Feed"
                          onClick={async () => {
                            const result = await Request.post(
                              "iCalFeed",
                              "",
                              getToken,
                            );
                            formikProps.setFieldValue(
                              "UserAccountiCalfeed",
                              result.data,
                            );
                          }}
                        />
                      </Col>
                    )}
                  </Form.Group>
                ) : null}

                {/* Reassign all responsible records */}
                {window.location.pathname !== "/settings/account" &&
                pageStatus !== "New" &&
                Access.checkAccess(
                  app.permissions_LEGACY,
                  Permissions.CodeUserSettings,
                  Permissions.TypeUpdate,
                  false,
                ) &&
                Access.checkAccess(
                  app.permissions_LEGACY,
                  Permissions.CodeAccountSettings,
                  Permissions.TypeUpdate,
                  false,
                ) ? (
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Reassign Records
                    </Form.Label>
                    <Col sm={formikProps.values.reassignUser ? "3" : "5"}>
                      <Dropdown
                        onSelect={(e: any) => {
                          const parsed = JSON.parse(e);
                          formikProps.setFieldValue("reassignUser", parsed.key);
                          formikProps.setFieldValue(
                            "reassignUserID",
                            parsed.value,
                          );
                        }}>
                        <Dropdown.Toggle
                          variant="outline-dark"
                          id="dropdown-ReassignRecords">
                          {formikProps.values.reassignUser
                            ? formikProps.values.reassignUser
                            : `Move all records from this user to another user`}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {renderUserList(formikProps.values.UserList)}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                    <Col sm={formikProps.values.reassignUser ? "6" : "4"}>
                      {formikProps.values.reassignUser ? (
                        <SpinnyButton
                          text={`Reassign all records from ${formikProps.values.UserFirstName} ${formikProps.values.UserLastName} to ${formikProps.values.reassignUser}`}
                          variant="btn btn-outline-danger"
                          onClick={async () => {
                            const body = {
                              reassignFromID: formikProps.values.UserID,
                              reassignToID: formikProps.values.reassignUserID,
                            };
                            try {
                              const result = await Request.post(
                                "settings/reassignUser",
                                body,
                                getToken
                              );
                              displayToast({
                                status: "success",
                                title:
                                  "All responsible records have been reassigned",
                                description: result.data.message,
                              });
                              setState({
                                ...state,
                                Responsibilities: result.data.responsibilities,
                              });
                            } catch (e) {
                              console.log(e);
                              displayToast({
                                status: "error",
                                title: "Unable to reassign records",
                              });
                            }
                          }}
                        />
                      ) : null}
                    </Col>
                    <Col sm="1">
                      <HelpText
                        id="reassign"
                        text="This function will move all records belonging to this user to another user that you select. This includes all records and upcoming actions (historic actions will stay allocated to the original user)."
                      />
                    </Col>
                  </Form.Group>
                ) : null}

          
                {/* Change Password / Change Email */}
                {pageStatus !== "New" &&
                pageStatus !== "Editing" &&
                state.UserID === app.attributes.userID ? (
                  <>
                    {!formikProps.values.changePassword &&
                    !formikProps.values.changeEmail ? (
                      <Form.Group
                        as={Row}
                        style={{
                          textAlign: "center",
                          marginTop: "30px",
                          marginBottom: "0px",
                        }}>
                        <Col style={{ textAlign: "right" }}>
                          <button
                            onClick={() => {
                              formikProps.setFieldValue("changePassword", true);
                            }}
                            className="stealthLink">
                            Change Password
                          </button>
                        </Col>
                        <Col style={{ textAlign: "left" }}>
                          <button
                            onClick={() => {
                              formikProps.setFieldValue("changeEmail", true);
                            }}
                            className="stealthLink">
                            Change Email
                          </button>
                        </Col>
                      </Form.Group>
                    ) : (
                      <>
                        {formikProps.values.changePassword ? (
                          <>
                            <Form.Group
                              as={Row}
                              style={{
                                textAlign: "center",
                                paddingLeft: "20%",
                                marginTop: "30px",
                              }}>
                              <Form.Label column sm="3">
                                Old Password
                              </Form.Label>
                              <Col sm={5}>
                                <Text
                                  type="password"
                                  value={formikProps.values.resetPasswordOld}
                                  name="resetPasswordOld"
                                  readOnly={false}
                                  onUpdate={formikProps.handleChange}
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group
                              as={Row}
                              style={{
                                textAlign: "center",
                                paddingLeft: "20%",
                              }}>
                              <Form.Label column sm="3">
                                New Password
                              </Form.Label>
                              <Col sm={5}>
                                <Text
                                  type="password"
                                  value={formikProps.values.resetPasswordNew}
                                  name="resetPasswordNew"
                                  readOnly={false}
                                  onUpdate={formikProps.handleChange}
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group
                              as={Row}
                              style={{
                                textAlign: "center",
                                marginBottom: "0px",
                              }}>
                              <Col>
                                <Button
                                  onClick={(e: any) => {
                                    resetPassword(
                                      formikProps.setFieldValue,
                                      formikProps.values.resetPasswordNew,
                                      formikProps.values.resetPasswordOld,
                                    );
                                  }}
                                  style={{ float: "right" }}>
                                  Change Password
                                </Button>
                              </Col>
                              <Col>
                                <Button
                                  onClick={() => {
                                    formikProps.setFieldValue(
                                      "changePassword",
                                      false,
                                    );
                                  }}
                                  style={{ float: "left" }}>
                                  Cancel
                                </Button>
                              </Col>
                            </Form.Group>
                          </>
                        ) : !formikProps.values.enterVerification ? (
                          <>
                            <Form.Group
                              as={Row}
                              style={{
                                textAlign: "center",
                                paddingLeft: "20%",
                                marginTop: "30px",
                              }}>
                              <Form.Label column sm="3">
                                New Email Address
                              </Form.Label>
                              <Col sm={5}>
                                <Text
                                  type="text"
                                  value={formikProps.values.resetEmailOne}
                                  name="resetEmailOne"
                                  readOnly={false}
                                  onUpdate={(event: any) => {
                                    event.target.value = event.target.value
                                      .trim()
                                      .toLowerCase();
                                    formikProps.handleChange(event);
                                  }}
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group
                              as={Row}
                              style={{
                                textAlign: "center",
                                paddingLeft: "20%",
                              }}>
                              <Form.Label column sm="3">
                                Confirm New Email Address
                              </Form.Label>
                              <Col sm={5}>
                                <Text
                                  type="text"
                                  value={formikProps.values.resetEmailTwo}
                                  name="resetEmailTwo"
                                  readOnly={false}
                                  onUpdate={(event: any) => {
                                    event.target.value = event.target.value
                                      .trim()
                                      .toLowerCase();
                                    formikProps.handleChange(event);
                                  }}
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group
                              as={Row}
                              style={{
                                textAlign: "center",
                                paddingLeft: "20%",
                              }}>
                              <Form.Label column sm="3">
                                Password
                              </Form.Label>
                              <Col sm={5}>
                                <Text
                                  type="password"
                                  value={formikProps.values.resetEmailPassword}
                                  name="resetEmailPassword"
                                  readOnly={false}
                                  onUpdate={formikProps.handleChange}
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group
                              as={Row}
                              style={{
                                textAlign: "center",
                                marginBottom: "0px",
                              }}>
                              <Col>
                                <Button
                                  onClick={(e: any) => {
                                    changeEmail(
                                      state,
                                      formikProps.values.resetEmailOne,
                                      formikProps.values.resetEmailTwo,
                                      formikProps.values.resetEmailPassword,
                                      formikProps.setFieldValue,
                                    );
                                  }}
                                  style={{ float: "right" }}>
                                  Change Email
                                </Button>
                              </Col>
                              <Col>
                                <Button
                                  onClick={() => {
                                    formikProps.setFieldValue(
                                      "changeEmail",
                                      false,
                                    );
                                  }}
                                  style={{ float: "left" }}>
                                  Cancel
                                </Button>
                              </Col>
                            </Form.Group>
                          </>
                        ) : (
                          <>
                            <Row
                              style={{
                                textAlign: "center",
                                marginTop: "30px",
                              }}>
                              <Col>
                                <i>
                                  An email with a verification code has been
                                  sent to {formikProps.values.resetEmailOne},
                                  please paste the verification code here
                                </i>
                              </Col>
                            </Row>
                            <Form.Group
                              as={Row}
                              style={{
                                textAlign: "center",
                                paddingLeft: "20%",
                                marginTop: "30px",
                              }}>
                              <Form.Label column sm="3">
                                Enter Verification Code
                              </Form.Label>
                              <Col sm={5}>
                                <Text
                                  type="text"
                                  value={formikProps.values.verificationCode}
                                  name="verificationCode"
                                  readOnly={false}
                                  onUpdate={formikProps.handleChange}
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group
                              as={Row}
                              style={{
                                textAlign: "center",
                                marginBottom: "0px",
                              }}>
                              <Col>
                                <Button
                                  onClick={(e: any) => {
                                    changeEmailVerify(
                                      state,
                                      formikProps.values.resetEmailOne,
                                      formikProps.values.verificationCode,
                                      formikProps.setFieldValue,
                                    );
                                  }}>
                                  Confirm
                                </Button>
                              </Col>
                            </Form.Group>
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : null}

                {/* Submit buttons row */}
                <Form.Group as={Row}>
                  {pageStatus === "Editing" || pageStatus === "New" ? (
                    <>
                      <Col sm={{ span: "auto", offset: "2" }}>
                        <Button
                          type="submit"
                          onClick={() => {
                            if (JSON.stringify(formikProps.errors) !== "{}") {
                              const errors = JSON.stringify(formikProps.errors)
                                .replace("{", "")
                                .replace("}", "")
                                .split(",");
                              errors.map(
                                (e: string, index: number) =>
                                  (errors[index] = e.substring(
                                    e.lastIndexOf(":") + 1,
                                  )),
                              );
                              const message = `Failed to ${
                                pageStatus === "New" ? "create" : "update"
                              } user:\n\n${errors
                                .map((error: string) =>
                                  error
                                    .replace("[", "")
                                    .replace("]", "")
                                    .replace("}", "")
                                    .replace("{", ""),
                                )
                                .filter((error: any) => error !== "null")
                                .join("\n")}`;
                              displayToast({
                                status: "danger",
                                title: message,
                              });
                            }
                          }}>
                          Submit
                        </Button>
                      </Col>
                      <Col sm="auto">
                        <Button
                          type="button"
                          variant="light"
                          onClick={() => {
                            if (pageStatus === "Editing") {
                              formikProps.resetForm();
                              setStatus("Ready");
                            } else {
                              navigate("/settings/users");
                            }
                          }}>
                          Cancel
                        </Button>
                      </Col>
                    </>
                  ) : null}
                </Form.Group>
              </Form>
            </Card>
          )}
        />
      );
    } else {
      return <NotFoundScreen />;
    }
  }
  return (
    <Card title="User Details" collapsable={false}>
      <Loading />
    </Card>
  );
};

const DeleteButton = (props: any) => {
  type PageStatus = "Loading" | "Ready";
  const [pageStatus, setPageStatus] = useState<PageStatus>("Ready");

  const onClick = async () => {
    setPageStatus("Loading");
    await props.onClick();
    setPageStatus("Ready");
  };

  if (pageStatus === "Ready") {
    if (props.responsibilities.length === 0) {
      return (
        <DropdownButton
          id="dropdown-basic-button"
          variant="outline-dark"
          title="Delete">
          <Dropdown.Item onClick={onClick}>Confirm delete</Dropdown.Item>
        </DropdownButton>
      );
    }
    return (
      <OverlayTrigger
        placement="auto"
        overlay={
          <Tooltip id="tooltip-cannot-respond-closed">
            Cannot delete User if they are responsible for any records
          </Tooltip>
        }>
        <Button className="disabled" variant="outline-dark">
          Delete
        </Button>
      </OverlayTrigger>
    );
  }
  return (
    <div style={{ marginRight: "35px" }}>
      <Loading noBorder />
    </div>
  );
};

export default SingleUserSettings;
