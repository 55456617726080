import { useState } from "react";
import { Box, Button, Text } from "@chakra-ui/react";
import { EditorContent } from "@tiptap/react";
import { useEditorWithExtensions } from "../_chakra/manual/components/useEditorWithExtensions";

type Props = {
  contentJSON: string;
  preview: string;
};

export default function RequirementManualRichText({
  contentJSON,

  preview,
}: Props) {
  const readonlyEditor = useEditorWithExtensions({
    content: contentJSON.length >= 1 ? JSON.parse(contentJSON) : undefined,
    editable: false,
    placeholder: "No content",
    limit: 20000,
  });

  const [viewMore, setViewMore] = useState(false);

  if (preview.length < 255) {
    return <Box>{preview}</Box>;
  }
  return (
    <Box>
      {viewMore ? (
        <Text>
          <EditorContent editor={readonlyEditor} />
        </Text>
      ) : (
        <Text noOfLines={2}>
          <EditorContent editor={readonlyEditor} />
        </Text>
      )}
      <Button variant="link" onClick={() => setViewMore(!viewMore)}>
        {viewMore ? "view less" : "view more"}
      </Button>
    </Box>
  );
}
