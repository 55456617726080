import React from "react";
import {
  Col,
  Container, Dropdown, Form, OverlayTrigger, Row,
  Table, Tooltip
} from "react-bootstrap";
import * as Feather from "react-feather";
import { Link, useLocation } from "react-router-dom";
import { useAppState } from "../../components/App/AppProvider";
import { Textarea } from "../../components/Forms/Field/Textarea";
import { Card } from "../../components/UI/Card/Card";
import { Header } from "../../components/UI/Header/Header";
import { Loading } from "../../components/UI/Loading/Loading";
import { Paginator } from "../../components/UI/Paginator/Paginator";
import { LifeCycle } from "../../components/UI/Status/LifeCycle";
import {
  objectStatusToVariant, Status
} from "../../components/UI/Status/Status";
import * as ObjectTypeGroup from "../../constants/objectTypeGroup";
import { formatDateTime } from "../../utilities/formatDate";
import * as Request from "../../utilities/request";
import { text_truncate } from "../../utilities/strings";
import { getUrlSearchParam } from "../../utilities/url";
import useToast from "../../utilities/useToast";
import { P } from "@clerk/clerk-react/dist/controlComponents-CByvIpDK";

interface JobDashboardState {
  jobs?: any[];
  instances?: any[];
  error?: any;
  fetchingJobs?: boolean;
  fetchingInstances?: boolean;
}

interface CategoryFilter {
  name: string;
  id: string;
  selected: boolean;
}

interface JobFiltersState {
  importantJobsPage: number;
  jobActionsPage: number;
  pageSize: number;
  upcoming: boolean;
  due: boolean;
  overdue: boolean;
  categories: CategoryFilter[];
  selectedCategory: string;
}

const lifeCycleFilter = (filters: JobFiltersState, instance: any) =>
  instance.LifeCycle !== "Closed" &&
  ((filters.upcoming && instance.LifeCycle === "Upcoming") ||
    (filters.due && instance.LifeCycle === "Due") ||
    (filters.overdue && instance.LifeCycle === "Overdue"));
const categoryFilter = (filters: JobFiltersState, instance: any) => {
  let isIncluded = false;
  filters.categories.forEach((category: CategoryFilter) => {
    if (category.selected && instance.RequirementObjectTypeID === category.id) {
      isIncluded = true;
    }
  });
  return isIncluded;
};

const pageFilter = (page: number, pageSize: number, index: number) =>
  index < page * pageSize && index >= (page - 1) * pageSize;

type JobsDashboardStatus =
  | "Loading"
  | "Ready"
  | "Editing"
  | "Fetching"
  | "Error";

const ScreensJobDashboard = () => {
  return <p>Not found</p>
};

export { ScreensJobDashboard };
