import Bugsnag, { Client, User } from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { useEffect, useRef } from "react";

let client: Client | null = null;

export default function initLogging() {
  client = Bugsnag.start({
    apiKey: "afb9449f4e9dc4f83b32a254607fa020",
    plugins: [new BugsnagPluginReact()],
  });
}
type LoggingUserData = {
  accountID?: string;
  userID?: string;
  authUserID?: string;
  email?: string;
  username?: string;
};

export function updateLoggingUser(data: LoggingUserData) {
  if (!client) {
    return;
  }
  let { accountID, userID, email, username, authUserID } = data;
  userID = userID || "UNKNOWN_USER_ID";
  accountID = accountID || "UNKNOWN_ACC_ID";
  email = email || "UNKNOWN_EMAIL";
  username = username || "UNKNOWN_NAME";
  authUserID = authUserID || "UNKNOWN_AUTH_USER_ID";
  client.setUser(userID, email, username);
  client.addMetadata("user", {
    accountID,
    authUserID,
  });
}
