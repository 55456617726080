import React from "react";
import { Col } from "react-bootstrap";
import {
  Route,
  Routes,
  useLocation
} from "react-router-dom";

// Mantine styles
import "@mantine/core/styles.css";
import "@mantine/dropzone/styles.css";

import "./App.css";
import {
  ActiveAppContextType,
  ActiveAppProvider,
  ActiveAppState,
  AppState,
  CoreAppInfo
} from "./components/App/AppProvider";
import Redirect from "./components/common/Redirect";
import BlueprintRoutes from "./screens/_mantine/blueprints/BlueprintRoutes";
import AccountDisabledScreen from "./screens/Home/HomeAccountDisabled";
import HomeLoading from "./screens/Home/HomeLoading";
import InviteScreen from "./screens/Login/InviteScreen";
import { ScreensPrintReport } from "./screens/Report/PrintReportPage";
import * as UserAccountStatusID from "./shared/v2/constants/UserAccountStatusID";
import { ProtectedMainRoutes } from "./ProtectedMainRoutes";

type ProtectedOuterRoutesProps = {
  baseAppState: ActiveAppState;
  setBaseAppState: React.Dispatch<React.SetStateAction<AppState>>;
};

/**
 * This component is responsible for rendering the main routes of the application
 * It uses MainRoutes to render most routes that use the standard layout, and renders routes that dont use the standard layout directly
 * (Standard layout inc sidebar, ect)
 */
export function ProtectedOuterRoutes ({
  baseAppState,
  setBaseAppState,
}: ProtectedOuterRoutesProps) {
  const location = useLocation();


  const { accountInfo } = baseAppState.app;

  // // If user doesnt have an account or if its inactive or if theyve been removed from the account
  // if (
  //   accountInfo === null ||
  //   accountInfo.userAccountStatusID === UserAccountStatusID.Inactive ||
  //   !accountInfo.active
  // ) {
  //   return (
  //     <Col className="main-pane">
  //       <AccountDisabledScreen userAccounts={baseAppState.app.userAccounts} />
  //     </Col>
  //   );
  // }

  // // If the user is pending, show them the invite screen
  // if (accountInfo.userAccountStatusID === UserAccountStatusID.Pending) {
  //   return (
  //     <Col className="main-pane">
  //       <InviteScreen code={accountInfo.userAccountID} />
  //     </Col>
  //   );
  // }

  const appInfo: CoreAppInfo = {
    ...baseAppState.app,
    accountInfo,
  };

  const appCtx: ActiveAppContextType = {
    appState: {
      ...baseAppState,
      app: appInfo,
      type: "active",
    },
    setAppState: setBaseAppState,
    setPageTitle: (title: string) =>
      setBaseAppState((prev) => ({ ...prev, currentPageTitle: title })),
  };
  return (
    <ActiveAppProvider activeAppContext={appCtx}>
      <Routes>
        <Route path="blueprint/*" element={<BlueprintRoutes />} />
        <Route path="print/report/:reportID" element={<ScreensPrintReport />} />
        <Route path="/login/*" element={<Redirect to="/" />} />
        <Route path="*" element={<ProtectedMainRoutes />} />
      </Routes>
    </ActiveAppProvider>
  );
};
