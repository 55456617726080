import { Formik } from "formik";
import moment from "moment";
import React, { useState } from "react";
import {
  Button,
  Col, Collapse, Dropdown, Form, OverlayTrigger, Row, Tooltip
} from "react-bootstrap";
import { Plus, Trash2 } from "react-feather";
import { useAppState } from "../../components/App/AppProvider";
import {
  getOrdinal, mySqlDayOfWeekEquivilant, renderMonthDropdown, renderNotificationMedium, renderNotificationRecurrence
} from "../../components/App/notificationSettings";
import { DateField } from "../../components/Forms/Field/Date";
import { Card } from "../../components/UI/Card/Card";
import { CollapseChevron } from "../../components/UI/CollapseChevron/CollapseChevron";
import { Loading } from "../../components/UI/Loading/Loading";
import * as RecordStatus from "../../shared/constants/recordStatus";
import { useLegacyApi } from "../../utilities/request";
import useToast from "../../utilities/useToast";
import { ScheduleInterface } from "./Report.d";

const Schedule: ScheduleInterface = {
  NotificationID: "",
  CreateUserID: "",
  ModifiedUserID: "",
  CreateTs: "",
  ModifiedTs: "",
  NotificationSendOn: "",
  NotificationMediumID: "",
  NotificationMediumName: "",
  ActionRecurrenceID: "",
  ActionRecurrenceText: "",
  RecordStatusID: "",
  NotificationOnceOff: "",
  NotificationSendEveryTime: false,
};

const addSchedule = (data: any, setData: any) => {
  const newScheduleData = data.ScheduleSettings;
  newScheduleData.push({
    ...Schedule,
    NotificationID: data.ScheduleSettings.length + 1,
    NotificationMediumName: "Email",
    NotificationMediumID: "5f00e51f-37e2-4e6c-b892-9fa92990c759",
  }); // remove the last two when more mediums are available
  setData({ ...data, ScheduleSettings: newScheduleData });
};

const checkFrequencySelection = (schedules: any) => {
  let check = true;
  schedules.forEach((s: any) => {
    if (s.ActionRecurrenceText === "") {
      check = false;
    }
  });
  return check;
};

const ScheduleReport = (props: any) => {
  const { auth, app } = useAppState();
  const { post } = useLegacyApi();
  const [state, setState] = React.useState<any>(null);
  const [pageStatus, setStatus] = React.useState<any>("Loading");
  const { displayToast } = useToast();
  // collapse
  const [isCollapsed, updateCollapsed] = useState<boolean>(props.collapsed);
  React.useEffect(() => {
    const getState = async () => {
      try {
        if (props.data.ScheduleSettings.length > 0) {
          // Hardcoded "Email" until we have more than one medium available
          const newState = props.data.ScheduleSettings.map((schedule: any) => ({
            ...schedule,
            NotificationMediumName: "Email",
            NotificationMediumID: "5f00e51f-37e2-4e6c-b892-9fa92990c759",
          }));
          setState({ ...props.data, ScheduleSettings: newState });
        } else setState(props.data);
        // updateCollapsed(props.collapsed)
        setStatus("Ready");
      } catch (err) {
        displayToast({
          status: "error",
          title: "Failed to retrieve User details",
        });
      }
    };

    if (auth.isLoggedIn) {
      getState();
    }
  }, [props.match]);

  if (pageStatus !== "Loading") {
    return (
      <Formik
        initialValues={state}
        onSubmit={async (values, actions) => {
          if (!checkFrequencySelection(values.ScheduleSettings)) {
            actions.setSubmitting(false);
          } else {
            try {
              setStatus("Submitting");
              const newState = values.ScheduleSettings.filter(
                (s: any) => s.RecordStatusID === RecordStatus.Active,
              );
              setState({ ...state, ScheduleSettings: newState });
              const result = await post(
                `report/${state.ReportID}/schedule`,
                values,
              
              );
              if (result.status === 200) {
                displayToast({
                  status: "success",
                  title: "Updated schedule successfully",
                });
              }
              setStatus("Ready");
              window.location.reload();
            } catch (err) {
              setStatus("Editing");
              actions.setSubmitting(false);
              displayToast({
                status: "error",
                title: "Unable to update schedule",
              });
            }
          }
        }}
        render={(formikProps) => (
          <section className="card">
            <Row>
              <Col sm="auto">
                <h1>Schedule</h1>
              </Col>
              <Col>
                <span />
              </Col>
              <Col sm="auto">
                {pageStatus === "Ready" ? (
                  formikProps.values.ScheduleSettings.length === 0 ? ( // this condition is to not allow for another schedule to be set if one is already there, remove when you want more than one
                    app.attributes.userID === state.ReportResponsibleUserID ? (
                      <Button
                        onClick={() => {
                          setStatus("Editing");
                          addSchedule(
                            formikProps.values,
                            formikProps.setValues,
                          );
                        }}>
                        <Plus className="feather" />
                        Add New Schedule
                      </Button>
                    ) : (
                      <OverlayTrigger
                        placement="auto"
                        overlay={
                          <Tooltip id="not-authorised-to-edit">
                            You are not authorised to add a new schedule
                          </Tooltip>
                        }>
                        <Button className="disabled">
                          <Plus className="feather" />
                          Add New Schedule
                        </Button>
                      </OverlayTrigger>
                    )
                  ) : null
                ) : null}
              </Col>
              <Col sm="auto">
                <CollapseChevron
                  collapsed={isCollapsed}
                  updateCollapsed={updateCollapsed}
                />
              </Col>
            </Row>
            <Collapse in={!isCollapsed}>
              <Row>
                <Col>
                  <hr />
                  <Form onSubmit={formikProps.handleSubmit}>
                    {formikProps.values.ScheduleSettings.length > 0 ? (
                      formikProps.values.ScheduleSettings.map(
                        (schedule: any, index: number) => (
                          <React.Fragment key={index}>
                            <Form.Group as={Row} className="largeText">
                              <Col sm="7">
                                <>
                                  {schedule.RecordStatusID !==
                                    RecordStatus.Deleted &&
                                  pageStatus === "Editing" ? (
                                    <Form.Label column sm="2">
                                      Send on a
                                    </Form.Label>
                                  ) : null}
                                  {pageStatus === "Editing" ? (
                                    <Dropdown
                                      onSelect={(e: any) => {
                                        e = JSON.parse(e);
                                        const schedules =
                                          formikProps.values.ScheduleSettings.map(
                                            (s: any, i: number) => {
                                              if (i === index) {
                                                const newSchedule = {
                                                  ...s,
                                                  ActionRecurrenceText: e.key,
                                                  ActionRecurrenceID: e.value,
                                                  NotificationOnceOff:
                                                    e.key !== "Once Off"
                                                      ? null
                                                      : "",
                                                  RecordStatusID:
                                                    e.key === "No Schedule"
                                                      ? RecordStatus.Deleted
                                                      : RecordStatus.Active,
                                                  NotificationSendOn:
                                                    e.key === "Weekly"
                                                      ? "2"
                                                      : e.key === "Monthly" ||
                                                        e.key === "Quarterly"
                                                      ? "1"
                                                      : "",
                                                };
                                                return newSchedule;
                                              }
                                              return s;
                                            },
                                          );
                                        formikProps.setValues({
                                          ...formikProps.values,
                                          ScheduleSettings: schedules,
                                        });
                                      }}
                                      className="inlineDropdown">
                                      <Dropdown.Toggle
                                        className="darkText"
                                        variant="outline-dark"
                                        id="dropdown-statusFilter">
                                        {schedule.ActionRecurrenceText
                                          ? ` ${schedule.ActionRecurrenceText}`
                                          : "Set"}{" "}
                                        Recurrence
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          eventKey={JSON.stringify({
                                            value: null,
                                            key: "No Notification",
                                          })}>
                                          No Schedule
                                        </Dropdown.Item>
                                        {renderNotificationRecurrence(
                                          formikProps.values
                                            .NotificationOptions,
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  ) : schedule.RecordStatusID ===
                                    RecordStatus.Active ? (
                                    `Reports will be sent ${schedule.ActionRecurrenceText}`
                                  ) : (
                                    `No schedules set up`
                                  )}
                                </>
                                {schedule.RecordStatusID ===
                                  RecordStatus.Active &&
                                schedule.ActionRecurrenceText === "Weekly" ? (
                                  pageStatus === "Editing" ? (
                                    <>
                                      <span>on</span>
                                      <Dropdown
                                        onSelect={(e: any) => {
                                          e = JSON.parse(e);
                                          const schedules =
                                            formikProps.values.ScheduleSettings.map(
                                              (s: any, i: number) => {
                                                if (i === index) {
                                                  const newSchedule = {
                                                    ...s,
                                                    NotificationSendOn: e.value,
                                                  };
                                                  return newSchedule;
                                                }
                                                return s;
                                              },
                                            );
                                          formikProps.setValues({
                                            ...formikProps.values,
                                            ScheduleSettings: schedules,
                                          });
                                        }}
                                        className="inlineDropdown">
                                        <Dropdown.Toggle
                                          className="darkText"
                                          variant="outline-dark"
                                          id="dropdown-statusFilter">
                                          {schedule.NotificationSendOn
                                            ? mySqlDayOfWeekEquivilant(
                                                schedule.NotificationSendOn,
                                              )
                                            : "Select day of week"}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            eventKey={JSON.stringify({
                                              value: "2",
                                              key: "Monday",
                                            })}>
                                            Monday
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            eventKey={JSON.stringify({
                                              value: "3",
                                              key: "Tuesday",
                                            })}>
                                            Tuesday
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            eventKey={JSON.stringify({
                                              value: "4",
                                              key: "Wednesday",
                                            })}>
                                            Wednesday
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            eventKey={JSON.stringify({
                                              value: "5",
                                              key: "Thursday",
                                            })}>
                                            Thursday
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            eventKey={JSON.stringify({
                                              value: "6",
                                              key: "Friday",
                                            })}>
                                            Friday
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            eventKey={JSON.stringify({
                                              value: "7",
                                              key: "Saturday",
                                            })}>
                                            Saturday
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            eventKey={JSON.stringify({
                                              value: "1",
                                              key: "Sunday",
                                            })}>
                                            Sunday
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </>
                                  ) : schedule.RecordStatusID ===
                                    RecordStatus.Active ? (
                                    ` on ${mySqlDayOfWeekEquivilant(
                                      schedule.NotificationSendOn,
                                    )}`
                                  ) : null
                                ) : null}
                                {schedule.RecordStatusID ===
                                  RecordStatus.Active &&
                                schedule.ActionRecurrenceText === "Monthly" ? (
                                  pageStatus === "Editing" ? (
                                    <>
                                      <span>on the</span>
                                      <Dropdown
                                        onSelect={(e: any) => {
                                          e = JSON.parse(e);
                                          const schedules =
                                            formikProps.values.ScheduleSettings.map(
                                              (s: any, i: number) => {
                                                if (i === index) {
                                                  const newSchedule = {
                                                    ...s,
                                                    NotificationSendOn: e.value,
                                                  };
                                                  return newSchedule;
                                                }
                                                return s;
                                              },
                                            );
                                          formikProps.setValues({
                                            ...formikProps.values,
                                            ScheduleSettings: schedules,
                                          });
                                        }}
                                        className="inlineDropdown">
                                        <Dropdown.Toggle
                                          className="darkText"
                                          variant="outline-dark"
                                          id="dropdown-statusFilter">
                                          {getOrdinal(
                                            schedule.NotificationSendOn,
                                          )}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          {renderMonthDropdown()}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </>
                                  ) : schedule.RecordStatusID ===
                                    RecordStatus.Active ? (
                                    ` on the ${getOrdinal(
                                      schedule.NotificationSendOn,
                                    )}`
                                  ) : null
                                ) : null}
                                {schedule.ActionRecurrenceText ===
                                "Once Off" ? (
                                  pageStatus === "Editing" ? (
                                    <>
                                      <span>on</span>
                                      <div className="inlineDropdown">
                                        <DateField
                                          name="NotificationOnceOff"
                                          selected={
                                            schedule.NotificationOnceOff
                                          }
                                          allDates
                                          authState={auth}
                                          onUpdate={(e: any) => {
                                            const schedules =
                                              formikProps.values.ScheduleSettings.map(
                                                (s: any, i: number) => {
                                                  if (i === index) {
                                                    const newSchedule = {
                                                      ...s,
                                                      NotificationOnceOff: e,
                                                    };
                                                    return newSchedule;
                                                  }
                                                  return s;
                                                },
                                              );
                                            formikProps.setValues({
                                              ...formikProps.values,
                                              ScheduleSettings: schedules,
                                            });
                                          }}
                                          className="form-control dropdown-toggle btn btn-outline-dark darkText"
                                        />
                                      </div>
                                    </>
                                  ) : schedule.RecordStatusID ===
                                    RecordStatus.Active ? (
                                    ` on ${moment
                                      .utc(schedule.NotificationOnceOff)
                                      .local()
                                      .format("DD-MMM-YYYY")}`
                                  ) : null
                                ) : null}
                                {/* Remove the false &&, and the hardcoded setting of NotificationMediumName / NotificationMediumID when we have more than one medium */}
                                {false && pageStatus === "Editing" ? (
                                  <>
                                    <span>via</span>
                                    <Dropdown
                                      onSelect={(e: any) => {
                                        e = JSON.parse(e);
                                        const schedules =
                                          formikProps.values.ScheduleSettings.map(
                                            (s: any, i: number) => {
                                              if (i === index) {
                                                const newSchedule = {
                                                  ...s,
                                                  NotificationMediumName: e.key,
                                                  NotificationMediumID: e.value,
                                                };
                                                return newSchedule;
                                              }
                                              return s;
                                            },
                                          );
                                        formikProps.setValues({
                                          ...formikProps.values,
                                          ScheduleSettings: schedules,
                                        });
                                      }}
                                      className="inlineDropdown">
                                      <Dropdown.Toggle
                                        className="darkText"
                                        variant="outline-dark"
                                        id="dropdown-statusFilter">
                                        {schedule.NotificationMediumName
                                          ? schedule.NotificationMediumName
                                          : "Set"}{" "}
                                        Medium
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        {renderNotificationMedium(
                                          formikProps.values
                                            .NotificationOptions,
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </>
                                ) : schedule.RecordStatusID ===
                                  RecordStatus.Active ? (
                                  ` via ${schedule.NotificationMediumName}`
                                ) : null}
                              </Col>
                              <Col sm="4">
                                {pageStatus === "Editing" ? (
                                  <Form.Check
                                    id="notificationEveryTime"
                                    type="checkbox"
                                    defaultChecked={
                                      schedule.NotificationSendEveryTime
                                    }
                                    onChange={(event: any) => {
                                      const schedules =
                                        formikProps.values.ScheduleSettings.map(
                                          (s: any, i: number) => {
                                            if (i === index) {
                                              const newSchedule = {
                                                ...s,
                                                NotificationSendEveryTime:
                                                  event.target.checked,
                                              };
                                              return newSchedule;
                                            }
                                            return s;
                                          },
                                        );
                                      formikProps.setValues({
                                        ...formikProps.values,
                                        ScheduleSettings: schedules,
                                      });
                                    }}
                                    label="Send even if report has no records"
                                  />
                                ) : schedule.NotificationSendEveryTime &&
                                  schedule.RecordStatusID ===
                                    RecordStatus.Active ? (
                                  "Send even if report has no records"
                                ) : (
                                  "Do not send if report has no records"
                                )}
                              </Col>
                              <Col sm="1">
                                <button
                                  style={{
                                    backgroundColor: "white",
                                    border: "none",
                                    marginBottom: "10px",
                                    marginRight: "10px",
                                  }}
                                  onClick={() => {
                                    const schedules =
                                      formikProps.values.ScheduleSettings.map(
                                        (s: any, i: number) => {
                                          if (i === index) {
                                            const newSchedule = {
                                              ...s,
                                              RecordStatusID:
                                                RecordStatus.Deleted,
                                            };
                                            return newSchedule;
                                          }
                                          return s;
                                        },
                                      );
                                    formikProps.setValues({
                                      ...formikProps.values,
                                      ScheduleSettings: schedules,
                                    });
                                  }}>
                                  <Trash2
                                    style={{ color: "#d8d8d8" }}
                                    className="feather"
                                  />
                                </button>
                              </Col>
                            </Form.Group>
                            {pageStatus === "Editing" &&
                            schedule.ActionRecurrenceText === "Quarterly" ? (
                              <Form.Group
                                as={Row}
                                style={{
                                  paddingLeft: "3%",
                                  marginTop: "-20px",
                                }}>
                                <Form.Label
                                  style={{
                                    color: "#5f7582",
                                    fontStyle: "italic",
                                    fontSize: 14,
                                  }}>
                                  *Sends the report on the 1st of next month,
                                  then every 3 months
                                </Form.Label>
                              </Form.Group>
                            ) : null}
                          </React.Fragment>
                        ),
                      )
                    ) : (
                      <span>No schedules set up</span>
                    )}
                    {/* Submit buttons row */}
                    <Form.Group as={Row}>
                      {pageStatus === "Editing" ? (
                        <>
                          <Col sm={{ span: "auto", offset: "2" }}>
                            <Button
                              type="submit"
                              onClick={() => {
                                const extraErrors = [];
                                if (
                                  !checkFrequencySelection(
                                    formikProps.values.ScheduleSettings,
                                  )
                                ) {
                                  extraErrors.push(
                                    '"Recurrence selection is required."',
                                  );
                                }

                                if (extraErrors.length !== 0) {
                                  const title = `Failed to add new schedule`;
                                  const description = extraErrors
                                    .map((error: string) => error)
                                    .join("\n");
                                  displayToast({
                                    status: "error",
                                    title,
                                    description,
                                  });
                                }
                              }}>
                              Submit
                            </Button>
                          </Col>
                          <Col sm="auto">
                            <Button
                              type="button"
                              variant="light"
                              onClick={() => {
                                if (pageStatus === "Editing") {
                                  formikProps.resetForm();
                                  state.ScheduleSettings.pop(); // remove the newly added schedule which you canceled on
                                  setState({ ...state });
                                  setStatus("Ready");
                                }
                              }}>
                              Cancel
                            </Button>
                          </Col>
                        </>
                      ) : null}
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
            </Collapse>
          </section>
        )}
      />
    );
  }
  return (
    <Card title="Schedule" collapsable={false}>
      <Loading />
    </Card>
  );
};

export { ScheduleReport };
