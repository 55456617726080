import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
// eslint-disable-next-line
import { useLocation, useParams } from "react-router-dom";
import { useAppState } from "../../components/App/AppProvider";
import * as CoreFields from "../../shared/constants/coreFields";
import { GeneratedReportRow } from "../../shared/definitions/reports";
import { RequestStatus, get, getPaginated } from "../../utilities/request";
import useToast from "../../utilities/useToast";
import HomeLoading from "../Home/HomeLoading";
import {
  combineReportResults,
  displayFilters,
  formatColumnFieldsFromDB,
  formatTableDataCell,
  groupReport,
  sortReport,
} from "./Common";
import { ReportPageData, ReportPageStatus } from "./Report.d";
import { useAuth } from "../../utilities/useAuth";

// for old reports before ID was made mandatory, arrange so ID is first for print display
const arrangeData = (data: any, objectTypeName: string) => {
  const coreFieldKey = objectTypeName as keyof typeof CoreFields;

  if (
    data.tables.length > 0 &&
    data.tables[0].headerRows[0].columns[0].id !== CoreFields[coreFieldKey].ID
  ) {
    data.tables.forEach((table: any) => {
      // move ID header
      const headerRowIndex = table.headerRows[0].columns.findIndex(
        (row: any) => row.id === CoreFields[coreFieldKey].ID,
      );
      if (headerRowIndex >= 0) {
        table.headerRows[0].columns.splice(
          0,
          0,
          table.headerRows[0].columns[headerRowIndex],
        );
        table.headerRows[0].columns.splice(headerRowIndex + 1, 1);
      }

      // move ID body
      table.bodyRows.forEach((row: any) => {
        const bodyRowIndex = row.columns.findIndex(
          (row: any) => row.fieldID === CoreFields[coreFieldKey].ID,
        );
        if (bodyRowIndex >= 0) {
          row.columns.splice(0, 0, row.columns[bodyRowIndex]);
          row.columns.splice(bodyRowIndex + 1, 1);
        }
      });
    });
  }
};

const ScreensPrintReport = () => {
  const params = useParams();
  const { getToken } = useAuth();

  const location = useLocation();
  const { auth } = useAppState();
  const { displayToast } = useToast();
  const [data, setData] = useState<ReportPageData>(null);
  const [generatedData, setGeneratedData] = useState<any>(null);
  const [pageStatus, setPageStatus] = useState<ReportPageStatus>("Loading");

  const reportID = params.reportID || "";

  useEffect(() => {
    const fetchReport = async () => {
      setPageStatus("Loading");
      const resultData = await get(`report/${reportID}${location.search}`, getToken);
      formatColumnFieldsFromDB(resultData.data);
      if (resultData.status !== 200 || !resultData.data) {
        displayToast({
          status: "error",
          title: "Failed to retrieve Report",
        });
      } else if (resultData.data.ReportID === "NotExist") {
        // display error
        setPageStatus("Error");
      } else {
        let interimResults: GeneratedReportRow[] = [];
        const updateGeneratedResults = (
          responseData: any,
          status: RequestStatus,
        ) => {
          if (status !== "Error") {
            interimResults = combineReportResults(
              interimResults,
              responseData.reportRows,
            );
            const grouped = groupReport(
              interimResults,
              resultData.data.Columns,
              resultData.data.GroupByID,
            );
            const sortedResults = sortReport(grouped, resultData.data.Columns);
            setGeneratedData({
              results: sortedResults,
              timestamp: generatedData
                ? generatedData.timestamp
                : responseData.timestamp,
            });
          } else {
            displayToast({
              status: "error",
              title: "Failed to generate Report",
            });
          }
          if (status === "Ready") {
            setTimeout(() => {
              window.print();
            }, 250);
          }
          setPageStatus(status);
        };
        setData(resultData.data);

        try {
          await getPaginated(
            `report/${reportID}/generate`,
            2000,
            updateGeneratedResults,
            getToken
          );
        } catch (err) {
          console.log("err", err);
          displayToast({
            status: "error",
            title: "Failed to generate Report",
          });
        }
        setPageStatus(reportID === "new" ? "New" : "Ready");
      }
    };

    if (auth.isLoggedIn) {
      fetchReport();
    }
  }, [reportID]); // eslint-disable-line

  return pageStatus === "Ready" ? (
    <div id="print_report">
      {data && generatedData ? (
        <section className="card" id="print_report">
          <Row>
            <Col sm="auto">
              <h1>{data.ReportName}</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              {data.ObjectTypeName}s Report:
              {displayFilters(
                data.Filters,
                data.Columns,
                data.CustomFields.concat(data.CoreFields),
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <hr />
              {generatedData.results.tables.length > 0 ? (
                generatedData.results.tables.map(
                  (table: any, tableIndex: number) => (
                    <React.Fragment key={`table-${tableIndex}`}>
                      {table.heading && table.heading !== "" ? (
                        <h1>{table.heading}</h1>
                      ) : null}
                      <Table
                        className="report-table table-print"
                        bordered
                        striped
                        responsive
                        key={table.groupValue}
                        title={table.heading}>
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Field</th>
                            <th>Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          {table.bodyRows.map((row: any, index: number) => (
                            <React.Fragment key={`record-${index}`}>
                              {row.columns.map((col: any, i: number) =>
                                i < row.columns.length - 1 ||
                                row.columns.length === 1 ? (
                                  <tr key={`row-${i}`}>
                                    {i === 0 ? (
                                      <td
                                        rowSpan={
                                          table.headerRows[0].columns.length > 1
                                            ? table.headerRows[0].columns
                                                .length - 1
                                            : 1
                                        }>
                                        <a
                                          href={row.columns[0].value.link}
                                          target="_blank"
                                          rel="noreferrer">
                                          {row.columns[0].value.text}
                                        </a>
                                      </td>
                                    ) : (
                                      ""
                                    )}
                                    {row.columns.length > 1 ? (
                                      <>
                                        <td>
                                          {
                                            table.headerRows[0].columns[i + 1]
                                              .text
                                          }
                                        </td>
                                        {formatTableDataCell(
                                          row.columns[i + 1],
                                          {
                                            colSpan: row.columns[i + 1].span,
                                            key: row.columns[i + 1].id,
                                            className: "wrap-text",
                                          },
                                          "print",
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <td />
                                        <td />
                                      </>
                                    )}
                                  </tr>
                                ) : (
                                  ""
                                ),
                              )}
                            </React.Fragment>
                          ))}
                        </tbody>
                      </Table>
                    </React.Fragment>
                  ),
                )
              ) : (
                <Row>
                  <Col>No records returned.</Col>
                </Row>
              )}
            </Col>
          </Row>
        </section>
      ) : null}
    </div>
  ) : (
    <HomeLoading info="Preparing report..." />
  );
};

export { ScreensPrintReport };
