import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { apiConfig } from "../constants/config";
import { useAuth } from "./useAuth";

export type RequestStatus = "Fetching" | "Ready" | "Error";

const setConfig = (apiToken: string): AxiosRequestConfig => ({
  headers: {
    Authorization: `Bearer ${apiToken}`,
  },
});

const getUrl = (endpoint: string): string =>
  `${apiConfig.controllerBaseUrl}/${endpoint}`;

// central function for handling pagination throughout the site
// pass in a function to handle receiving data however your page wants to handle it
export const getPaginated = async (
  endpoint: string,
  limit: number,
  updatePage: (data: any, pageStatus: RequestStatus) => void,
  apiToken: (() => Promise<string | null>) | string | null,
): Promise<AxiosResponse<any>> => {
  let offset = 0;
  let finished = false;
  let status: RequestStatus = "Fetching";
  const data: any[] = [];
  let result: any;

  while (!finished) {
    result = await get(
      `${endpoint}${
        endpoint.endsWith("&") ? "" : "?"
      }limit=${limit}&offset=${offset}`,
      apiToken,
    );

    if (!result.data || result.status !== 200) {
      status = "Error";
      finished = true;
    } else {
      offset += limit;

      if (result.data.resultsLength < limit) {
        status = "Ready";
        finished = true;
      }
    }
    updatePage(result.data, status);
  }
  return {
    ...result,
    data,
  };
};

export const get = async (
  endpoint: string,
  apiToken: (() => Promise<string | null>) | string | null,
): Promise<AxiosResponse<any>> => {
  const token =
    (typeof apiToken === "function" ? await apiToken() : apiToken) ?? null;
  if (!token) {
    console.warn(`No token provided for ${endpoint}`);
  }
  return new Promise<AxiosResponse<any>>((resolve, reject) => {
    axios
      .get(getUrl(endpoint), token ? setConfig(token) : undefined)
      .catch((error: any) => {
        if (error.response && error.response.status === 401) {
          // window.location.reload();
        }
        reject(error);
      })
      .then((value: AxiosResponse<any> | void) => {
        resolve(value || undefined);
      });
  });
};

export const post = async (
  endpoint: string,
  body: any,
  apiToken: (() => Promise<string | null>) | string | null,
) => {
  const token =
    (typeof apiToken === "function" ? await apiToken() : apiToken) ?? null;
  return axios.post(getUrl(endpoint), body, setConfig(token || ""));
};
export const put = async (
  endpoint: string,
  body: any,
  apiToken: (() => Promise<string | null>) | string | null,
) => {
  const token =
    (typeof apiToken === "function" ? await apiToken() : apiToken) ?? null;
  return axios.put(getUrl(endpoint), body, setConfig(token || ""));
};
export const del = async (
  endpoint: string,
  apiToken: (() => Promise<string | null>) | string | null,
) => {
  const token =
    (typeof apiToken === "function" ? await apiToken() : apiToken) ?? null;
  return axios.delete(getUrl(endpoint), setConfig(token || ""));
};

export function useLegacyApi() {
  const { getToken } = useAuth();
  return {
    get: async (endpoint: string) => get(endpoint, getToken),
    post: async (endpoint: string, body: any) => post(endpoint, body, getToken),
    put: async (endpoint: string, body: any) => put(endpoint, body, getToken),
    del: async (endpoint: string) => del(endpoint, getToken),
  };
}
