import React, { SetStateAction } from "react";
import { AccountPermissionCache } from "../../shared/definitions/access";
import { PermissionObject } from "../../shared/v2/definitions/permissions";
import { Account, UserAccount } from "../../shared/v2/definitions/accounts";
import { SimpleUserInfo, UserDetails } from "../../shared/v2/definitions/user";

/**
 * The user can be in a few different 'states'
 * loggedout - The user is logged out
 * preparing - The user is logged in but we need to fetch app state
 * active - The user is logged in and we have fetched app state
 * limbo - The user is logged in, and we have fetched app state, but they arent currently in a valid System (or have no valid systems)
 */

/**
 * Core info for users in active state
 */
export type CoreAppInfo = {
  userAccounts: UserAccount[];
  userInfo: SimpleUserInfo;
  accountInfo: Account;
  permissions_LEGACY: AccountPermissionCache;
  permissions: PermissionObject;
  features: {
    enabled: string[];
  };
  attributes: Record<string, string>
};

/**
 * Variant of CoreAppInfo for limbo state
 */
export type LimboCoreAppInfo = Omit<CoreAppInfo, "accountInfo"> & {
  accountInfo: Account | null;
};

export type AuthState = {
  clerkUserID: string;
  apiToken: string;
  isLoggedIn: true;
};

/**
 * App states
 */

/**
 * Initial state
 */
type InitialAppState = {
  type: "initial";
  currentPageTitle: string;
}

/**
 * Account or user has failed to load
 */
type FailedState = {
  type: "failed";
  currentPageTitle: string;
  attemptedAuthID: string | null;
}

// User is logged in and we have their clerk user, and we are currently fetching the app state
type PreparingAppState = {
  type: "preparing";
  auth: AuthState;
  currentPageTitle: string;
};

// Use is all ready to go
// This is the only version of app state that should be in context
export type ActiveAppState = {
  type: "active";
  auth: AuthState;
  app: CoreAppInfo;
  currentPageTitle: string;
};

// User has no valid systems
type LimboAppState = {
  type: "limbo";
  auth: AuthState;
  app: LimboCoreAppInfo;
  currentPageTitle: string;
};


export type AppState = InitialAppState | FailedState | PreparingAppState | ActiveAppState | LimboAppState;

export const createInitialAppState = (): AppState => {
  return {
    type: "initial",
    currentPageTitle: "Home",
  
  }
  // if (window.localStorage.getItem("authState")) {
  //   const currentState = JSON.parse(window.localStorage.getItem("authState")!);
  //   if (currentState.authState === "signedIn") {
  //     return {
  //       type: "preparing",
  //       auth: {
  //         apiToken: currentState.auth.apiToken,
  //         isLoggedIn: true,
  //       },
  //       currentPageTitle: "Home",
  //     };
  //   }
  // }
  // return {
  //   type: "loggedout",
  //   authState: {
  //     isLoggedIn: false,
  //   },
  //   currentPageTitle: "Home",
  // };
};

export type ActiveAppContextType = {
  appState: ActiveAppState;
  setAppState: React.Dispatch<SetStateAction<AppState>>;
  setPageTitle: (title: string) => void;
};

const ActiveAppContext = React.createContext<ActiveAppContextType>(null!);

type Props = {
  children: React.ReactNode;
  activeAppContext: ActiveAppContextType;
};

export const ActiveAppProvider = ({ children, activeAppContext }: Props) => (
  <ActiveAppContext.Provider value={activeAppContext}>
    {children}
  </ActiveAppContext.Provider>
);

export const useActiveAppState = () => React.useContext(ActiveAppContext);

export const useAppState = () => React.useContext(ActiveAppContext).appState;

export const useAuthState = () =>
  React.useContext(ActiveAppContext).appState.auth;
