import {
  Button,
  chakra,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Text,
  Link,
  HStack,
  Input,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UnorderedList,
  UseDisclosureReturn,
  VStack,
  Skeleton,
  Box,
  Center,
  PinInput,
  PinInputField,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import ShortTextField from "../../../../components/chakra/form/formComponents/ShortTextField";
import useToast from "../../../../utilities/useToast";
import { useAuth, useAuthTOTP } from "../../../../utilities/useAuth";
import { useClerk, useUser } from "@clerk/clerk-react";
import CheckboxField from "../../../../components/chakra/form/formComponents/CheckboxField";
import { isClerkAPIResponseError } from "@clerk/clerk-js";
import { useEffect, useState } from "react";
import QRCode from "qrcode.react";

type Props = {
  modalState: UseDisclosureReturn;
  onRefresh: () => void;
};

const schema = z
  .object({
    newPassword: z
      .string()
      .min(8, "New password must be at least 8 characters"),
    confirmPassword: z.string(),
    currentPassword: z.string(),
    signOutOfOtherSessions: z.boolean(),
  })
  .superRefine(({ newPassword, confirmPassword }, ctx) => {
    if (newPassword !== confirmPassword) {
      ctx.addIssue({
        message: `Passwords must match`,
        code: "custom",
        path: ["confirmPassword"],
      });
    }
  });

type State =
  | { step: "closed" }
  | {
      step: "preparing";
    }
  | {
      step: "verifying";
      secret: string;
      uri: string;
      showCode: boolean;
      verificationCode: string;
      isBusy?: boolean;
    }
  | {
      step: "complete";
    };

export default function SetupMFAModal({ modalState, onRefresh }: Props) {
  const { displayToast } = useToast();
  const totpAuth = useAuthTOTP();

  const [state, setState] = useState<State>({ step: "closed" });

  useEffect(() => {
    const fetchMfa = async () => {
      console.log("Preparing totp");
      if (!totpAuth) return;
      const totp = await totpAuth.createTOTP();
      const { secret, uri } = totp;
      if (!secret || !uri) {
        return;
      }
      setState({
        step: "verifying",
        secret,
        uri,
        showCode: false,
        verificationCode: "",
      });
    };
    if (totpAuth && modalState.isOpen && state.step === "closed") {
      console.log("use eff, open");
      setState({ step: "preparing" });
      fetchMfa();
    } else if (!modalState.isOpen && state.step !== "closed") {
      console.log("use eff, close");
      setState({ step: "closed" });
    } else {
      console.log("use eff, skip");
    }
  }, [modalState.isOpen, state.step, totpAuth]);

  const handleClickVerify = async () => {
    if (state.step !== "verifying") return;
    if (state.isBusy) return;

    setState((prev) => ({ ...prev, isBusy: true }));

    try {
      const res = await totpAuth?.verifyTOTP({ code: state.verificationCode });
      if (res?.verified) {
        displayToast({
          status: "success",
          title: "MFA setup complete",
        });
        onRefresh();
        modalState.onClose();
      } else {
        displayToast({
          status: "error",
          title: "Unable to verify",
          description: "Incorrect verification code. Please try again.",
        });
        setState({ step: "closed" });
      }
    } catch (e) {
      console.error(e);
      displayToast({
        status: "error",
        title: "Failed to setup MFA",
        description: (e as Error).message,
      });
      setState({ step: "closed" });
    }
  };

  const isBusy = false;
  return (
    <Modal
      size="xl"
      onCloseComplete={() => setState({ step: "closed" })}
      closeOnEsc={!isBusy}
      closeOnOverlayClick={!isBusy}
      isOpen={isBusy || modalState.isOpen}
      onClose={modalState.onClose}>
      <ModalOverlay />
      <chakra-scope>
        <ModalContent>
          <ModalHeader>Setup MFA</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack w="full" align="start" spacing="1">
              {state.step === "preparing" ? (
                <>
                  <Skeleton height="5" w="100%" />
                  <Skeleton height="5" w="100%" />
                  <Skeleton height="5" w="100%" />
                  <Skeleton height="5" w="100%" />
                  <HStack w="100%" pt="8" align="center" justify="center">
                    <Skeleton height="48" w="48" alignSelf="center" />
                  </HStack>
                </>
              ) : state.step === "verifying" ? (
                <>
                  <Text>
                    Using your phone, <b>scan the QR code</b> below with an
                    authenticator (i.e. <b>Google Authenticator</b>, Microsoft
                    Authenticator, etc.)
                  </Text>
                  <Text>
                    Then <b>enter the 6-digit code</b> below, and{" "}
                    <b>click &quot;Verify & Setup&quot;</b>.
                  </Text>
                  <Text>
                    <Link
                      color="blue.600"
                      href="https://help.certcrowd.com/docs/Getting%20Started/setting-up-mfa/"
                      isExternal>
                      See more info about MFA setup.
                    </Link>
                  </Text>
                  <Center w="100%" pt="8">
                    <QRCode size={192} value={state.uri} />
                  </Center>
                  <VStack w="100%" pt="4" spacing="0">
                    {state.showCode && (
                      <>
                        <Text>
                          Or enter this code manually into your authenticator
                          app:
                        </Text>
                        <Center w="100%">
                          <Text color="brand.700" fontWeight="bold">
                            {state.secret}
                          </Text>
                        </Center>
                      </>
                    )}
                  </VStack>
                </>
              ) : state.step === "complete" ? (
                <Text>Multifactor setup complete</Text>
              ) : null}
              {state.step === "verifying" && (
                <VStack justify="center" align="center" w="100%">
                  <Text>
                    Enter verification code from your authenticator app
                  </Text>
                  <Box>
                    <PinInput
                      value={state.verificationCode}
                      autoFocus
                      onChange={(value) =>
                        setState((prev) => ({
                          ...prev,
                          verificationCode: value,
                        }))
                      }>
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                    </PinInput>
                  </Box>
                </VStack>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <HStack w="100%" justify="space-between">
              {state.step === "verifying" && !state.showCode ? (
                <Button
                  onClick={() =>
                    setState((prev) =>
                      prev.step === "verifying"
                        ? { ...prev, showCode: true }
                        : prev,
                    )
                  }
                  variant="link">
                  Can't scan QR code?
                </Button>
              ) : (
                <Box />
              )}
              <HStack>
                <Button onClick={modalState.onClose} colorScheme="gray">
                  Cancel
                </Button>
                <Button
                  loadingText="Verifying..."
                  isLoading={state.step === "verifying" && state.isBusy}
                  onClick={handleClickVerify}
                  isDisabled={
                    state.step !== "verifying" ||
                    state.verificationCode.length <= 5
                  }>
                  Verify & Setup
                </Button>
              </HStack>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </chakra-scope>
    </Modal>
  );
}
