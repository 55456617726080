import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Tooltip,
  Flex,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  Heading,
  HStack,
  IconButton,
  Input,
  Spacer,
  Stack,
  StackDivider,
  Text,
  VStack,
  SkeletonText,
} from "@chakra-ui/react";
import { EditorContent } from "@tiptap/react";
import { useState } from "react";
import { RiAddLine, RiPencilLine } from "react-icons/ri";
import Breadcrumbs from "../../../components/chakra/Breadcrumbs";
import {
  ManualData,
  ManualSectionData,
} from "../../../shared/v2/definitions/manuals";
import { RichTextToolbar } from "./components/RichTextToolbar";
import TableOfContents from "./components/TableOfContents";
import { useEditorWithExtensions } from "./components/useEditorWithExtensions";
import exampleManual from "./exampleManual";
import { useNavigate, useParams } from "react-router-dom";
import NotFoundScreen from "../../NotFound";
import { useApiMutation, useApiQuery } from "../../../utilities/apibelRequest";
import { formatDateOrIsoString } from "../../../utilities/dateUtils";
import useToast from "../../../utilities/useToast";
import { useQueryClient } from "react-query";
import { UsageManualSectionsLink } from "./components/UsageManualSectionsLink";

export default function EditManualSectionScreen() {
  const params = useParams();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const toast = useToast();

  const { sectionID } = params;

  const [title, setTitle] = useState("Section");

  const editor = useEditorWithExtensions({
    content: "",
    placeholder: "Click here to start typing",
    limit: 20000,
    editable: true,
  });

  const sectionQuery = useApiQuery(
    "manual/sectionByID",
    { manualSectionID: sectionID || "" },
    {
      enabled: !!sectionID,
      onSuccess: (data) => {
        setTitle(data.title);
        if (data.content.jsonStr) {
          try {
            editor?.commands.setContent(JSON.parse(data.content.jsonStr));
          } catch (e) {
            console.error("Failed to set content", e);
          }
        }
      },
    },
  );

  const { isFetching } = sectionQuery;

  const updateSectionMutation = useApiMutation("manual/updateSection");

  const didTitleChange =
    sectionQuery.isSuccess && title !== sectionQuery.data.title;
  const didContentChange =
    sectionQuery.isSuccess &&
    JSON.stringify(editor?.getJSON()) !== sectionQuery.data.content.jsonStr;
  const didChange = didTitleChange || didContentChange;

  const handleConfirmCancel = () => {
    if (!didChange) {
      return true;
    }
    const didConfirm = window.confirm(
      "Are you sure you want to leave, unsaved changes will be lost",
    );
    return didConfirm;
  };

  const handleClose = () => {
    if (sectionQuery.isSuccess) {
      navigate(`/manual/${sectionQuery.data.manual.manualID}`);
    } else {
      navigate("/manual");
    }
  };

  const handleClickCancel = () => {
    if (!handleConfirmCancel()) {
      return;
    }
    handleClose();
  };

  const handleClickSave = async () => {
    if (!didChange) {
      return false;
    }

    const newJson = JSON.stringify(editor?.getJSON());
    const newText = editor?.getText();
    const newTitle = title;

    if (!sectionID || !sectionQuery.isSuccess) {
      return false;
    }

    const newSection = {
      manualSectionID: sectionID,
      title: didTitleChange ? newTitle : undefined,
      content: didContentChange
        ? { jsonString: newJson, rawPreview: (newText || "").slice(0, 255) }
        : undefined,
    };

    editor?.setEditable(false);

    try {
      await updateSectionMutation.mutateAsync({ section: newSection });
    } catch (e) {
      console.error("Failed to update section", e);
      toast.displayToast({
        title: "Failed to update section",
        status: "error",
      });
      return false;
    }
    editor?.setEditable(true);

    toast.displayToast({
      title: "Updated section",
      status: "success",
    });
    return true;
  };

  const handleClickSaveAndClose = async () => {
    const didComplete = await handleClickSave();
    if (didComplete) {
      queryClient.invalidateQueries(["manual"]);
      handleClose();
    }
  };

  const isMutating = updateSectionMutation.isLoading;

  const linkedQuery = useApiQuery(
    "manual/manyRecordsForLinkedSection",
    {
      sectionID,
    },
    {
      enabled: sectionID !== undefined,
    },
  );

  const isLoading = linkedQuery.isLoading;

  if (!sectionID) {
    return <NotFoundScreen />;
  }

  return (
    <chakra-scope>
      <Box w="calc(100vw - 332px)">
        <Box
          pos="fixed"
          top="top-bar"
          w="calc(100vw - 332px)"
          h="48px"
          zIndex={100}
          pt="2"
          pl="4"
          pr="4"
          bg="white">
          <VStack w="full" align="start" spacing="0" h="100%">
            <HStack justify="space-between" w="full" h="100%">
              <HStack
                align="center"
                spacing={2}
                _hover={{
                  "& .edit-button": { visibility: "visible", opacity: 1 },
                }}>
                <HStack spacing={0}>
                  <Breadcrumbs
                    breadcrumbs={[
                      { link: "/manual", label: "Manual" },
                      ...(sectionQuery.isSuccess
                        ? [
                            {
                              link: `/manual/${sectionQuery.data.manual.manualID}`,
                              label: sectionQuery.data.manual.name,
                            },
                          ]
                        : []),
                    ]}
                  />
                  <Heading as="h1" size="md" color="gray.700">
                    {title.length >= 1 ? title : "Untitled Section"}
                  </Heading>
                </HStack>
              </HStack>
              <HStack h="100%">
                <ButtonGroup size="sm">
                  <Button
                    size="sm"
                    isDisabled={isMutating || isFetching}
                    onClick={handleClickCancel}
                    colorScheme="gray">
                    Cancel
                  </Button>

                  <Tooltip
                    label={
                      didChange
                        ? "Save and continue editing the section"
                        : "Nothing modified"
                    }>
                    <Button
                      isDisabled={!didChange || isMutating || isFetching}
                      onClick={handleClickSave}>
                      Save
                    </Button>
                  </Tooltip>
                  <Tooltip
                    label={
                      didChange
                        ? "Save and return to the manual"
                        : "Nothing modified"
                    }>
                    <Button
                      isDisabled={!didChange || isMutating || isFetching}
                      onClick={handleClickSaveAndClose}>
                      Save & Close
                    </Button>
                  </Tooltip>
                </ButtonGroup>
              </HStack>
            </HStack>
          </VStack>
        </Box>

        <Box minH="80px" w="100%" px="1" pos="relative">
          <Box
            zIndex="1"
            bg="white"
            borderBottomWidth="1px"
            borderTopWidth="1px"
            w="calc(100vw - 332px)"
            top="calc(var(--chakra-space-top-bar) + 48px)"
            left="0"
            position="fixed">
            <RichTextToolbar editor={editor} />
          </Box>
          <Box pt="24" w="100%">
            <Box
              maxW="container.lg"
              m="auto"
              px="3"
              bg="white"
              zIndex={1}
              h="16">
              <HStack bg="white" w="100%" justify="space-between" mt="6">
                <Box flex={1}>
                  <Input
                    size="lg"
                    fontSize="2.5rem"
                    borderBottom="none"
                    variant="flushed"
                    _focus={{ borderBottom: "none" }}
                    _focusVisible={{ borderBottom: "none" }}
                    fontWeight="bold"
                    placeholder="Enter a title"
                    minW="80%"
                    isDisabled={isMutating || isFetching}
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                  />
                </Box>
              </HStack>
            </Box>

            <Box
              px="3"
              py="2"
              m="auto"
              maxW="container.lg"
              className="manual-editor">
              <EditorContent editor={editor} />
            </Box>
          </Box>
        </Box>

        <Box
          h="calc(100vh - 48px)"
          pos="fixed"
          top="calc(var(--chakra-space-top-bar) + 1rem)"
          right="2"
          pl="2"
          w="320px">
          {sectionQuery.isSuccess && (
            <VStack spacing="4" w="100%" align="stretch">
              <Card variant="outline">
                <CardHeader>
                  <Heading size="sm">Section Details</Heading>
                </CardHeader>
                <CardBody>
                  <Text>
                    Owned by {sectionQuery.data.responsibleUser.firstName}{" "}
                    {sectionQuery.data.responsibleUser.lastName}
                  </Text>
                  <Text>
                    Last edited{" "}
                    {formatDateOrIsoString(
                      sectionQuery.data.modifiedTs,
                      "datetime-short",
                    )}
                  </Text>
                </CardBody>
              </Card>
              <Card variant="outline">
                <CardHeader>
                  <Heading size="sm">Usage</Heading>
                </CardHeader>
                {isLoading ? (
                  <CardBody>
                    <SkeletonText
                      mt="2"
                      noOfLines={3}
                      spacing="2"
                      skeletonHeight="3"
                    />
                  </CardBody>
                ) : (
                  linkedQuery.isSuccess &&
                  (linkedQuery.data.linkedRecords.length > 0 ? (
                    <CardBody>
                      <UsageManualSectionsLink sectionID={sectionID} />
                    </CardBody>
                  ) : (
                    <CardBody>No linked records.</CardBody>
                  ))
                )}
              </Card>
            </VStack>
          )}
        </Box>
      </Box>
    </chakra-scope>
  );
}
