import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import * as Feather from "react-feather";
import { Move } from "react-feather";
import { useAppState } from "../../components/App/AppProvider";
import { CustomFieldDragAndDrop } from "../../components/Forms/CustomFields/CustomFieldDD";
import { Select } from "../../components/Forms/Field/Select";
import { Text } from "../../components/Forms/Field/Text";
import { Textarea } from "../../components/Forms/Field/Textarea";
import { Card } from "../../components/UI/Card/Card";
import { Loading } from "../../components/UI/Loading/Loading";
import * as Permissions from "../../shared/constants/permission";
import * as Access from "../../utilities/access";
import {useLegacyApi } from "../../utilities/request";
import useToast from "../../utilities/useToast";
import SettingsScreenContainer from "../_chakra/settings/SettingsScreenContainer";

const ScreensRiskMatrix = () => {
  const { auth, app } = useAppState();
  const { get, put} = useLegacyApi();
  const [pageStatus, setPageStatus] = useState<any>("Loading");
  const [data, setData] = useState<any>(null);
  const { displayToast } = useToast();
  const [intersection, setIntersection] = useState<any>({
    Cols: 5,
    Rows: 5,
    Dropdowns: 5,
  });
  const deleteArray: string[] = [];

  const setOrder = async (list: any[]) => {
    // This is the dimension number that has been changed
    const dimensionNumber: number = list[0].RiskMatrixDimensionOrder - 1;

    // Update the dimension that has been changed
    const updatedDimensions: any[] = data.RiskMatrixDimensions.map(
      (dimension: any, index: any) => {
        if (
          dimension.RiskMatrixElements[0].RiskMatrixDimensionOrder ===
          dimensionNumber
        ) {
          return {
            ...dimension,
            RiskMatrixElements: list,
          };
        }
        return dimension;
      },
    );

    const updatedData = {
      ...data,
      RiskMatrixDimensions: updatedDimensions,
    };
    setData(updatedData);
  };

  const getIntersectionPosition = (
    intersection: any[],
    intersectValue: any,
  ): any => {
    const ID1 = intersectValue.RiskMatrixElementID1;
    const ID2 = intersectValue.RiskMatrixElementID2;
    let intersectPosition: number = -1;
    intersection.find((intersect: any, index: number) => {
      if (
        intersect.RiskMatrixElementID1 === ID1 &&
        intersect.RiskMatrixElementID2 === ID2
      ) {
        intersectPosition = index;
        return intersect;
      }
    });
    return intersectPosition;
  };

  const isRiskLevelUsed = (
    intersection: any[],
    RiskMatrixElementID: string,
  ): boolean =>
    intersection.find(
      (row: any) => row.RiskMatrixElementID3 === RiskMatrixElementID,
    ) !== undefined;

  useEffect(() => {
    const fetchList = async () => {
      const riskData: any = await get(`settings/riskMatrix`);
      setIntersection(riskData.intersection);
      setData(riskData.data);
      setPageStatus("Ready");
    };

    fetchList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (pageStatus === "Loading") {
    return (
      <section>
        <div className="progress-spinner" style={{ marginTop: "20%" }}>
          <Loading size={"xl"} />
        </div>
      </section>
    );
  }
  return Access.checkAccess(
    app.permissions_LEGACY,
    Permissions.CodeUserSettings,
    Permissions.TypeRead,
    false,
  ) ? (
    <SettingsScreenContainer pageTitle="Risk Settings">
      <section>
        <Card
          collapsable
          title="Risk Matrix Settings"
          headerCols={[
            { colProps: {}, children: <span /> },
            {
              // Returning true here as there are not currently any permissions on RiskMatrix
              colProps: { sm: "auto" },
              children:
                pageStatus !== "Ready" ? (
                  <span />
                ) : true ? (
                  <Button
                    type="button"
                    variant="outline-dark"
                    onClick={() => {
                      if (pageStatus !== "New") {
                        setPageStatus("Editing");
                      }
                    }}>
                    <Feather.Edit2 className="feather" size="16" />
                    Edit
                  </Button>
                ) : (
                  <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip id="tooltip-not-authorised-to-edit">
                        You do not have permission to edit this
                      </Tooltip>
                    }>
                    <Button
                      type="button"
                      variant="outline-dark"
                      className="disabled">
                      <Feather.Edit2 className="feather" />
                      Edit
                    </Button>
                  </OverlayTrigger>
                ),
            },
          ]}>
          <Formik
            initialValues={data}
            onSubmit={async (values, formikActions) => {
              if (checkEmpty(values)) {
                formikActions.setSubmitting(false);
              } else {
                setPageStatus("Submitting");
                values.RiskMatrixVersion =
                  values.RiskMatrixVersion === ""
                    ? 1
                    : values.RiskMatrixVersion + 1;
                values.RiskMatrixDimensions = values.RiskMatrixDimensions.map(
                  (dimensions: any, index: number) => ({
                    ...dimensions,
                    RiskMatrixDimensionName:
                      dimensions.RiskMatrixElements[0]
                        .RiskMatrixDimensionTypeName,
                  }),
                );
                const updatedatedRiskDimensions: any[] =
                  values.RiskMatrixDimensions.map((riskDimension: any) =>
                    riskDimension.RiskMatrixElements.map((riskElement: any) => {
                      if (typeof riskElement.RiskMatrixElementID === "number") {
                        riskElement.RiskMatrixElementID = "";
                      }
                      return riskElement;
                    }),
                  );

                try {
                  const reply = await put(`settings/riskMatrix`, values);
                  displayToast({
                    status: "success",
                    title: `Risk Matrix updated successfully`,
                  });
                  setData(reply.data);
                } catch (err) {
                  displayToast({
                    status: "error",
                    title: `Failed to update Risk Matrix`,
                  });
                } finally {
                  setPageStatus("Ready");
                }
              }
            }}
            render={(formikProps) => (
              <Form onSubmit={formikProps.handleSubmit}>
                <Form.Group>
                  {formikProps.values.RiskMatrixDimensions.map(
                    (RiskDimension: any, dimensionIndex: number) => (
                      <React.Fragment key={dimensionIndex}>
                        <Row>
                          <Col sm="12" style={{ paddingLeft: 0 }}>
                            <Form.Label
                              key="dimensionLabel"
                              column
                              style={{
                                textAlign: "left",
                                wordWrap: "unset",
                              }}>
                              {
                                RiskDimension.RiskMatrixElements[0]
                                  .RiskMatrixDimensionTypeName
                              }
                            </Form.Label>
                          </Col>
                        </Row>
                        {pageStatus === "Editing" ? (
                          <CustomFieldDragAndDrop
                            setOrder={setOrder}
                            fields={RiskDimension.RiskMatrixElements}
                            customfields={RiskDimension.RiskMatrixElements.map(
                              (riskElement: any, index: number) => (
                                <Row
                                  key={riskElement.RiskMatrixElementID}
                                  accessKey={riskElement.RiskMatrixElementID}>
                                  <Col key={index} sm="2" />
                                  <Col sm="1" style={{ textAlign: "right" }}>
                                    <Move />
                                  </Col>
                                  <Col sm="9" style={{ marginBottom: 35 }}>
                                    <Row>
                                      <Col sm="9">
                                        <Text
                                          value={
                                            formikProps.values
                                              .RiskMatrixDimensions[
                                              dimensionIndex
                                            ].RiskMatrixElements[index]
                                              .RiskMatrixElementName
                                          }
                                          name={`RiskMatrixDimensions[${dimensionIndex}].RiskMatrixElements[${index}].RiskMatrixElementName`}
                                          onUpdate={formikProps.handleChange}
                                          readOnly={
                                            pageStatus !== "Editing" &&
                                            pageStatus !== "New"
                                          }
                                        />
                                      </Col>

                                      {pageStatus === "Editing" &&
                                      riskElement.RiskMatrixDimensionTypeEditable >
                                        0 &&
                                      index > 0 ? (
                                        <Col sm="1">
                                          {isRiskLevelUsed(
                                            formikProps.values.intersection,
                                            riskElement.RiskMatrixElementID,
                                          ) ? (
                                            <OverlayTrigger
                                              placement="auto"
                                              overlay={
                                                <Tooltip id="tooltip-not-authorised-to-edit">
                                                  Cannot delete a Risk Level
                                                  that is used in Risk Matrix
                                                </Tooltip>
                                              }>
                                              <Button
                                                type="button"
                                                className="disabled">
                                                Delete
                                              </Button>
                                            </OverlayTrigger>
                                          ) : (
                                            <Button
                                              value={
                                                riskElement.RiskMatrixElementID
                                              }
                                              onClick={(event: any) => {
                                                deleteArray.push(
                                                  event.target.value,
                                                );
                                                RiskDimension.RiskMatrixElements =
                                                  RiskDimension.RiskMatrixElements.filter(
                                                    (element: any) =>
                                                      element.RiskMatrixElementID !==
                                                      event.target.value,
                                                  );
                                                formikProps.setValues(
                                                  formikProps.values,
                                                );
                                              }}
                                              variant="light">
                                              Delete
                                            </Button>
                                          )}
                                        </Col>
                                      ) : null}
                                    </Row>
                                    <Row>
                                      <Col sm="9">
                                        <Textarea
                                          value={
                                            formikProps.values
                                              .RiskMatrixDimensions[
                                              dimensionIndex
                                            ].RiskMatrixElements[index]
                                              .RiskMatrixElementDescription
                                          }
                                          name={`RiskMatrixDimensions[${dimensionIndex}].RiskMatrixElements[${index}].RiskMatrixElementDescription`}
                                          onUpdate={formikProps.handleChange}
                                          readOnly={
                                            pageStatus !== "Editing" &&
                                            pageStatus !== "New"
                                          }
                                        />
                                      </Col>
                                    </Row>

                                    {riskElement.RiskMatrixDimensionTypeName ===
                                    "Risk Level" ? (
                                      <Row>
                                        <Col sm="9">
                                          <Select
                                            readOnly={false}
                                            onUpdate={(event: any) => {
                                              const copy =
                                                formikProps.values
                                                  .RiskMatrixDimensions;
                                              copy[2].RiskMatrixElements[
                                                index
                                              ].RiskMatrixElementColourID =
                                                event.value;
                                              copy[2].RiskMatrixElements[
                                                index
                                              ].RiskMatrixElementColourHex =
                                                event.label;
                                              formikProps.setFieldValue(
                                                "RiskMatrixDimensions",
                                                copy,
                                              );
                                            }}
                                            name={`RiskMatrixDimensions[${dimensionIndex}].RiskMatrixElements[${index}].RiskMatrixElementColourID`}
                                            value={
                                              formikProps.values
                                                .RiskMatrixDimensions[2]
                                                .RiskMatrixElements[index]
                                                .RiskMatrixElementColourID
                                            }
                                            optionsList={
                                              formikProps.values.colours
                                            }
                                            optionsListID="RiskMatrixElementColourID"
                                            optionsListValue="RiskMatrixElementColourHex"
                                            style={{
                                              singleValue: (
                                                provided: any,
                                                state: any,
                                              ) => {
                                                const color =
                                                  formikProps.values
                                                    .RiskMatrixDimensions[2]
                                                    .RiskMatrixElements[index]
                                                    .RiskMatrixElementColourHex;
                                                return {
                                                  ...provided,
                                                  color,
                                                };
                                              },

                                              option: (
                                                provided: any,
                                                state: any,
                                              ) => {
                                                const color = state.data.label;

                                                return {
                                                  ...provided,
                                                  color,
                                                  ":active": {
                                                    ...provided[":active"],
                                                    backgroundColor:
                                                      state.isSelected,
                                                  },
                                                  backgroundColor:
                                                    state.data.label,
                                                  borderTopRightRadius: "6px",
                                                  borderTopLeftRadius: "6px",
                                                  borderBottomRightRadius:
                                                    "6px",
                                                  borderBottomLeftRadius: "6px",
                                                };
                                              },
                                              control: (
                                                provided: any,
                                                state: any,
                                              ) => ({
                                                ...provided,
                                                backgroundColor:
                                                  formikProps.values
                                                    .RiskMatrixDimensions[2]
                                                    .RiskMatrixElements[index]
                                                    .RiskMatrixElementColourHex,
                                              }),
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    ) : null}
                                  </Col>
                                  <Col sm="1" />
                                </Row>
                              ),
                            )}
                          />
                        ) : (
                          RiskDimension.RiskMatrixElements.map(
                            (riskElement: any, index: number) => (
                              <Row
                                key={riskElement.RiskMatrixElementID}
                                accessKey={riskElement.RiskMatrixElementID}>
                                <Col key={index} sm="2" />
                                <Col sm="1" style={{ textAlign: "right" }}>
                                  {index + 1}
                                </Col>
                                <Col sm="9" style={{ marginBottom: 35 }}>
                                  <Row>
                                    <Col sm="9">
                                      <Text
                                        value={
                                          formikProps.values
                                            .RiskMatrixDimensions[
                                            dimensionIndex
                                          ].RiskMatrixElements[index]
                                            .RiskMatrixElementName
                                        }
                                        name={`RiskMatrixDimensions[${dimensionIndex}].RiskMatrixElements[${index}].RiskMatrixElementName`}
                                        onUpdate={formikProps.handleChange}
                                        readOnly={
                                          pageStatus !== "Editing" &&
                                          pageStatus !== "New"
                                        }
                                      />
                                    </Col>

                                    {pageStatus === "Editing" &&
                                    riskElement.RiskMatrixDimensionTypeEditable >
                                      0 ? (
                                      <Col sm="1">
                                        {" "}
                                        <Button
                                          value={
                                            riskElement.RiskMatrixElementID
                                          }
                                          onClick={(event: any) => {
                                            deleteArray.push(
                                              event.target.value,
                                            );
                                            RiskDimension.RiskMatrixElements =
                                              RiskDimension.RiskMatrixElements.filter(
                                                (element: any) =>
                                                  element.RiskMatrixElementID !==
                                                  event.target.value,
                                              );
                                            formikProps.setValues(
                                              formikProps.values,
                                            );
                                          }}
                                          variant="light">
                                          Delete
                                        </Button>{" "}
                                      </Col>
                                    ) : null}
                                  </Row>
                                  <Row>
                                    <Col sm="9">
                                      <Textarea
                                        value={
                                          formikProps.values
                                            .RiskMatrixDimensions[
                                            dimensionIndex
                                          ].RiskMatrixElements[index]
                                            .RiskMatrixElementDescription
                                        }
                                        name={`RiskMatrixDimensions[${dimensionIndex}].RiskMatrixElements[${index}].RiskMatrixElementDescription`}
                                        onUpdate={formikProps.handleChange}
                                        readOnly={
                                          pageStatus !== "Editing" &&
                                          pageStatus !== "New"
                                        }
                                      />
                                    </Col>
                                  </Row>
                                  {riskElement.RiskMatrixDimensionTypeName ===
                                  "Risk Level" ? (
                                    <Row>
                                      <Col sm="9">
                                        <Text
                                          name={`RiskMatrixDimensions[${dimensionIndex}].RiskMatrixElements[${index}].RiskMatrixElementColourID`}
                                          readOnly
                                          style={{
                                            backgroundColor:
                                              formikProps.values
                                                .RiskMatrixDimensions[2]
                                                .RiskMatrixElements[index]
                                                .RiskMatrixElementColourHex,
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  ) : null}
                                </Col>
                                <Col sm="1" />
                              </Row>
                            ),
                          )
                        )}
                        {RiskDimension.RiskMatrixElements[0]
                          .RiskMatrixDimensionTypeEditable === 1 &&
                        pageStatus === "Editing" ? (
                          <Row>
                            <Col sm="3"> </Col>{" "}
                            <Col>
                              <Button
                                variant="outline-dark"
                                onClick={() => {
                                  const newElement: any = {
                                    ...RiskDimension.RiskMatrixElements[
                                      RiskDimension.RiskMatrixElements.length
                                    ],
                                    RiskMatrixElementID:
                                      Math.random().toString(),
                                    RiskMatrixElementName: "",
                                    RiskMatrixElementDescription: "",
                                    RiskMatrixDimensionTypeEditable: 1,
                                    RiskMatrixDimensionTypeName:
                                      RiskDimension.RiskMatrixElements[0]
                                        .RiskMatrixDimensionTypeName,
                                    RiskMatrixElementColourID:
                                      formikProps.values.colours[0]
                                        .RiskMatrixElementColourID,
                                    RiskMatrixElementColourHex:
                                      formikProps.values.colours[0]
                                        .RiskMatrixElementColourHex,
                                  };

                                  formikProps.setFieldValue(
                                    `RiskMatrixDimensions[${dimensionIndex}].RiskMatrixElements[${RiskDimension.RiskMatrixElements.length}]`,
                                    newElement,
                                  );
                                }}>
                                {" "}
                                Add Risk Level
                              </Button>{" "}
                            </Col>
                          </Row>
                        ) : null}
                      </React.Fragment>
                    ),
                  )}

                  <h1> Risk Matrix </h1>
                  <Table title="Risk Matrix" bordered>
                    <thead>
                      <tr>
                        <td />
                        {formikProps.values.RiskMatrixDimensions[1].RiskMatrixElements.map(
                          (riskElementRow: any, rowNum: number) => (
                            <th
                              key={`title:${rowNum}`}
                              style={{ width: "200px" }}>
                              {riskElementRow.RiskMatrixElementName}
                            </th>
                          ),
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {formikProps.values.RiskMatrixDimensions[0].RiskMatrixElements.map(
                        (riskElementRow: any, rowNum: number) => (
                          <tr key={riskElementRow.RiskMatrixElementID}>
                            <th
                              style={{
                                color: "var(--color-secondary-grey-medium)",
                              }}>
                              {riskElementRow.RiskMatrixElementName}
                            </th>
                            {formikProps.values.RiskMatrixDimensions[1].RiskMatrixElements.map(
                              (riskElementCol: any, colNum: number) => (
                                <td
                                  key={`col:${colNum}`}
                                  style={{
                                    backgroundColor:
                                      formikProps.values.RiskMatrixDimensions[2].RiskMatrixElements.find(
                                        (element: any, index: number) =>
                                          element.RiskMatrixElementID ===
                                          formikProps.values.intersection[
                                            getIntersectionPosition(
                                              formikProps.values.intersection,
                                              {
                                                RiskMatrixElementID1:
                                                  riskElementRow.RiskMatrixElementID,
                                                RiskMatrixElementID2:
                                                  riskElementCol.RiskMatrixElementID,
                                              },
                                            )
                                          ]?.RiskMatrixElementID3,
                                      )?.RiskMatrixElementColourHex ?? "beige",
                                  }}>
                                  <Select
                                    onUpdate={(event: any) => {
                                      const intersectPosition =
                                        getIntersectionPosition(
                                          formikProps.values.intersection,
                                          {
                                            RiskMatrixElementID1:
                                              riskElementRow.RiskMatrixElementID,
                                            RiskMatrixElementID2:
                                              riskElementCol.RiskMatrixElementID,
                                          },
                                        );

                                      const intersect =
                                        formikProps.values.intersection.map(
                                          (
                                            intersectValue: any,
                                            index: number,
                                          ) => {
                                            if (index !== intersectPosition) {
                                              return intersectValue;
                                            }
                                            return {
                                              ...intersectValue,
                                              RiskMatrixElementID3: event.value,
                                              RiskMatrixElementName:
                                                event.label,
                                            };
                                          },
                                        );
                                      formikProps.setFieldValue(
                                        `intersection`,
                                        intersect,
                                      );
                                    }}
                                    name={`formikProps.values.intersection[getIntersectionPosition(${
                                      formikProps.values.intersection
                                    }, ${{
                                      RiskMatrixElementID1:
                                        riskElementRow.RiskMatrixElementID,
                                      RiskMatrixElementID2:
                                        riskElementCol.RiskMatrixElementID,
                                    }})].RiskMatrixElementName`}
                                    value={
                                      formikProps.values.intersection[
                                        getIntersectionPosition(
                                          formikProps.values.intersection,
                                          {
                                            RiskMatrixElementID1:
                                              riskElementRow.RiskMatrixElementID,
                                            RiskMatrixElementID2:
                                              riskElementCol.RiskMatrixElementID,
                                          },
                                        )
                                      ]?.RiskMatrixElementID3 ?? "Unknown"
                                    }
                                    optionsList={
                                      formikProps.values.RiskMatrixDimensions[2]
                                        .RiskMatrixElements
                                    }
                                    optionsListID="RiskMatrixElementID"
                                    optionsListValue="RiskMatrixElementName"
                                    readOnly={pageStatus !== "Editing"}
                                    readOnlyComponent={() => (
                                      <Text
                                        name={`formikProps.values.intersection[getIntersectionPosition(${
                                          formikProps.values.intersection
                                        }, ${{
                                          RiskMatrixElementID1:
                                            riskElementRow.RiskMatrixElementID,
                                          RiskMatrixElementID2:
                                            riskElementCol.RiskMatrixElementID,
                                        }})].RiskMatrixElementName`}
                                        readOnly
                                        value={
                                          formikProps.values.intersection[
                                            getIntersectionPosition(
                                              formikProps.values.intersection,
                                              {
                                                RiskMatrixElementID1:
                                                  riskElementRow.RiskMatrixElementID,
                                                RiskMatrixElementID2:
                                                  riskElementCol.RiskMatrixElementID,
                                              },
                                            )
                                          ]?.RiskMatrixElementName ?? "Unknown"
                                        }
                                        style={{
                                          color: "white",
                                          opacity: 0.75,
                                        }}
                                      />
                                    )}
                                    readOnlyValue={
                                      formikProps.values.intersection[
                                        getIntersectionPosition(
                                          formikProps.values.intersection,
                                          {
                                            RiskMatrixElementID1:
                                              riskElementRow.RiskMatrixElementID,
                                            RiskMatrixElementID2:
                                              riskElementCol.RiskMatrixElementID,
                                          },
                                        )
                                      ]?.RiskMatrixElementName ?? "Unknown"
                                    }
                                    style={{
                                      singleValue: (
                                        provided: any,
                                        state: any,
                                      ) => {
                                        const color =
                                          pageStatus !== "Editing"
                                            ? "white"
                                            : "";
                                        return {
                                          ...provided,
                                          color,
                                          backgroundColor: state.data.label,
                                        };
                                      },
                                    }}
                                  />
                                </td>
                              ),
                            )}
                          </tr>
                        ),
                      )}
                    </tbody>
                  </Table>
                </Form.Group>

                {pageStatus === "Editing" || pageStatus === "Submitting" ? (
                  <Form.Group as={Row}>
                    <Col sm="2" />
                    <Col sm="auto">
                      <Button
                        type="submit"
                        disabled={pageStatus === "Submitting"}
                        onClick={() => {
                          const errors: string[] = [];
                          formikProps.values.RiskMatrixDimensions.forEach(
                            (RiskDimension: any, dimensionIndex: number) => {
                              RiskDimension.RiskMatrixElements.forEach(
                                (riskElement: any, index: number) => {
                                  if (
                                    riskElement.RiskMatrixElementName === ""
                                  ) {
                                    errors.push(
                                      `"${
                                        riskElement.RiskMatrixDimensionTypeName
                                      } Element ${index + 1} name is required"`,
                                    );
                                  }
                                  if (
                                    riskElement.RiskMatrixElementDescription ===
                                    ""
                                  ) {
                                    errors.push(
                                      `"${
                                        riskElement.RiskMatrixDimensionTypeName
                                      } Element ${
                                        index + 1
                                      } description is required"`,
                                    );
                                  }
                                },
                              );
                            },
                          );
                          if (
                            errors.length > 0 &&
                            formikProps.isSubmitting === false
                          ) {
                            const title = "Failed to update Risk Matrix";
                            const description = `${errors.join("\n")}`;
                            displayToast({
                              status: "error",
                              title,
                              description,
                            });
                          }
                        }}>
                        {" "}
                        Submit{" "}
                      </Button>
                    </Col>

                    <Col sm="auto">
                      <Button
                        type="button"
                        disabled={pageStatus === "Submitting"}
                        variant="light"
                        onClick={() => {
                          formikProps.resetForm();
                          setPageStatus("Ready");
                        }}>
                        Cancel{" "}
                      </Button>
                    </Col>
                  </Form.Group>
                ) : null}
              </Form>
            )}
          />
        </Card>
      </section>
    </SettingsScreenContainer>
  ) : (
    <section />
  );
};

const checkEmpty = (values: any) => {
  let count = 0;
  values.RiskMatrixDimensions.map(
    (RiskDimension: any, dimensionIndex: number) => {
      RiskDimension.RiskMatrixElements.map(
        (riskElement: any, index: number) => {
          if (
            riskElement.RiskMatrixElementName === "" ||
            riskElement.RiskMatrixElementDescription === ""
          ) {
            count = +1;
          }
        },
      );
    },
  );
  return count > 0;
};

export type PageStatus = "Loading" | "Ready" | "Editing" | "Submitting";

export { ScreensRiskMatrix };
