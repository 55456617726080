import {
  Box,
  Button,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { RefreshCw as RefreshIcon } from "react-feather";
import { useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useAppState } from "../../components/App/AppProvider";
import { BreadcrumbElement } from "../../components/chakra/Breadcrumbs";
import { Card, CardContent, CardHeading } from "../../components/chakra/card";
import ChakraScreenContainer from "../../components/chakra/ChakraScreenContainer";
import Spinner from "../../components/chakra/common/Spinner";
import Icon, { IconSizes } from "../../components/UI/Icon";
import { useApiQuery } from "../../utilities/apibelRequest";
import {
  formatUtcDateIsoShort,
  formatUtcDateReadableShort,
  getBrowserTimeZone,
} from "../../utilities/dateUtils";
import { downloadAsCsv } from "../../utilities/downloadAsCsv";
import NotFoundScreen from "../NotFound";

const BREADCRUMBS: BreadcrumbElement[] = [
  {
    label: "Home",
    link: "/",
  },
  {
    label: "Reports",
    link: "/report",
  },
  {
    label: "Time Sheet Report",
    link: "/report/easy/time-sheet",
  },
];

const GeneratedTimeSheetReportScreen = () => {
  const { auth, app } = useAppState();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();

  const { permissions } = app;

  // If user can't see time notes or can't see reports, return 404
  const canSeeScreen =
    permissions.TIME_NOTES.READ.ALL && permissions.REPORTS.READ.ALL;

  const startDateUtc = searchParams.get("startDateUtc");
  const endDateUtc = searchParams.get("endDateUtc");

  const datesSupplied = startDateUtc !== null && endDateUtc !== null;

  const reportResult = useApiQuery(
    "report/quickReportTimeNotes",
    { startDate: startDateUtc || "", endDate: endDateUtc || "" },
    {
      enabled: datesSupplied && canSeeScreen,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      staleTime: 0,
    },
  );

  const formattedStart = formatUtcDateReadableShort(
    new Date(startDateUtc || ""),
  );
  const formattedEnd = formatUtcDateReadableShort(new Date(endDateUtc || ""));
  const userTimeZone = getBrowserTimeZone();

  const handleClickRefresh = () => {
    queryClient.invalidateQueries(["report", "quickReportTimeNotes"]);
  };

  const sortedItems = reportResult.isSuccess
    ? reportResult.data.sort((a, b) => a.userName.localeCompare(b.userName))
    : [];

  const handleClickExport = () => {
    if (!reportResult.isSuccess) return;

    const date = `${formatUtcDateIsoShort(
      new Date(startDateUtc || ""),
    )}__${formatUtcDateIsoShort(new Date(endDateUtc || ""))}`;
    const data = sortedItems.map(
      ({ userName, billableHours, nonBillableHours }) => ({
        Name: userName,
        "Billable Hours": billableHours,
        "Non-Billable Hours": nonBillableHours,
        "Total Hours": billableHours + nonBillableHours,
      }),
    );

    downloadAsCsv(data, `timesheet_report_${date}.csv`);
  };

  if (!canSeeScreen) {
    return <NotFoundScreen />;
  }

  return (
    <chakra-scope>
      <ChakraScreenContainer
        pageTitle="Time Sheet Report"
        breadcrumbs={BREADCRUMBS}>
        <Card>
          <CardHeading>
            {!reportResult.isFetching && datesSupplied && (
              <>
                <Button
                  size="sm"
                  leftIcon={<Icon name="Download" />}
                  onClick={handleClickExport}
                  variant="ghost">
                  Export as CSV
                </Button>
                <Button
                  size="sm"
                  leftIcon={<RefreshIcon size={IconSizes.md} />}
                  onClick={handleClickRefresh}
                  variant="ghost">
                  Refresh
                </Button>
              </>
            )}
          </CardHeading>
          <CardContent>
            {!datesSupplied && <Text>Invalid Configuration</Text>}
            {reportResult.isFetching ? (
              <VStack>
                <Spinner />
                <Text>Generating Report</Text>
              </VStack>
            ) : reportResult.isSuccess ? (
              <Box overflowX="auto" w="full">
                <Table size="sm" variant="data-table">
                  <Thead>
                    <Tr>
                      <Th>Name</Th>
                      <Th>Non-Billable Hours</Th>
                      <Th>Billable Hours</Th>
                      <Th>Total Hours</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {sortedItems.map(
                      ({
                        userName,
                        billableHours,
                        nonBillableHours,
                        userID,
                      }) => (
                        <Tr key={userID}>
                          <Td>{userName}</Td>
                          <Td>{nonBillableHours}</Td>
                          <Td>{billableHours}</Td>
                          <Td>{nonBillableHours + billableHours}</Td>
                        </Tr>
                      ),
                    )}
                  </Tbody>
                  <Tfoot>
                    <Tr>
                      <Th>Name</Th>
                      <Th>Non-Billable Hours</Th>
                      <Th>Billable Hours</Th>
                      <Th>Total Hours</Th>
                    </Tr>
                  </Tfoot>
                  <TableCaption>
                    {formattedStart} - {formattedEnd} ({userTimeZone})
                  </TableCaption>
                </Table>
              </Box>
            ) : reportResult.isError ? (
              <Text>Something went wrong...</Text>
            ) : null}
          </CardContent>
        </Card>
      </ChakraScreenContainer>
    </chakra-scope>
  );
};

export default GeneratedTimeSheetReportScreen;
