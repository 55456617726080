import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Frown } from "react-feather";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { useAuthState } from "../../components/App/AppProvider";
import * as Request from "../../utilities/request";
import { validateDate } from "../../utilities/formatDate";

import {
  OnDemandActionMatchParams,
  OnDemandActionPageStatus,
} from "./Action.d";

import { Loading } from "../../components/UI/Loading/Loading";
import { useAuth } from "../../utilities/useAuth";

const ScreensOnDemandAction = () => {
  const { getToken } = useAuth();
  const [pageStatus, setPageStatus] =
    useState<OnDemandActionPageStatus>("Loading");
  const authState = useAuthState();
  const navigate = useNavigate();
  const params = useParams();

  const requirementOnDemandActionID = params.requirementOnDemandActionID || "";

  useEffect(() => {
    const createOnDemandAction = async () => {
      const date = moment().toDate();
      const tz = moment.tz.guess();
      const body = {
        ActionStartTs: validateDate(date, tz),
        ActionTimezone: tz,
      };

      try {
        const req = await Request.put(
          `app/ondemandaction/${requirementOnDemandActionID}`,
          body,
          getToken
        );
        if (req.data && req.status === 200) {
          navigate(
            {
              pathname: `/instance/${req.data.InstanceID}`,
              search: `display=${req.data.ObjectTypeGroupName.toLowerCase()}s`,
            },
            {
              state: {
                pageStatus: "Responding",
              },
            },
          );
        } else {
          setPageStatus("Error");
        }
      } catch (e) {
        setPageStatus("Error");
      }
    };

    if (authState.isLoggedIn) {
      createOnDemandAction();
    }
  }, [navigate, requirementOnDemandActionID, authState]);

  return (
    <Container>
      <Row style={{ marginTop: "20%", textAlign: "center" }}>
        <Col>
          {pageStatus === "Loading" ? (
            <>
              <div className="progress-spinner" style={{ marginTop: "20%" }}>
                <Loading size={"xl"} />
              </div>
            </>
          ) : pageStatus === "Error" ? (
            <Card>
              <Row style={{ marginBottom: "20px" }}>
                <Col>
                  <Frown />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h1>
                    Oops! It looks like this action does not exist or you
                    don&apos;t have permission to view it.
                  </h1>
                </Col>
              </Row>
            </Card>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
};

export { ScreensOnDemandAction };
