import React from "react";
import {
  Col,
  Form,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import * as Feather from "react-feather";
import { Link, useLocation } from "react-router-dom";
import { useAppState } from "../../components/App/AppProvider";
import { Textarea } from "../../components/Forms/Field/Textarea";
import { Card } from "../../components/UI/Card/Card";
import LegacyScreenContainer from "../../components/UI/LegacyScreenContainer";
import { Loading } from "../../components/UI/Loading/Loading";
import { NewObjectTypeButton } from "../../components/UI/NewObjectTypeButton";
import { Paginator } from "../../components/UI/Paginator/Paginator";
import { LifeCycle } from "../../components/UI/Status/LifeCycle";
import {
  objectStatusToVariant,
  Status,
} from "../../components/UI/Status/Status";
import * as ObjectStatus from "../../shared/constants/objectStatus";
import * as ObjectTypeGroup from "../../shared/constants/objectTypeGroup";
import { formatDateTime } from "../../utilities/formatDate";
import * as Request from "../../utilities/request";
import { text_truncate } from "../../utilities/strings";
import { getUrlSearchParam } from "../../utilities/url";
import useToast from "../../utilities/useToast";
import { useAuth } from "../../utilities/useAuth";

interface ActionDashboardState {
  actions?: any[];
  instances?: any[];
  error?: any;
  fetchingActions?: boolean;
  fetchingInstances?: boolean;
}

interface ActionDashboardFiltersState {
  openResponded: boolean;
  upcoming: boolean;
  due: boolean;
  overdue: boolean;
  missed: boolean;
  importantActionsPage: number;
  actionsPage: number;
  pageSize: number;
}

type ActionPageStatus = "Loading" | "Ready" | "Error" | "Fetching";

const lifeCycleFilter = (filters: ActionDashboardFiltersState, instance: any) =>
  instance.LifeCycle !== "Closed" &&
  ((filters.upcoming && instance.LifeCycle === "Upcoming") ||
    (filters.due && instance.LifeCycle === "Due") ||
    (filters.overdue && instance.LifeCycle === "Overdue") ||
    (filters.openResponded && instance.LifeCycle === "Open Responded"));

const pageFilter = (page: number, pageSize: number, index: number) =>
  index < page * pageSize && index >= (page - 1) * pageSize;

const ScreensActionDashboard = (props: {}) => {
  const { auth } = useAppState();
  const { getToken } = useAuth();
  const location = useLocation();
  const [data, setData] = React.useState<ActionDashboardState>({});
  const [filters, setFilters] = React.useState<ActionDashboardFiltersState>({
    openResponded: false,
    upcoming: false,
    due: true,
    overdue: true,
    missed: false,
    importantActionsPage: 1,
    actionsPage: 1,
    pageSize: 10,
  });
  const { displayToast } = useToast();
  const [pageStatus, setPageStatus] =
    React.useState<ActionPageStatus>("Loading");

  React.useEffect(() => {
    const getData = async () => {
      try {
        setData({ ...data, fetchingInstances: true, fetchingActions: true });
        await Request.getPaginated(`action`, 300, updateActionResults, getToken);
        await Request.getPaginated(`instance`, 500, updateInstanceResults, getToken);
      } catch (err) {
        setData({
          error: err,
        });
      }
    };

    getData();
  }, [auth]);

  let interimInstanceResults: any[] = [];
  const updateInstanceResults = (
    responseData: any,
    status: Request.RequestStatus,
  ) => {
    if (status !== "Error") {
      interimInstanceResults = interimInstanceResults.concat(
        responseData.Instances,
      );
      setData((prevData) => ({
        ...prevData,
        instances: interimInstanceResults,
        fetchingInstances: status === "Fetching",
      }));
    } else {
      displayToast({
        status: "error",
        title: `Failed to retrieve Tasks`,
      });
    }
    setPageStatus(status);
  };

  let interimActionResults: any[] = [];
  const updateActionResults = (
    responseData: any,
    status: Request.RequestStatus,
  ) => {
    if (status !== "Error") {
      interimActionResults = interimActionResults.concat(responseData.Actions);
      setData((prevData) => ({
        ...prevData,
        actions: interimActionResults,
        fetchingActions: status === "Fetching",
      }));
    } else {
      displayToast({
        status: "error",
        title: `Failed to retrieve Actions`,
      });
    }
    setPageStatus(status);
  };

  React.useEffect(() => {
    const getUrlFilter = () => {
      const filter = getUrlSearchParam(location.search, "filter");
      if (filter === "upcoming") {
        setFilters({
          ...filters,
          actionsPage: 1,
          openResponded: false,
          upcoming: true,
          due: false,
          overdue: false,
          missed: false,
        });
      } else if (filter === "due") {
        setFilters({
          ...filters,
          actionsPage: 1,
          openResponded: false,
          due: true,
          upcoming: false,
          overdue: false,
          missed: false,
        });
      } else if (filter === "overdue") {
        setFilters({
          ...filters,
          actionsPage: 1,
          openResponded: false,
          overdue: true,
          upcoming: false,
          due: false,
          missed: false,
        });
      } else if (filter === "openresponded") {
        setFilters({
          ...filters,
          actionsPage: 1,
          openResponded: true,
          due: false,
          upcoming: false,
          overdue: false,
          missed: false,
        });
      } else if (filter === "missed") {
        setFilters({
          ...filters,
          actionsPage: 1,
          openResponded: false,
          due: false,
          upcoming: false,
          overdue: false,
          missed: true,
        });
      }
    };
    getUrlFilter();
    // eslint-disable-next-line
  }, [location.search]);

  // Important Actions Card Filters and Pagination
  let filteredActions: any[] = data.actions ? data.actions : [];
  let paginatedFilteredActions: any[] = [];
  if (filteredActions.length > 0) {
    // Filter based off ObjectStatus
    filteredActions = filteredActions.filter(
      (action: any) => action.ObjectStatusID === ObjectStatus.Negative,
    );

    // Order Results
    filteredActions = filteredActions.sort((a: any, b: any) =>
      a.ActionName.localeCompare(b.ActionName, "en", { numeric: true }),
    );

    // Pagination
    paginatedFilteredActions = filteredActions.filter(
      (action: any, index: number) =>
        pageFilter(filters.importantActionsPage, filters.pageSize, index),
    );
  }

  // Actions (Instances) Card Filters and Pagination
  let filteredInstances: any[] = data.instances ? data.instances : [];
  let paginatedFilteredInstances: any[] = [];
  if (filteredInstances.length > 0) {
    // Filter based off lifeCycleFilter
    filteredInstances = filteredInstances.filter((instance: any) =>
      lifeCycleFilter(filters, instance),
    );

    // Pagination
    paginatedFilteredInstances = filteredInstances.filter(
      (instance: any, index: number) =>
        pageFilter(filters.actionsPage, filters.pageSize, index),
    );
  }

  return (
    <LegacyScreenContainer pageTitle="Action Dashboard" breadcrumbs={[]}>
      <>
        {pageStatus === "Ready" || pageStatus === "Fetching" ? (
          data.actions || data.instances ? (
            <>
              {/* <Header
              breadcrumbs={[
                { title: "Home", link: "/" },
                { title: "Action Dashboard" },
              ]}
              title="Action Dashboard"
              buttonProps={{}}
            /> */}
              <Card
                title="Important Actions"
                collapsable={false}
                headerColsRight
                headerCols={[
                  {
                    colProps: { sm: "auto" },
                    children: (
                      <NewObjectTypeButton
                        variant="primary"
                        title="Create New Action"
                        header="New Action"
                        objectTypeGroupID={ObjectTypeGroup.Action}
                        type={1}
                        parentType={ObjectTypeGroup.Action}
                      />
                    ),
                  },
                ]}
              >
                <Table responsive borderless>
                  <thead>
                    <tr>
                      <th />
                      <th>Name</th>
                      <th>Action Type</th>
                      <th>Status</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.actions && paginatedFilteredActions.length > 0 ? (
                      paginatedFilteredActions.map(
                        (action: any, index: number) => {
                          const IconType: keyof typeof Feather =
                            action.ObjectTypeIcon;
                          const Icon = Feather[IconType];
                          return (
                            <tr key={index}>
                              <td>
                                <Icon />
                              </td>
                              {action.ActionName.length > 25 ? (
                                <td style={{ whiteSpace: "nowrap" }}>
                                  <OverlayTrigger
                                    placement="right-end"
                                    overlay={
                                      <Tooltip id={action.ActionName}>
                                        {action.ActionReadableID} -{" "}
                                        {action.ActionName}
                                      </Tooltip>
                                    }
                                  >
                                    <Link
                                      to={`/action/${action.ActionID}?display=actions`}
                                    >
                                      {action.ActionReadableID} -{" "}
                                      {text_truncate(action.ActionName, 25)}
                                    </Link>
                                  </OverlayTrigger>
                                </td>
                              ) : (
                                <td>
                                  <Link
                                    to={`/action/${action.ActionID}?display=actions`}
                                  >
                                    {action.ActionReadableID} -{" "}
                                    {action.ActionName}
                                  </Link>
                                </td>
                              )}
                              <td>{action.ObjectTypeName}</td>
                              <td>
                                <Status
                                  placement="top"
                                  variant={objectStatusToVariant(
                                    action.ObjectStatusID,
                                  )}
                                  text={action.ObjectStatusName}
                                  tooltip={action.ObjectStatusDescription}
                                />
                              </td>
                              {action.ActionDescription.length > 25 ? (
                                <OverlayTrigger
                                  placement="left"
                                  overlay={
                                    <Tooltip id={action.ActionDescription}>
                                      <Textarea
                                        value={action.ActionDescription}
                                        name={`ActionDescription${index}`}
                                        readOnly
                                        richText
                                      />
                                    </Tooltip>
                                  }
                                >
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    <Textarea
                                      value={action.ActionDescription.replace(
                                        /(<([^>]+)>)/gi,
                                        "",
                                      )
                                        .substring(0, 22)
                                        .concat("...")}
                                      name={`ActionDescription${index}`}
                                      readOnly
                                      richText
                                    />
                                  </td>
                                </OverlayTrigger>
                              ) : (
                                <td>
                                  <Textarea
                                    value={action.ActionDescription}
                                    name={`ActionDescription${index}`}
                                    readOnly
                                    richText
                                  />
                                </td>
                              )}
                            </tr>
                          );
                        },
                      )
                    ) : data.actions ? (
                      <tr>
                        <td />
                        <td colSpan={4}>No Negative Actions</td>
                      </tr>
                    ) : null}
                  </tbody>
                </Table>
                {data.fetchingActions ? (
                  <Row className="justify-content-sm-center">
                    <Col sm="auto">
                      <Loading size={"md"} />
                    </Col>
                  </Row>
                ) : (
                  <div />
                )}
                <Row className="justify-content-sm-center">
                  <Col sm="auto">
                    <Paginator
                      filters={filters}
                      setFilterDispatch={setFilters}
                      allPaginatedRecords={filteredActions}
                      pageKey="importantActionsPage"
                    />
                  </Col>
                </Row>
              </Card>
              <Card
                title="Tasks"
                collapsable={false}
                headerColsRight
                headerCols={[
                  {
                    colProps: { sm: "auto", style: { paddingTop: "5px" } },
                    children: (
                      <Form.Check
                        custom
                        inline
                        label="Open Responded"
                        type="checkbox"
                        id="checkbox-openResponded"
                        checked={filters.openResponded}
                        onChange={() =>
                          setFilters({
                            ...filters,
                            openResponded: !filters.openResponded,
                            actionsPage: 1,
                          })
                        }
                      />
                    ),
                  },
                  {
                    colProps: { sm: "auto", style: { paddingTop: "5px" } },
                    children: (
                      <Form.Check
                        custom
                        inline
                        label="Upcoming"
                        type="checkbox"
                        id="checkbox-upcoming"
                        checked={filters.upcoming}
                        onChange={() =>
                          setFilters({
                            ...filters,
                            upcoming: !filters.upcoming,
                            actionsPage: 1,
                          })
                        }
                      />
                    ),
                  },
                  {
                    colProps: { sm: "auto", style: { paddingTop: "5px" } },
                    children: (
                      <Form.Check
                        custom
                        inline
                        label="Due"
                        type="checkbox"
                        id="checkbox-due"
                        checked={filters.due}
                        onChange={() =>
                          setFilters({
                            ...filters,
                            due: !filters.due,
                            actionsPage: 1,
                          })
                        }
                      />
                    ),
                  },
                  {
                    colProps: { sm: "auto", style: { paddingTop: "5px" } },
                    children: (
                      <Form.Check
                        custom
                        inline
                        label="Overdue"
                        type="checkbox"
                        id="checkbox-overdue"
                        checked={filters.overdue}
                        onChange={() =>
                          setFilters({
                            ...filters,
                            overdue: !filters.overdue,
                            actionsPage: 1,
                          })
                        }
                      />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col>
                    <Table responsive borderless>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Action Type</th>
                          <th>Action Name</th>
                          <th>Lifecycle</th>
                          <th>Person Responsible</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.instances &&
                        paginatedFilteredInstances.length > 0 ? (
                          paginatedFilteredInstances.map(
                            (instance: any, index: any) => (
                              <tr key={index}>
                                <td>
                                  <OverlayTrigger
                                    placement="right"
                                    overlay={
                                      <Tooltip id={instance.InstanceID}>
                                        {formatDateTime({
                                          date: instance.InstanceWhenTs,
                                          format: "DateAndTimeAndTimezone",
                                        })}
                                      </Tooltip>
                                    }
                                  >
                                    <Link
                                      to={`/instance/${instance.InstanceID}?display=actions`}
                                    >
                                      {formatDateTime({
                                        date: instance.InstanceWhenTs,
                                        format: "Date",
                                      })}
                                    </Link>
                                  </OverlayTrigger>
                                </td>
                                <td>{instance.ActionTypeName}</td>
                                {instance.ActionName.length > 25 ? (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id={instance.ActionName}>
                                        {instance.ActionName}
                                      </Tooltip>
                                    }
                                  >
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      {text_truncate(instance.ActionName, 25)}
                                    </td>
                                  </OverlayTrigger>
                                ) : (
                                  <td>{instance.ActionName}</td>
                                )}
                                <td>
                                  <LifeCycle
                                    placement="top"
                                    lifecycle={instance.LifeCycle}
                                  />
                                </td>
                                <td>{instance.InstanceResponsibleUserName}</td>
                              </tr>
                            ),
                          )
                        ) : data.instances ? (
                          <tr>
                            <td colSpan={5}>No Tasks</td>
                          </tr>
                        ) : null}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                {data.fetchingInstances ? (
                  <Row className="justify-content-sm-center">
                    <Col sm="auto">
                      <Loading size={"md"} />
                    </Col>
                  </Row>
                ) : (
                  <div />
                )}
                <Row className="justify-content-sm-center">
                  <Col sm="auto">
                    <Paginator
                      filters={filters}
                      setFilterDispatch={setFilters}
                      allPaginatedRecords={filteredInstances}
                      pageKey="actionsPage"
                    />
                  </Col>
                </Row>
              </Card>
            </>
          ) : (
            <>
              <div className="progress-spinner" style={{ marginTop: "20%" }}>
                <Loading size={"xl"} />
              </div>
            </>
          )
        ) : (
          <>
            <div className="progress-spinner" style={{ marginTop: "20%" }}>
              <Loading size={"xl"} />
            </div>
          </>
        )}
      </>
    </LegacyScreenContainer>
  );
};

export { ScreensActionDashboard };
