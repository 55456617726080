import { Box, HStack, Text } from "@chakra-ui/react";
import { RiListCheck } from "react-icons/ri";

type Props = {
  items: {
    label: string;
    link: string;
  }[];
  onClick: (index: number) => void;
  activeIndex: number;
};

export default function TableOfContents({
  items,
  onClick,
  activeIndex,
}: Props) {
  const linkHeight = 36;
  const indicatorSize = 10;
  const indicatorOffset = (linkHeight - indicatorSize) / 2;

  const renderedItems = items.map((item, index) => (
    <Box
      as="a"
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        onClick(index);
      }}
      key={item.label}
      display="flex"
      textDecor="none"
      fontSize="sm"
      height={9}
      lineHeight={9}
      borderTopRightRadius="md"
      borderBottomRightRadius="md"
      borderLeftWidth="2px"
      borderLeftStyle="solid"
      _hover={{
        bg: "gray.50",
        textDecor: "none",
      }}
      cursor="pointer"
      fontWeight={activeIndex === index ? "500" : undefined}
      color={activeIndex === index ? "brand.800" : "gray.800"}
      px={4}
      noOfLines={1}>
      {item.label}
    </Box>
  ));

  return (
    <Box pos="relative" height="calc(100vh - 120px)" overflowY="auto" pl="1">
      <HStack align="center" marginBottom="4">
        <RiListCheck style={{ width: 16, height: 16 }} />
        <Text margin="0">Sections</Text>
      </HStack>
      {items.length >= 1 ? (
        <div>
          <Box
            ml="1"
            transition="transform 150ms ease"
            borderWidth="2px"
            background="white"
            borderColor="brand.800"
            borderStyle="solid"
            height={`${indicatorSize}px`}
            width={`${indicatorSize}px`}
            borderRadius={`${indicatorSize}px`}
            pos="absolute"
            left={`${indicatorSize / -2 + 1}px`}
            display={activeIndex < 0 ? "none" : undefined}
            transform={`translateY(${
              activeIndex * linkHeight + indicatorOffset
            }px)`}
          />
          {renderedItems}
        </div>
      ) : (
        <div>
          <Text color="gray.600">Manual is empty...</Text>
        </div>
      )}
    </Box>
  );
}
