import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Collapse,
  Container,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import * as Feather from "react-feather";
import MdiIcon from "@mdi/react";
import { mdiMenu, mdiMenuOpen } from "@mdi/js";
import {
  Button as ChakraButton,
  HStack as ChakraHStack,
} from "@chakra-ui/react";
import { ArrowLeft, Menu } from "react-feather";
import { Link, useLocation, useMatch } from "react-router-dom";
import * as Permissions from "../../../shared/constants/permission";
import * as RecordStatus from "../../../shared/constants/recordStatus";
import * as Access from "../../../utilities/access";
import { formatDateTime } from "../../../utilities/formatDate";
import { getUrlSearchParam } from "../../../utilities/url";
import {  useActiveAppState } from "../../App/AppProvider";
import SidebarLoading from "../../chakra/SidebarLoading";
import { CollapseChevron } from "../CollapseChevron/CollapseChevron";
import {
  ActionsTree,
  AloneActionsTree,
  AppNavData,
  DashboardCategory,
  DashboardCategoryItem,
  InstancesTree,
  NavPathState,
  RequirementsTree,
} from "./sidebar.d";
import SidebarAccount from "./SidebarAccount";
import { Categories, useSidebarState } from "./SidebarStateManager";

type SidebarStatus = "Normal" | "Template";
const initialPathState: NavPathState = {
  itemID: "",
  path: [],
};

const restrictedSettingsCategories: DashboardCategoryItem[] = [
  { title: "Account", link: "/settings/account" },
];

const toProperCase = (s: string) =>
  s
    .replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
    )
    .replace("_", " ");

const riskAndIssueFilter = (
  status: SidebarStatus,
  record: RequirementsTree,
  category: string,
  data: RequirementsTree[],
) => {
  if (status === "Normal") {
    // for reports
    if (
      record.ObjectTypeGroupName === "Report" &&
      category.includes(record.ObjectTypeName)
    ) {
      return true;
    }
    if (record.ObjectTypeID === category && record.ParentIDs.length === 0) {
      if (record.Permission) {
        return true;
      }

      const children: any[] = data.filter((requirement: any) => {
        const IDs = record.ChildIDs.filter((childID: string) => {
          if (childID === requirement.RequirementID) {
            return true;
          }
          return false;
        });
        if (IDs.includes(requirement.RequirementID)) {
          return true;
        }
        return false;
      });

      const authedChildren = children.filter((child: any) => child.Permission);

      if (authedChildren.length > 0 || record.Actions.length > 0) {
        return true;
      }
      return false;
    }
  } else if (status === "Template") {
    return record.ObjectTypeID === category && record.ParentIDs.length === 0;
  }
};

type NavItemProps = {
  id: string;
  level: number;
  text: string[];
  link: string | undefined;
  path: NavPathState;
  icon: any;
  disabled?: boolean;
  children?: React.ReactNodeArray;
  topRef: any;
  updatePageTitle: (title: string) => void;
  updateMobileIsOpen?: (bool: boolean) => void;
  onClick?: () => void;
};

const NavItem = (props: NavItemProps) => {
  const [collapsed, setCollapsed] = useState(true);
  const selectedRef = useRef<HTMLDivElement>(null);
  const isCollapsed = !(
    props.path.path.includes(props.id) ||
    (props.path.itemID === "new" && props.level === 2)
  );
  const IconType: keyof typeof Feather = props.icon;
  const Icon = Feather[IconType];
  const selected = props.id === props.path.itemID && props.level > 1;
  const subTitle = props.text[1] || "";
  const isMobile = window.innerWidth < 768;
  useEffect(() => {
    const setChildDisplay = () => {
      setCollapsed(isCollapsed);
    };
    setChildDisplay();
  }, [isCollapsed]);

  useEffect(() => {
    const scrollToItem = () => {
      if (selected && selectedRef.current) {
        selectedRef.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest",
        });
      }
    };
    if (!isMobile) {
      window.setTimeout(scrollToItem, 500);
    }
  }, [selected, isMobile]);

  return (
    <div ref={selectedRef} className="navItem">
      <span className={selected ? "navItemSelected" : "navItemUnselected"}>
        {React.Children.count(props.children) > 0 ? (
          <CollapseChevron
            collapsed={collapsed}
            updateCollapsed={setCollapsed}
          />
        ) : (
          <span style={{ marginLeft: "16px" }} />
        )}
        <span className={subTitle ? "hasReadableID" : "hasNoId"}>
          {Icon && <Icon className="feather" size="16" />}
          {props.disabled ? (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="tooltip-not-authorised-to-view">
                  You don’t have permission to see the details of this record
                </Tooltip>
              }>
              <div
                className="text-link"
                onClick={() => {
                  if (React.Children.count(props.children) > 0) {
                    setCollapsed(!collapsed);
                  }
                  props.onClick && props.onClick();
                }}>
                {props.text[0]}
              </div>
            </OverlayTrigger>
          ) : props.link && props.link.startsWith("/") ? (
            <Link
              to={props.link}
              onClick={() => {
                props.updatePageTitle(props.text[0]);
                if (props.updateMobileIsOpen) {
                  props.updateMobileIsOpen(false);
                  if (isMobile && props.topRef.current) {
                    props.topRef.current.scrollTo(0, 0);
                  }
                }
                props.onClick && props.onClick();
              }}>
              {props.text[0]}
            </Link>
          ) : props.link ? (
            <a href={props.link} target="_blank" rel="noopener noreferrer">
              {props.text[0]}
            </a>
          ) : (
            <div
              className="text-link"
              onClick={() => {
                props.updatePageTitle(props.text[0]);
                if (props.updateMobileIsOpen) {
                  props.updateMobileIsOpen(false);
                  if (isMobile && props.topRef.current) {
                    props.topRef.current.scrollTo(0, 0);
                  }
                }
                if (React.Children.count(props.children) > 0) {
                  setCollapsed(!collapsed);
                }
                props.onClick && props.onClick();
              }}>
              {props.text[0]}
            </div>
          )}
          {subTitle ? <div className="readableID">{subTitle}</div> : ""}
        </span>
      </span>
      <Collapse in={!collapsed}>
        <div className="children">{props.children}</div>
      </Collapse>
    </div>
  );
};

type TopLevelDisplayProps = {
  data: AppNavData;
  kind: SidebarStatus;
  path: NavPathState;
  type: string;
  topRef: any;
  updatePageTitle: (title: string) => void;
  updateDisplay: (displayType: string) => void;
  updateParentID: (parentID: string) => void;
};

type EasyReportNavItemProps = {
  setMobileOpen?: (bool: boolean) => void;
  setPageTitle: (title: string) => void;
  path: NavPathState;
  topRef: any;
};
const EasyReportNavItem = ({
  topRef,
  setMobileOpen,
  path,
  setPageTitle,
}: EasyReportNavItemProps) => (
  <NavItem
    level={2}
    id="easy-report-timesheet"
    text={["Time Sheet Report"]}
    link="/report/easy/time-sheet?display=reports"
    updatePageTitle={setPageTitle}
    updateMobileIsOpen={setMobileOpen}
    topRef={topRef}
    path={path}
    icon="Clock"
  />
);

const EasyReportAlertHistoryNavItem = ({
  topRef,
  setMobileOpen,
  path,
  setPageTitle,
}: EasyReportNavItemProps) => (
  <NavItem
    level={2}
    id="easy-report-alert-history"
    text={["Alert Right Now History Report"]}
    link="/report/easy/alert-history?display=reports"
    updatePageTitle={setPageTitle}
    updateMobileIsOpen={setMobileOpen}
    topRef={topRef}
    path={path}
    icon="Archive"
  />
);

const TopLevelDisplay = (props: TopLevelDisplayProps) => {
  const { appState } = useActiveAppState();
  const { state } = useSidebarState();
  const { permissions } = appState.app;
  const canAccessTimeSheetReport =
    permissions.REPORTS.READ.ALL && permissions.TIME_NOTES.READ.ALL;
  const canAccessAlertRightNowReport =
    permissions.REPORTS.READ.ALL && permissions.OBJECTS.ISSUE.READ.ALL;

  const selectedDisplayCategories =
    props.type === "Risks"
      ? state.categories.risksCategories
      : props.type === "Issues"
      ? state.categories.issuesCategories
      : props.type === "Jobs"
      ? state.categories.jobsCategories
      : props.type === "Actions"
      ? state.categories.actionsCategories
      : props.type === "Reports"
      ? state.categories.reportsCategories
      : props.type === "Registers"
      ? state.categories.registersCategories
      : state.categories.requirementsCategories;

  if (props.kind === "Normal" && props.type === "Requirements") {
    return (
      <div>
        <div className="navMenu">
          <aside>
            <h3>Requirements</h3>
          </aside>
        </div>
        <div>
          {props.data.Requirements.length > 0 ? (
            props.data.Requirements.filter((req: any) => {
              // Only get the parents for top level
              if (
                req.ParentIDs.length === 0 &&
                req.ObjectTypeGroupName === "Requirement"
              ) {
                // Checking if User is authed to see any of the children
                if (req.Permission) {
                  return true;
                }
                if (req.ChildIDs.length > 0 || req.ActionIDs.length > 0) {
                  const children: any[] = props.data.Requirements.filter(
                    (requirement: any) => {
                      const IDs = req.ChildIDs.filter((childID: string) => {
                        if (childID === requirement.RequirementID) {
                          return true;
                        }
                        return false;
                      });
                      return IDs.includes(requirement.RequirementID);
                    },
                  );
                  const actions: any[] = props.data.Actions.filter(
                    (action: any) => {
                      const IDs = req.ActionIDs.filter((childID: string) => {
                        if (childID === action.ActionID) {
                          return true;
                        }
                        return false;
                      });
                      return IDs.includes(action.ActionID);
                    },
                  );
                  const authedActions = actions.filter(
                    (action: any) => action.Permission,
                  );
                  const authedChildren = children.filter(
                    (child: any) => child.Permission,
                  );

                  if (authedChildren.length > 0 || authedActions.length > 0) {
                    return true;
                  }
                  return false;
                }
              }
            }).map((requirement: any, index: number) => (
              <div key={index}>
                <NavItem
                  level={1}
                  id={requirement.RequirementID}
                  text={[requirement.RequirementName]}
                  link={
                    requirement.Permission
                      ? `/requirement/${requirement.RequirementID}?display=requirements`
                      : undefined
                  }
                  updatePageTitle={props.updatePageTitle}
                  onClick={() => {
                    props.updateDisplay("requirements");
                    props.updateParentID(requirement.RequirementID);
                  }}
                  topRef={props.topRef}
                  path={props.path}
                  icon={requirement.ObjectTypeIcon}
                  disabled={!requirement.Permission}
                />
              </div>
            ))
          ) : (
            <div className="navMenuHeading" />
          )}
        </div>
      </div>
    );
  }
  if (props.type === "Settings") {
    props.updateDisplay("settings");
    return <div />;
  }
  if (
    props.type === "Risks" ||
    props.type === "Issues" ||
    props.type === "Jobs" ||
    props.type === "Registers"
  ) {
    // everything else that is organised by a category first
    return (
      <div>
        <div className="navMenu">
          <aside>
            <h3>{props.type}</h3>
          </aside>
        </div>
        <div>
          {selectedDisplayCategories
            .filter((category: any) => {
              // Only get the parents for top level
              if (category.topLevel) {
                // Checking if User is authed to see any of the children
                if (category.Permission) {
                  return true;
                }
                if (category.children && category.children.length > 0) {
                  let selectedItems: any = [];
                  if (props.type === "Risks") {
                    selectedItems = props.data.Risks;
                  } else if (props.type === "Issues") {
                    selectedItems = props.data.Issues;
                  } else if (props.type === "Jobs") {
                    selectedItems = props.data.Jobs;
                  } else if (props.type === "Registers") {
                    selectedItems = props.data.Registers;
                  }

                  // Return a list of any Authenticated children of the children
                  const authedChildren = category.children.reduce(
                    (children: any[], requirement: any) => [
                      ...children,
                      ...selectedItems.filter(
                        (child: any) =>
                          requirement.ChildIDs.includes(child.RequirementID) &&
                          child.Permission,
                      ),
                    ],
                    [],
                  );

                  // Return a list of any Authenticated children of the children
                  const authedActions = category.children.reduce(
                    (children: any[], requirement: any) => [
                      ...children,
                      ...props.data.Actions.filter(
                        (child: any) =>
                          requirement.ActionIDs.includes(child.ActionID) &&
                          child.Permission,
                      ),
                    ],
                    [],
                  );

                  return authedChildren.length > 0 || authedActions.length > 0;
                }
              }
            })
            .map((item: DashboardCategoryItem) => (
              <div key={item.id}>
                <NavItem
                  level={1}
                  id={
                    item.id
                      ? item.id
                      : item.title.toLowerCase().replace(/\s/g, "")
                  }
                  text={[item.title]}
                  link={item.link}
                  updatePageTitle={props.updatePageTitle}
                  onClick={() => {
                    props.updateParentID(
                      item.id
                        ? item.id
                        : item.title.toLowerCase().replace(/\s/g, ""),
                    );
                    props.updateDisplay(props.type.toLowerCase());
                  }}
                  topRef={props.topRef}
                  path={props.path}
                  icon={item.icon}
                />
              </div>
            ))}
        </div>
      </div>
    );
  }
  if (
    props.type === "Actions" ||
    props.type === "Reports" ||
    (props.kind === "Template" && props.type === "Requirements")
  ) {
    // everything else that is organised by a category first
    return (
      <div>
        <div className="navMenu">
          <aside>
            <h3>{props.type}</h3>
          </aside>
        </div>
        <div>
          {selectedDisplayCategories.map((item: DashboardCategoryItem) => (
            <div key={item.id}>
              <NavItem
                level={1}
                id={
                  item.id
                    ? item.id
                    : item.title.toLowerCase().replace(/\s/g, "")
                }
                text={[item.title]}
                link={item.link}
                updatePageTitle={props.updatePageTitle}
                onClick={() => {
                  props.updateParentID(
                    item.id
                      ? item.id
                      : item.title.toLowerCase().replace(/\s/g, ""),
                  );
                  props.updateDisplay(props.type.toLowerCase());
                }}
                topRef={props.topRef}
                path={props.path}
                icon={item.icon}
              />
            </div>
          ))}
          {props.type === "Reports" && canAccessTimeSheetReport ? (
            <>
              <EasyReportNavItem
                topRef={props.topRef}
                path={props.path}
                setPageTitle={props.updatePageTitle}
              />
            </>
          ) : null}
          {props.type === "Reports" && canAccessAlertRightNowReport ? (
            <EasyReportAlertHistoryNavItem
              topRef={props.topRef}
              path={props.path}
              setPageTitle={props.updatePageTitle}
            />
          ) : null}
        </div>
      </div>
    );
  }
  return <div />;
};

// id = the most specific information we can get from the url, whether uuid, object type/category/filter, etc - this will be the item highlighted in the nav as 'selected'
const deconstructUrlString = (url: string, search: string) => {
  const pathname = url.split("/");
  let type: string = "";
  let id: string = "";
  let filter: string = "";
  let objecttype: string = "";
  let display: string = "";
  let parentID: string = "";
  display = getUrlSearchParam(search, "display") || "";
  parentID = getUrlSearchParam(search, "parentid") || "";
  filter = getUrlSearchParam(search, "filter") || "";
  objecttype = getUrlSearchParam(search, "type") || "";

  if (pathname[1] === "template") {
    type = pathname[2];
    id = pathname[3];
  } else {
    type = pathname[1];
    id = pathname[2];
    if (
      id === "customField" ||
      id === "customfield" ||
      id === "list" ||
      id === "user" ||
      id === "role"
    ) {
      id = `${id.toLowerCase()}s`;
    }
  }

  if (id === "new" && type !== "settings") {
    id = parentID || filter || objecttype;
  } else if (filter) {
    id = filter;
  }

  // if url pattern: domain/action?filter=openresponded etc, set display mode to record type
  // or: special case for stand alone actions in template mode
  if (
    !display &&
    (filter || (!parentID && pathname[1] === "template" && type === "action"))
  ) {
    display = `${type}s`;
  }
  return [type, id, filter, objecttype, display, parentID];
};

const locateNavPath = (
  data: AppNavData,
  recordType: string,
  recordID: string,
  displayMode: string,
  objectType: string,
  parentID: string,
  isNewItem: boolean,
  pageStatus: SidebarStatus,
  isAloneAction: boolean,
  categories: Categories,
) => {
  let topLevelObjects: any;
  let action: any;
  let parent: any;
  let updatedDisplay: string = "";
  let objectTypeName: string = "";
  let selectedData: any[] = [];
  let topLevelItemID: string = ""; // topLevelItemID needs to be at top level (as opposed to parentID) for requirements display
  let pathArray: string[] = [recordID, objectType];

  const findInstanceFromRequirement = (item: RequirementsTree) =>
    item.Actions.find((action: ActionsTree) =>
      action.Instances.find(
        (instance: InstancesTree) => instance.InstanceID === recordID,
      ),
    );

  if (displayMode === "actions" || isAloneAction) {
    selectedData = pageStatus === "Normal" ? data.Actions : data.AloneActions;
  } else if (displayMode === "issues" || recordType === "issue") {
    selectedData = data.Issues;
  } else if (displayMode === "registers" || recordType === "register") {
    selectedData = data.Registers;
  } else if (displayMode === "reports" || recordType === "report") {
    selectedData = data.Reports;
  } else if (displayMode === "jobs" || recordType === "job") {
    selectedData = data.Jobs;
  } else if (displayMode === "risks" || recordType === "risk") {
    selectedData = data.Risks;
  } else {
    // locate parent in either risks, issues, jobs or registers
    if (data.Issues.find((record: any) => record.RequirementID === parentID)) {
      selectedData = data.Issues;
      displayMode = "issues";
    } else if (
      data.Risks.find((record: any) => record.RequirementID === parentID)
    ) {
      selectedData = data.Risks;
      displayMode = "risks";
    } else if (
      data.Registers.find((record: any) => record.RequirementID === parentID)
    ) {
      selectedData = data.Registers;
      displayMode = "registers";
    } else if (
      data.Jobs.find((record: any) => record.RequirementID === parentID)
    ) {
      selectedData = data.Jobs;
      displayMode = "jobs";
    } else {
      // if none of the above then it is a requirement
      selectedData = data.Requirements;
    }
  }

  // when creating new record
  if (isNewItem && recordType !== "settings") {
    recordType =
      recordType === "instance" || isAloneAction ? "action" : "requirement";
    let objectTypeItem = categories.risksCategories.find(
      (item: DashboardCategory) => item.id === objectType,
    );
    if (objectTypeItem) {
      updatedDisplay = "risks";
    } else {
      objectTypeItem = categories.issuesCategories.find(
        (item: DashboardCategory) => item.id === objectType,
      );
      if (objectTypeItem) {
        updatedDisplay = "issues";
      } else {
        objectTypeItem = categories.requirementsCategories.find(
          (item: DashboardCategory) => item.id === objectType,
        );
        if (objectTypeItem) {
          updatedDisplay = "requirements";
        } else {
          objectTypeItem = categories.actionsCategories.find(
            (item: DashboardCategory) => item.id === objectType,
          );
          if (objectTypeItem) {
            updatedDisplay = "actions";
          } else {
            objectTypeItem = categories.jobsCategories.find(
              (item: DashboardCategory) => item.id === objectType,
            );
            if (objectTypeItem) {
              updatedDisplay = "jobs";
            } else {
              objectTypeItem = categories.reportsCategories.find(
                (item: DashboardCategory) => item.id === objectType,
              );
              if (objectTypeItem) {
                updatedDisplay = "reports";
              } else {
                objectTypeItem = categories.registersCategories.find(
                  (item: DashboardCategory) => item.id === objectType,
                );
                if (objectTypeItem) {
                  updatedDisplay = "registers";
                }
              }
            }
          }
        }
      }
    }
    if (objectTypeItem) {
      objectTypeName = objectTypeItem.title.toLowerCase().replace(/\s/g, "");
      pathArray.push(objectTypeName);
      parentID = objectTypeName;
    }
  }

  // if we know the context of the record (displayMode), use that to narrow the search, otherwise search through all data

  if (recordType === "settings") {
    topLevelObjects =
      categories.settingsCategories.find(
        (category: DashboardCategoryItem) =>
          category.children &&
          category.children.find(
            (child: DashboardCategoryItem) =>
              child.title.toLowerCase().replace(/\s/g, "") === recordID,
          ),
      ) || "";
    if (topLevelObjects) {
      pathArray.push(topLevelObjects.title.toLowerCase().replace(/\s/g, ""));
    }
  } else if (recordType === "instance") {
    // actions display in normal mode
    if (displayMode === "actions") {
      topLevelObjects = selectedData.find(
        (item: InstancesTree) => item.InstanceID === recordID,
      );
      if (topLevelObjects) {
        pathArray.push(
          topLevelObjects.LifeCycle.toLowerCase().replace(/\s/g, ""),
        );
      }
    }
    // any other display in normal mode (no instances in template mode)
    else {
      topLevelObjects =
        selectedData.filter((item: RequirementsTree) =>
          findInstanceFromRequirement(item),
        ) ||
        data.Requirements.filter((item: RequirementsTree) =>
          findInstanceFromRequirement(item),
        ) ||
        data.Issues.filter((item: RequirementsTree) =>
          findInstanceFromRequirement(item),
        ) ||
        data.Jobs.filter((item: RequirementsTree) =>
          findInstanceFromRequirement(item),
        ) ||
        data.Risks.filter((item: RequirementsTree) =>
          findInstanceFromRequirement(item),
        ) ||
        data.Registers.filter((item: RequirementsTree) =>
          findInstanceFromRequirement(item),
        );
      // ||
      // data.Reports.find((item: RequirementsTree) => findInstanceFromRequirement(item))

      topLevelObjects.forEach((item: RequirementsTree) => {
        action = item.Actions.find((action: ActionsTree) =>
          action.Instances.find(
            (instance: InstancesTree) => instance.InstanceID === recordID,
          ),
        );
        if (action) {
          pathArray.push(action.ActionID);
        }
      });
    }
  } else if (recordType === "action") {
    // actions display in template mode
    if (displayMode === "actions" || isAloneAction) {
      topLevelObjects = selectedData.find(
        (action: AloneActionsTree) => action.ActionID === recordID,
      );
      if (topLevelObjects) {
        pathArray.push(topLevelObjects.ObjectTypeID);
      }
    }
    // any other display, any mode
    else {
      topLevelObjects =
        selectedData.filter((item: any) => item.ActionIDs.includes(recordID)) ||
        data.Requirements.filter((item: any) =>
          item.ActionIDs.includes(recordID),
        ) ||
        data.Issues.filter((item: any) => item.ActionIDs.includes(recordID)) ||
        data.Jobs.filter((item: any) => item.ActionIDs.includes(recordID)) ||
        data.Risks.filter((item: any) => item.ActionIDs.includes(recordID)) ||
        data.Registers.filter((item: any) => item.ActionIDs.includes(recordID));
    }
  } else if (
    recordType === "risk" ||
    recordType === "issue" ||
    recordType === "register" ||
    recordType === "requirement" ||
    recordType === "job" ||
    recordType === "report"
  ) {
    topLevelObjects = [
      selectedData.find(
        (item: RequirementsTree) => item.RequirementID === recordID,
      ),
      data.Requirements.find(
        (item: RequirementsTree) => item.RequirementID === recordID,
      ),
      data.Issues.find(
        (item: RequirementsTree) => item.RequirementID === recordID,
      ),
      data.Jobs.find(
        (item: RequirementsTree) => item.RequirementID === recordID,
      ),
      data.Risks.find(
        (item: RequirementsTree) => item.RequirementID === recordID,
      ),
      data.Reports.find(
        (item: RequirementsTree) => item.RequirementID === recordID,
      ),
      data.Registers.find(
        (item: RequirementsTree) => item.RequirementID === recordID,
      ),
    ];
  }

  // if the tree includes a requirement/issue/risk, check if it has a parent
  if (
    topLevelObjects &&
    topLevelObjects.length > 0 &&
    topLevelObjects[0] &&
    topLevelObjects[0].RequirementID
  ) {
    topLevelObjects.forEach((topLevelObject: RequirementsTree) => {
      if (topLevelObject) {
        pathArray.push(topLevelObject.ObjectTypeID);
        pathArray = pathArray.concat(topLevelObject.ParentIDs);

        // Get all of the parents...
        parent =
          selectedData.filter((item: RequirementsTree) =>
            item.ChildIDs.includes(topLevelObject.RequirementID),
          ) ||
          data.Requirements.find((item: RequirementsTree) =>
            item.ChildIDs.includes(topLevelObject.RequirementID),
          ) ||
          data.Issues.find((item: RequirementsTree) =>
            item.ChildIDs.includes(topLevelObject.RequirementID),
          ) ||
          data.Jobs.find((item: RequirementsTree) =>
            item.ChildIDs.includes(topLevelObject.RequirementID),
          ) ||
          data.Risks.find((item: RequirementsTree) =>
            item.ChildIDs.includes(topLevelObject.RequirementID),
          ) ||
          data.Registers.find((item: RequirementsTree) =>
            item.ChildIDs.includes(topLevelObject.RequirementID),
          );

        const parentFromUrl = selectedData.find(
          (item: RequirementsTree) => item.RequirementID === parentID,
        );

        // Add each parent to the path (for sub-requirements)
        if (parent.length > 0) {
          parent.forEach((parentItem: any) => {
            pathArray.push(parentItem.RequirementID);
            pathArray.push(parentItem.ObjectTypeID);
            // for multi parent, use parent id from url if available && if it is the top level, otherwise use parent from array
            if (
              !parentFromUrl ||
              (parentFromUrl && parentFromUrl.ParentIDs.length > 0)
            ) {
              parentID = parentItem.RequirementID;
              topLevelItemID = parentItem.RequirementID;
            } else {
              topLevelItemID = parentFromUrl.RequirementID;
            }
          });

          // For top level requirements
        } else {
          // reports are specialcase so they need the reports part added
          if (topLevelObject.ObjectTypeGroupName === "Report") {
            pathArray.push(topLevelObject.ObjectTypeID);
            parentID = topLevelObject.RequirementID;
          } else {
            pathArray.push(topLevelObject.ObjectTypeID);
            parentID = parentID || topLevelObject.RequirementID;
            topLevelItemID = parentFromUrl
              ? parentID
              : topLevelObject.RequirementID;
          }
        }
        pathArray.push(topLevelObject.RequirementID);
      }
    });

    updatedDisplay = `${topLevelObjects[0].ObjectTypeGroupName.toLowerCase()}s`;
  }

  return { path: pathArray, parentID, display: updatedDisplay, topLevelItemID };
};

type SidebarProps = {
  onSignOut: () => void;
  closeSidebar: () => void;
  openSidebar: () => void;
  mobileIsOpen: boolean;
  setMobileOpen: () => void;
  isInDrawer?: boolean;
};

const Sidebar = (props: SidebarProps) => {
  console.log("sidebar render");
  const appContext = useActiveAppState();
  const sidebarState = useSidebarState();
  const pageStatus = useMatch("/template/*") !== null ? "Template" : "Normal";
  const { navData: data, categories, status } = sidebarState.state;
  const {
    dashboardCategories,
    jobsCategories,
    risksCategories,
    issuesCategories,
    actionsCategories,
    settingsCategories,
    reportsCategories,
    requirementsCategories,
    registersCategories,
  } = categories;
  const { appState, setPageTitle } = appContext;
  const { permissions } = appState.app;
  const canAccessTimeSheetReport =
    permissions.REPORTS.READ.ALL && permissions.TIME_NOTES.READ.ALL;
  const canAccessAlertRightNowReport = permissions.REPORTS.READ.ALL;
  const location = useLocation();
  const initialDisplay =
    location.state && location.state.display ? location.state.display : "main";
  const [open, setOpen] = useState(true);

  const [parentID, updateParentID] = useState("");
  const [display, updateDisplay] = useState(initialDisplay);
  const [path, updatePath] = useState(initialPathState);
  const [itemType, updateItemType] = useState("");
  const topRef = useRef(null);

  const getUrl = () => {
    const [recordType, recordID, filter, objectType, displayMode, parentID] =
      deconstructUrlString(location.pathname, location.search);
    let navPath: any = initialPathState;
    if (recordType && recordID && !filter) {
      // if we have opened the tree, trace back the open path
      navPath = locateNavPath(
        data,
        recordType,
        recordID,
        displayMode,
        objectType,
        parentID,
        location.pathname.includes("new"),
        pageStatus,
        pageStatus === "Template" &&
          getUrlSearchParam(location.search, "parentid") === null &&
          displayMode === "actions",
        categories,
      );
      updateParentID(navPath.topLevelItemID || navPath.parentID); // this needs to be very top level parent id for requirements
      updateDisplay(
        recordType === "settings"
          ? recordType
          : displayMode || (navPath.display ? navPath.display : "main"),
      );
    } else if (recordType && recordID) {
      // else we have selected a category/filter
      navPath.path = [];
      navPath.path.push(recordID);
      updateDisplay(
        recordType === "settings" ? recordType : displayMode || "requirements",
      );
    } else if (recordType) {
      // else we have just selected an item type
      updateDisplay("main");
      updateItemType(`${toProperCase(recordType)}s`);
    } else {
      updateDisplay("main");
    }

    navPath.path.push(parentID);
    navPath.path.push(filter);

    updatePath({
      itemID:
        recordType !== "settings" &&
        getUrlSearchParam(location.search, "type") &&
        recordID !== parentID
          ? navPath.parentID
          : recordID, // highlight objecttype navitem when creating new top level item
      path: navPath.path,
    });
  };

  useEffect(() => {
    if (
      data.Requirements.length > 0 ||
      data.Issues.length > 0 ||
      data.Registers.length > 0 ||
      data.Jobs.length > 0 ||
      data.AloneActions.length > 0 ||
      data.Actions.length > 0 ||
      data.Risks.length > 0
    ) {
      getUrl();
    }
  }, [
    location.pathname,
    location.search,
    data.Requirements,
    data.Issues,
    data.Registers,
    data.Jobs,
    data.Actions,
    data.Risks,
    data.AloneActions,
  ]); // eslint-disable-line

  console.log("sidebar status: ", { status, open, display });
  if (appState.auth.isLoggedIn) {
    if (open && status === "fetching") {
      return (
        <Container
          as={Col}
          ref={topRef}
          className={`sideNav sideNavOpen ${
            pageStatus === "Template" ? "sideNavTemplate" : ""
          }${!props.isInDrawer ? "fullSize" : ""}`}>
          <div className="sideNavContainer">
            <SidebarLoading />
          </div>
        </Container>
      );
    }
    if (open && display === "main") {
      return (
        <Container
          as={Col}
          ref={topRef}
          className={`sideNav sideNavOpen ${
            pageStatus === "Template" ? "sideNavTemplate" : ""
          }${!props.isInDrawer ? "fullSize" : ""}`}>
          <div className="sideNavContainer">
            <div className="row" style={{ justifyContent: "flex-end" }}>
              {/* <Col xs="8" sm="8" md="8" className="sideNavLogo">
                {data.AccountData ? (
                  data.AccountData.AccountLogoPath ? (
                    <>
                      <Row>
                        <Col>
                          <Link to="/">
                            <img
                              src={data.AccountData.AccountLogoPath}
                              alt="logo"
                              style={{ width: "100%" }}
                            />
                          </Link>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <span
                            style={{
                              fontSize: "smaller",
                              paddingLeft: "15px",
                            }}>
                            Powered by CertCrowd
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <Link to="/">
                      <img
                        src={Logo}
                        alt="logo"
                        style={{
                          width: "180px",
                          height: "30px",
                          paddingLeft: "10px",
                        }}
                      />
                    </Link>
                  )
                ) : null}
              </Col> */}
              <Col md="auto" className="d-none d-sm-none d-md-block">
                <chakra-scope>
                  <ChakraButton
                    variant="ghost"
                    onClick={() => {
                      setOpen(false);
                      props.closeSidebar();
                    }}>
                    <MdiIcon size={1} path={mdiMenuOpen} />
                  </ChakraButton>
                </chakra-scope>
              </Col>
              <Col xs="3" sm="3" className="d-block d-sm-block d-md-none">
                <chakra-scope>
                  <ChakraButton
                    variant="ghost"
                    onClick={() => {
                      props.closeSidebar();
                    }}>
                    <MdiIcon size={1} path={mdiMenuOpen} />
                  </ChakraButton>
                </chakra-scope>
              </Col>
            </div>
            {pageStatus === "Template" ? (
              <>
                <div
                  style={{ display: "flex", justifyContent: "center" }}
                  className="row">
                  <h5>Templates</h5>
                </div>
                <div className="row">
                  <div className="navMenu col">
                    {dashboardCategories.map((item: any, index: number) => (
                      <div key={index}>
                        <NavItem
                          level={1}
                          id={item.title.toLowerCase()}
                          text={[item.title]}
                          link={item.link}
                          updatePageTitle={setPageTitle}
                          updateMobileIsOpen={props.setMobileOpen}
                          topRef={topRef}
                          onClick={() => {
                            updateItemType(item.title);
                          }}
                          path={path}
                          icon={item.icon}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : null}

            <div className="row" style={{ flex: "1 1 auto" }}>
              <div className="navMenu col">
                <TopLevelDisplay
                  type={itemType}
                  data={data}
                  updatePageTitle={setPageTitle}
                  updateDisplay={updateDisplay}
                  updateParentID={updateParentID}
                  topRef={topRef}
                  path={path}
                  kind={pageStatus}
                />
              </div>
            </div>
          </div>
        </Container>
      );
    }
    if (open && pageStatus === "Normal" && display === "requirements") {
      const parentRequirement: any = data.Requirements.find(
        (req: RequirementsTree) => req.RequirementID === parentID,
      );
      return (
        <Container
          as={Col}
          ref={topRef}
          className={`sideNav sideNavOpen ${
            pageStatus === "Template" ? "sideNavTemplate" : ""
          }${!props.isInDrawer ? "fullSize" : ""}`}>
          <Row>
            <Col xs="3">
              <Button
                className="sideNavBackButton"
                variant="light"
                size="sm"
                onClick={() => {
                  updateDisplay("main");
                  updateItemType("Requirements");
                  updateParentID("");
                  props.setMobileOpen(false);
                }}>
                <ArrowLeft size="16" />
              </Button>
            </Col>
            <Col className="navMenuHeading">
              <h3>Back to Requirements</h3>
            </Col>
            <Col xs={{ span: "3" }} className="d-block d-md-none">
              <chakra-scope>
                <ChakraHStack w="full" justifyContent="center">
                  <ChakraButton
                    variant="ghost"
                    onClick={() => {
                      props.closeSidebar();
                    }}>
                    <MdiIcon size={1} path={mdiMenuOpen} />
                  </ChakraButton>
                </ChakraHStack>
              </chakra-scope>
            </Col>
          </Row>
          <Row>
            <div className="navMenu col">
              {data.Requirements.length > 0 && parentRequirement && (
                <div>
                  <NavItem
                    level={2}
                    id={parentRequirement.RequirementID}
                    text={[
                      parentRequirement.RequirementName,
                      parentRequirement.RequirementReadableID,
                    ]}
                    link={
                      parentRequirement.Permission
                        ? `/requirement/${parentRequirement.RequirementID}?display=requirements`
                        : undefined
                    }
                    updatePageTitle={setPageTitle}
                    updateMobileIsOpen={props.setMobileOpen}
                    topRef={topRef}
                    path={path}
                    icon={parentRequirement.ObjectTypeIcon}
                    disabled={!parentRequirement.Permission}>
                    {parentRequirement.Actions.sort((a: any, b: any) =>
                      a.ActionName.localeCompare(b.ActionName, "en", {
                        numeric: true,
                      }),
                    ).map((action: ActionsTree, index: number) => (
                      <div key={index}>
                        <NavItem
                          level={4}
                          id={action.ActionID}
                          text={[action.ActionName, action.ActionReadableID]}
                          link={`/action/${action.ActionID}?display=requirements&parentid=${parentRequirement.RequirementID}`}
                          updatePageTitle={setPageTitle}
                          updateMobileIsOpen={props.setMobileOpen}
                          topRef={topRef}
                          path={path}
                          icon={action.ObjectTypeIcon}>
                          {action.Instances.map(
                            (instance: InstancesTree, index: number) => (
                              <div key={index}>
                                <NavItem
                                  level={5}
                                  id={instance.InstanceID}
                                  text={[
                                    formatDateTime({
                                      date: instance.InstanceDate,
                                      format: "Date",
                                    }),
                                  ]}
                                  link={`/instance/${instance.InstanceID}?display=requirements`}
                                  updatePageTitle={setPageTitle}
                                  updateMobileIsOpen={props.setMobileOpen}
                                  topRef={topRef}
                                  path={path}
                                  icon={instance.ObjectTypeIcon}
                                />
                              </div>
                            ),
                          )}
                        </NavItem>
                      </div>
                    ))}
                    {data.Requirements.filter(
                      (req: RequirementsTree) =>
                        req.ParentIDs.includes(
                          parentRequirement.RequirementID,
                        ) && req.Permission,
                    )
                      .sort((a, b) =>
                        a.RequirementName.localeCompare(
                          b.RequirementName,
                          "en",
                          { numeric: true },
                        ),
                      )
                      .map((child: RequirementsTree, index: number) => (
                        <div key={index}>
                          <NavItem
                            level={3}
                            id={child.RequirementID}
                            text={[
                              child.RequirementName,
                              child.RequirementReadableID,
                            ]}
                            link={
                              child.Permission
                                ? `/requirement/${child.RequirementID}?display=requirements&parentid=${parentRequirement.RequirementID}`
                                : undefined
                            }
                            updatePageTitle={setPageTitle}
                            updateMobileIsOpen={props.setMobileOpen}
                            topRef={topRef}
                            path={path}
                            icon={child.ObjectTypeIcon}
                            disabled={!child.Permission}>
                            {child.Actions.sort((a, b) =>
                              a.ActionName.localeCompare(b.ActionName, "en", {
                                numeric: true,
                              }),
                            ).map((action: ActionsTree, index: number) => (
                              <div key={index}>
                                <NavItem
                                  level={4}
                                  id={action.ActionID}
                                  text={[
                                    action.ActionName,
                                    action.ActionReadableID,
                                  ]}
                                  link={`/action/${action.ActionID}?display=requirements&parentid=${child.RequirementID}`}
                                  updatePageTitle={setPageTitle}
                                  updateMobileIsOpen={props.setMobileOpen}
                                  topRef={topRef}
                                  path={path}
                                  icon={action.ObjectTypeIcon}>
                                  {action.Instances.map(
                                    (
                                      instance: InstancesTree,
                                      index: number,
                                    ) => (
                                      <div key={index}>
                                        <NavItem
                                          level={5}
                                          id={instance.InstanceID}
                                          text={[
                                            formatDateTime({
                                              date: instance.InstanceDate,
                                              format: "Date",
                                            }),
                                          ]}
                                          link={`/instance/${instance.InstanceID}?display=requirements`}
                                          updatePageTitle={setPageTitle}
                                          updateMobileIsOpen={
                                            props.setMobileOpen
                                          }
                                          topRef={topRef}
                                          path={path}
                                          icon={instance.ObjectTypeIcon}
                                        />
                                      </div>
                                    ),
                                  )}
                                </NavItem>
                              </div>
                            ))}
                          </NavItem>
                        </div>
                      ))}
                  </NavItem>
                </div>
              )}
            </div>
          </Row>
        </Container>
      );
    }
    if (open && pageStatus === "Normal" && display === "actions") {
      return (
        <Container
          as={Col}
          ref={topRef}
          className={`sideNav sideNavOpen ${
            pageStatus === "Template" ? "sideNavTemplate" : ""
          }${!props.isInDrawer ? "fullSize" : ""}`}>
          <Row>
            <Col xs="3">
              <Button
                className="sideNavBackButton"
                variant="light"
                size="sm"
                onClick={() => {
                  updateDisplay("main");
                  updateItemType("Actions");
                  props.setMobileOpen(false);
                }}>
                <ArrowLeft size="16" />
              </Button>
            </Col>
            <Col className="navMenuHeading">
              <h3>Back to Actions</h3>
            </Col>
            <Col xs={{ span: "3" }} className="d-block d-md-none">
              <chakra-scope>
                <ChakraHStack w="full" justifyContent="center">
                  <ChakraButton
                    variant="ghost"
                    onClick={() => {
                      props.closeSidebar();
                    }}>
                    <MdiIcon size={1} path={mdiMenuOpen} />
                  </ChakraButton>
                </ChakraHStack>
              </chakra-scope>
            </Col>
          </Row>
          <Row>
            <div className="navMenu col">
              {actionsCategories.map(
                (item: DashboardCategory, index: number) => (
                  <div key={index}>
                    <NavItem
                      level={2}
                      id={item.title.toLowerCase().replace(" ", "")}
                      text={[item.title]}
                      link={item.link}
                      updatePageTitle={setPageTitle}
                      updateMobileIsOpen={props.setMobileOpen}
                      topRef={topRef}
                      path={path}
                      icon="Edit">
                      {data &&
                        data.Actions.filter(
                          (instance: InstancesTree) =>
                            instance.LifeCycle === item.title &&
                            instance.Permission,
                        ).map(
                          (
                            categorisedInstance: InstancesTree,
                            index: number,
                          ) => (
                            <div key={index}>
                              <NavItem
                                level={3}
                                id={categorisedInstance.InstanceID}
                                text={[
                                  categorisedInstance.ActionName,
                                  formatDateTime({
                                    date: categorisedInstance.InstanceDate,
                                    format: "DateAndTimeIfNotMidnight",
                                  }),
                                ]}
                                link={`/instance/${categorisedInstance.InstanceID}?display=actions`}
                                updatePageTitle={setPageTitle}
                                updateMobileIsOpen={props.setMobileOpen}
                                topRef={topRef}
                                path={path}
                                icon={categorisedInstance.ObjectTypeIcon}
                              />
                            </div>
                          ),
                        )}
                    </NavItem>
                  </div>
                ),
              )}
            </div>
          </Row>
        </Container>
      );
    }
    // anything that is organised by object type can go here
    if (
      (open && display === "issues") ||
      display === "jobs" ||
      display === "risks" ||
      display === "registers"
    ) {
      const selectedObjectType = display.substring(0, display.length - 1);
      const displayMode = `${selectedObjectType}s`;
      let selectedCategories: DashboardCategory[] = [];
      let selectedData: RequirementsTree[] = [];
      if (display === "issues") {
        selectedCategories = issuesCategories;
        selectedData = data.Issues;
      } else if (display === "jobs") {
        selectedCategories = jobsCategories;
        selectedData = data.Jobs;
      } else if (display === "risks") {
        selectedCategories = risksCategories.filter((cat: any) => cat.topLevel);
        selectedData = data.Risks;
      } else if (display === "registers") {
        selectedCategories = registersCategories;
        selectedData = data.Registers;
      }

      return (
        <Container
          as={Col}
          ref={topRef}
          className={`sideNav sideNavOpen ${
            pageStatus === "Template" ? "sideNavTemplate" : ""
          }${!props.isInDrawer ? "fullSize" : ""}`}>
          <Row>
            <Col xs="3">
              <Button
                className="sideNavBackButton"
                variant="light"
                size="sm"
                onClick={() => {
                  updateDisplay("main");
                  updateItemType(toProperCase(display));
                  props.setMobileOpen(false);
                }}>
                <ArrowLeft size="16" />
              </Button>
            </Col>
            <Col className="navMenuHeading">
              <h3>Back to {toProperCase(display)}</h3>
            </Col>
            <Col xs={{ span: "3" }} className="d-block d-md-none">
              <chakra-scope>
                <ChakraHStack w="full" justifyContent="center">
                  <ChakraButton
                    variant="ghost"
                    onClick={() => {
                      props.closeSidebar();
                    }}>
                    <MdiIcon size={1} path={mdiMenuOpen} />
                  </ChakraButton>
                </ChakraHStack>
              </chakra-scope>
            </Col>
          </Row>
          {pageStatus === "Template" ? (
            <>
              <div
                style={{ display: "flex", justifyContent: "center" }}
                className="row">
                <h5>Templates</h5>
              </div>
            </>
          ) : null}
          <Row>
            <div className="navMenu col">
              {selectedCategories
                .filter((category: any) => {
                  // Only get the parents for top level
                  if (category.topLevel) {
                    // Checking if User is authed to see any of the children
                    if (category.Permission) {
                      return true;
                    }
                    if (category.children.length > 0) {
                      // Return a list of any Authenticated children of the children
                      const authedChildren = category.children.reduce(
                        (children: any[], requirement: any) => [
                          ...children,
                          ...selectedData.filter(
                            (child: any) =>
                              requirement.ChildIDs.includes(
                                child.RequirementID,
                              ) && child.Permission,
                          ),
                        ],
                        [],
                      );

                      // Return a list of any Authenticated children of the children
                      const authedActions = category.children.reduce(
                        (children: any[], requirement: any) => [
                          ...children,
                          ...data.Actions.filter(
                            (child: any) =>
                              requirement.ActionIDs.includes(child.ActionID) &&
                              child.Permission,
                          ),
                        ],
                        [],
                      );

                      return (
                        authedChildren.length > 0 || authedActions.length > 0
                      );
                    }
                  }
                })
                .map((item: DashboardCategory, index: number) => (
                  <div key={index}>
                    <NavItem
                      level={2}
                      id={item.id}
                      text={[item.title]}
                      link={item.link}
                      updatePageTitle={setPageTitle}
                      updateMobileIsOpen={props.setMobileOpen}
                      topRef={topRef}
                      path={path}
                      icon={item.icon}>
                      {selectedData
                        .filter((record: RequirementsTree) =>
                          riskAndIssueFilter(
                            pageStatus,
                            record,
                            item.id,
                            selectedData,
                          ),
                        )
                        .sort((a, b) =>
                          a.RequirementName.localeCompare(
                            b.RequirementName,
                            "en",
                            { numeric: true },
                          ),
                        )
                        .map((item: RequirementsTree, index: number) => (
                          <div key={index}>
                            <NavItem
                              level={3}
                              id={item.RequirementID}
                              text={[
                                item.RequirementName,
                                item.RequirementReadableID,
                              ]}
                              link={
                                item.Permission
                                  ? `${
                                      pageStatus === "Template"
                                        ? "/template"
                                        : ""
                                    }/${selectedObjectType}/${
                                      item.RequirementID
                                    }?display=${displayMode}`
                                  : undefined
                              }
                              updatePageTitle={setPageTitle}
                              updateMobileIsOpen={props.setMobileOpen}
                              topRef={topRef}
                              path={path}
                              icon={item.ObjectTypeIcon}
                              disabled={!item.Permission}>
                              {item.Actions.sort((a, b) =>
                                a.ActionName.localeCompare(b.ActionName, "en", {
                                  numeric: true,
                                }),
                              ).map((action: ActionsTree, index: number) => (
                                <div key={index}>
                                  <NavItem
                                    level={4}
                                    id={action.ActionID}
                                    text={[
                                      action.ActionName,
                                      action.ActionReadableID,
                                    ]}
                                    link={`${
                                      pageStatus === "Template"
                                        ? "/template"
                                        : ""
                                    }/action/${
                                      action.ActionID
                                    }?display=${displayMode}&parentid=${
                                      item.RequirementID
                                    }`}
                                    updatePageTitle={setPageTitle}
                                    updateMobileIsOpen={props.setMobileOpen}
                                    topRef={topRef}
                                    path={path}
                                    icon={action.ObjectTypeIcon}>
                                    {action.Instances &&
                                      action.Instances.map(
                                        (
                                          instance: InstancesTree,
                                          index: number,
                                        ) => (
                                          <div key={index}>
                                            <NavItem
                                              level={5}
                                              id={instance.InstanceID}
                                              text={[
                                                formatDateTime({
                                                  date: instance.InstanceDate,
                                                  format: "Date",
                                                }),
                                              ]}
                                              link={`/instance/${instance.InstanceID}?display=${displayMode}`}
                                              updatePageTitle={setPageTitle}
                                              updateMobileIsOpen={
                                                props.setMobileOpen
                                              }
                                              topRef={topRef}
                                              path={path}
                                              icon={action.ObjectTypeIcon}
                                            />
                                          </div>
                                        ),
                                      )}
                                  </NavItem>
                                </div>
                              ))}
                              {selectedData
                                .filter((record: RequirementsTree) =>
                                  record.ParentIDs.includes(item.RequirementID),
                                )
                                .sort((a, b) =>
                                  a.RequirementName.localeCompare(
                                    b.RequirementName,
                                    "en",
                                    { numeric: true },
                                  ),
                                )
                                .filter((child: any) => child.Permission)
                                .map(
                                  (child: RequirementsTree, index: number) => (
                                    <div key={index}>
                                      <NavItem
                                        level={4}
                                        id={child.RequirementID}
                                        text={[
                                          child.RequirementName,
                                          child.RequirementReadableID,
                                        ]}
                                        link={`${
                                          pageStatus === "Template"
                                            ? "/template"
                                            : ""
                                        }/${selectedObjectType}/${
                                          child.RequirementID
                                        }?display=${displayMode}&parentid=${
                                          item.RequirementID
                                        }`}
                                        updatePageTitle={setPageTitle}
                                        updateMobileIsOpen={props.setMobileOpen}
                                        topRef={topRef}
                                        path={path}
                                        icon={child.ObjectTypeIcon}
                                        disabled={
                                          display === "risks" &&
                                          pageStatus === "Template" &&
                                          child.RecordStatusID ===
                                            RecordStatus.Active
                                            ? true
                                            : !item.Permission
                                        }>
                                        {child.Actions.sort((a, b) =>
                                          a.ActionName.localeCompare(
                                            b.ActionName,
                                            "en",
                                            { numeric: true },
                                          ),
                                        ).map(
                                          (
                                            action: ActionsTree,
                                            index: number,
                                          ) => (
                                            <div key={index}>
                                              <NavItem
                                                level={4}
                                                id={action.ActionID}
                                                text={[
                                                  action.ActionName,
                                                  action.ActionReadableID,
                                                ]}
                                                link={`${
                                                  pageStatus === "Template"
                                                    ? "/template"
                                                    : ""
                                                }/action/${
                                                  action.ActionID
                                                }?display=${displayMode}&parentid=${
                                                  child.RequirementID
                                                }`}
                                                updatePageTitle={setPageTitle}
                                                updateMobileIsOpen={
                                                  props.setMobileOpen
                                                }
                                                topRef={topRef}
                                                path={path}
                                                icon={action.ObjectTypeIcon}>
                                                {action.Instances &&
                                                  action.Instances.map(
                                                    (
                                                      instance: InstancesTree,
                                                      index: number,
                                                    ) => (
                                                      <div key={index}>
                                                        <NavItem
                                                          level={5}
                                                          id={
                                                            instance.InstanceID
                                                          }
                                                          text={[
                                                            formatDateTime({
                                                              date: instance.InstanceDate,
                                                              format: "Date",
                                                            }),
                                                          ]}
                                                          link={`/instance/${instance.InstanceID}?display=${displayMode}`}
                                                          updatePageTitle={
                                                            setPageTitle
                                                          }
                                                          updateMobileIsOpen={
                                                            props.setMobileOpen
                                                          }
                                                          topRef={topRef}
                                                          path={path}
                                                          icon={
                                                            action.ObjectTypeIcon
                                                          }
                                                        />
                                                      </div>
                                                    ),
                                                  )}
                                              </NavItem>
                                            </div>
                                          ),
                                        )}
                                      </NavItem>
                                    </div>
                                  ),
                                )}
                            </NavItem>
                          </div>
                        ))}
                    </NavItem>
                  </div>
                ))}
            </div>
          </Row>
        </Container>
      );
    }
    // anything that is organised by object type can go here
    if (
      (open && display === "reports") ||
      (pageStatus === "Template" && display === "requirements")
    ) {
      const selectedObjectType = display.substring(0, display.length - 1);
      const displayMode =
        display === "requirements" ? "requirements" : `${selectedObjectType}s`;
      let selectedCategories: DashboardCategory[] = [];
      let selectedData: RequirementsTree[] = [];
      if (display === "reports") {
        selectedCategories = reportsCategories;
        selectedData = data.Reports;
      } else if (display === "requirements") {
        selectedCategories = requirementsCategories;
        selectedData = data.Requirements;
      } else if (display === "registers") {
        selectedCategories = registersCategories;
        selectedData = data.Registers;
      }

      return (
        <Container
          as={Col}
          ref={topRef}
          className={`sideNav sideNavOpen ${
            pageStatus === "Template" ? "sideNavTemplate" : ""
          }${!props.isInDrawer ? "fullSize" : ""}`}>
          <Row>
            <Col xs="3">
              <Button
                className="sideNavBackButton"
                variant="light"
                size="sm"
                onClick={() => {
                  updateDisplay("main");
                  updateItemType(toProperCase(display));
                  props.setMobileOpen(false);
                }}>
                <ArrowLeft size="16" />
              </Button>
            </Col>
            <Col className="navMenuHeading">
              <h3>Back to {toProperCase(display)}</h3>
            </Col>
            <Col xs={{ span: "3" }} className="d-block d-md-none">
              <chakra-scope>
                <ChakraHStack w="full" justifyContent="center">
                  <ChakraButton
                    variant="ghost"
                    onClick={() => {
                      props.closeSidebar();
                    }}>
                    <MdiIcon size={1} path={mdiMenuOpen} />
                  </ChakraButton>
                </ChakraHStack>
              </chakra-scope>
            </Col>
          </Row>
          {pageStatus === "Template" ? (
            <>
              <div
                style={{ display: "flex", justifyContent: "center" }}
                className="row">
                <h5>Templates</h5>
              </div>
            </>
          ) : null}
          <Row>
            <div className="navMenu col">
              {selectedCategories.map((item: DashboardCategory) => (
                <div key={item.id}>
                  <NavItem
                    level={2}
                    id={item.id}
                    text={[item.title]}
                    link={item.link}
                    updatePageTitle={setPageTitle}
                    updateMobileIsOpen={props.setMobileOpen}
                    topRef={topRef}
                    path={path}
                    icon={item.icon}>
                    {selectedData
                      .filter((record: RequirementsTree) =>
                        riskAndIssueFilter(
                          pageStatus,
                          record,
                          item.id,
                          selectedData,
                        ),
                      )
                      .sort((a, b) =>
                        a.RequirementName.localeCompare(
                          b.RequirementName,
                          "en",
                          { numeric: true },
                        ),
                      )
                      .map((item: RequirementsTree, index: number) => (
                        <div key={index}>
                          <NavItem
                            level={3}
                            id={item.RequirementID}
                            text={[
                              item.RequirementName,
                              item.RequirementReadableID,
                            ]}
                            link={
                              item.Permission
                                ? `${
                                    pageStatus === "Template" ? "/template" : ""
                                  }/${selectedObjectType}/${
                                    item.RequirementID
                                  }?display=${displayMode}`
                                : undefined
                            }
                            updatePageTitle={setPageTitle}
                            updateMobileIsOpen={props.setMobileOpen}
                            topRef={topRef}
                            path={path}
                            icon={item.ObjectTypeIcon}
                            disabled={!item.Permission}>
                            {item.Actions.sort((a, b) =>
                              a.ActionName.localeCompare(b.ActionName, "en", {
                                numeric: true,
                              }),
                            ).map((action: ActionsTree, index: number) => (
                              <div key={index}>
                                <NavItem
                                  level={4}
                                  id={action.ActionID}
                                  text={[
                                    action.ActionName,
                                    action.ActionReadableID,
                                  ]}
                                  link={`${
                                    pageStatus === "Template" ? "/template" : ""
                                  }/action/${
                                    action.ActionID
                                  }?display=${displayMode}&parentid=${
                                    item.RequirementID
                                  }`}
                                  updatePageTitle={setPageTitle}
                                  updateMobileIsOpen={props.setMobileOpen}
                                  topRef={topRef}
                                  path={path}
                                  icon={action.ObjectTypeIcon}>
                                  {action.Instances &&
                                    action.Instances.map(
                                      (
                                        instance: InstancesTree,
                                        index: number,
                                      ) => (
                                        <div key={index}>
                                          <NavItem
                                            level={5}
                                            id={instance.InstanceID}
                                            text={[
                                              formatDateTime({
                                                date: instance.InstanceDate,
                                                format: "Date",
                                              }),
                                            ]}
                                            link={`/instance/${instance.InstanceID}?display=${displayMode}`}
                                            updatePageTitle={setPageTitle}
                                            updateMobileIsOpen={
                                              props.setMobileOpen
                                            }
                                            topRef={topRef}
                                            path={path}
                                            icon={action.ObjectTypeIcon}
                                          />
                                        </div>
                                      ),
                                    )}
                                </NavItem>
                              </div>
                            ))}
                            {selectedData
                              .filter((record: RequirementsTree) =>
                                record.ParentIDs.includes(item.RequirementID),
                              )
                              .sort((a, b) =>
                                a.RequirementName.localeCompare(
                                  b.RequirementName,
                                  "en",
                                  { numeric: true },
                                ),
                              )
                              .filter((child: any) => child.Permission)
                              .map((child: RequirementsTree, index: number) => (
                                <div key={index}>
                                  <NavItem
                                    level={4}
                                    id={child.RequirementID}
                                    text={[
                                      child.RequirementName,
                                      child.RequirementReadableID,
                                    ]}
                                    link={`${
                                      pageStatus === "Template"
                                        ? "/template"
                                        : ""
                                    }/${selectedObjectType}/${
                                      child.RequirementID
                                    }?display=${displayMode}&parentid=${
                                      item.RequirementID
                                    }`}
                                    updatePageTitle={setPageTitle}
                                    updateMobileIsOpen={props.setMobileOpen}
                                    topRef={topRef}
                                    path={path}
                                    icon={child.ObjectTypeIcon}
                                    disabled={!item.Permission}>
                                    {child.Actions.sort((a, b) =>
                                      a.ActionName.localeCompare(
                                        b.ActionName,
                                        "en",
                                        { numeric: true },
                                      ),
                                    ).map(
                                      (action: ActionsTree, index: number) => (
                                        <div key={index}>
                                          <NavItem
                                            level={4}
                                            id={action.ActionID}
                                            text={[
                                              action.ActionName,
                                              action.ActionReadableID,
                                            ]}
                                            link={`${
                                              pageStatus === "Template"
                                                ? "/template"
                                                : ""
                                            }/action/${
                                              action.ActionID
                                            }?display=${displayMode}&parentid=${
                                              child.RequirementID
                                            }`}
                                            updatePageTitle={setPageTitle}
                                            updateMobileIsOpen={
                                              props.setMobileOpen
                                            }
                                            topRef={topRef}
                                            path={path}
                                            icon={action.ObjectTypeIcon}>
                                            {action.Instances &&
                                              action.Instances.map(
                                                (
                                                  instance: InstancesTree,
                                                  index: number,
                                                ) => (
                                                  <div key={index}>
                                                    <NavItem
                                                      level={5}
                                                      id={instance.InstanceID}
                                                      text={[
                                                        formatDateTime({
                                                          date: instance.InstanceDate,
                                                          format: "Date",
                                                        }),
                                                      ]}
                                                      link={`/instance/${instance.InstanceID}?display=${displayMode}`}
                                                      updatePageTitle={
                                                        setPageTitle
                                                      }
                                                      updateMobileIsOpen={
                                                        props.setMobileOpen
                                                      }
                                                      topRef={topRef}
                                                      path={path}
                                                      icon={
                                                        action.ObjectTypeIcon
                                                      }
                                                    />
                                                  </div>
                                                ),
                                              )}
                                          </NavItem>
                                        </div>
                                      ),
                                    )}
                                  </NavItem>
                                </div>
                              ))}
                          </NavItem>
                        </div>
                      ))}
                  </NavItem>
                </div>
              ))}

              {display === "reports" && canAccessTimeSheetReport ? (
                <div>
                  <EasyReportNavItem
                    topRef={topRef}
                    setMobileOpen={props.setMobileOpen}
                    path={path}
                    setPageTitle={setPageTitle}
                  />
                </div>
              ) : null}
              {display === "reports" && canAccessAlertRightNowReport ? (
                <EasyReportAlertHistoryNavItem
                  topRef={topRef}
                  setMobileOpen={props.setMobileOpen}
                  path={path}
                  setPageTitle={setPageTitle}
                />
              ) : null}
            </div>
          </Row>
        </Container>
      );
    }
    if (open && pageStatus === "Normal" && display === "settings") {
      const accessibleSettingsCategories = Access.checkAccess(
        appState.app.permissions_LEGACY,
        Permissions.CodeUserSettings,
        Permissions.TypeUpdate,
        false,
      )
        ? settingsCategories
        : restrictedSettingsCategories;
      return (
        <Container
          as={Col}
          ref={topRef}
          className={`sideNav sideNavOpen ${
            pageStatus === "Template" ? "sideNavTemplate" : ""
          }${!props.isInDrawer ? "fullSize" : ""}`}>
          <div className="sideNavContainer">
            <div className="row">
              <Col xs="3">
                <Button
                  className="sideNavBackButton"
                  variant="light"
                  size="sm"
                  onClick={() => {
                    updateDisplay("main");
                    updateItemType("");
                    props.setMobileOpen(false);
                  }}>
                  <ArrowLeft size="16" />
                </Button>
              </Col>
              <Col className="navMenuHeading">
                <h3>Back</h3>
              </Col>
              <Col xs={{ span: "3" }} className="d-block d-md-none">
                <Button
                  variant="light"
                  className="closeButton"
                  onClick={() => {
                    props.setMobileOpen(!props.mobileIsOpen);
                  }}>
                  <Menu />
                </Button>
              </Col>
            </div>
            <div className="row" style={{ flex: "1 1 auto" }}>
              <div className="navMenu col">
                {accessibleSettingsCategories.map((item, index) => (
                  <div key={index}>
                    <NavItem
                      level={2}
                      id={item.title.toLowerCase().replace(/\s/g, "")}
                      text={[item.title]}
                      link={item.link}
                      updatePageTitle={setPageTitle}
                      updateMobileIsOpen={props.setMobileOpen}
                      topRef={topRef}
                      path={path}
                      icon="Folder">
                      {item.children &&
                        item.children.map((child, index) => (
                          <div key={index}>
                            <NavItem
                              level={3}
                              id={child.title.toLowerCase().replace(/\s/g, "")}
                              text={[child.title]}
                              link={child.link}
                              onClick={
                                child.title === "Template Management"
                                  ? () => {
                                      window.location.href = "/template";
                                    }
                                  : undefined
                              }
                              updatePageTitle={setPageTitle}
                              updateMobileIsOpen={props.setMobileOpen}
                              topRef={topRef}
                              path={path}
                              icon="FileText"
                            />
                          </div>
                        ))}
                    </NavItem>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <SidebarAccount />
            </div>
          </div>
        </Container>
      );
    }
    if (open && pageStatus === "Template" && display === "actions") {
      return (
        <Container
          as={Col}
          ref={topRef}
          className={`sideNav sideNavOpen ${
            pageStatus === "Template" ? "sideNavTemplate" : ""
          }${!props.isInDrawer ? "fullSize" : ""}`}>
          <Row>
            <Col xs="3">
              <Button
                className="sideNavBackButton"
                variant="light"
                size="sm"
                onClick={() => {
                  updateDisplay("main");
                  updateItemType("Actions");
                  props.setMobileOpen(false);
                }}>
                <ArrowLeft size="16" />
              </Button>
            </Col>
            <Col className="navMenuHeading">
              <h3>Back to Actions</h3>
            </Col>
            <Col xs={{ span: "3" }} className="d-block d-md-none">
              <Button
                variant="light"
                className="closeButton"
                onClick={() => {
                  props.setMobileOpen(!props.mobileIsOpen);
                }}>
                <Menu />
              </Button>
            </Col>
          </Row>
          {pageStatus === "Template" ? (
            <>
              <div
                style={{ display: "flex", justifyContent: "center" }}
                className="row">
                <h5>Templates</h5>
              </div>
            </>
          ) : null}
          <Row>
            <div className="navMenu col">
              {actionsCategories.map((item: DashboardCategory) => (
                <div key={item.id}>
                  <NavItem
                    level={2}
                    id={item.id}
                    text={[item.title]}
                    link={item.link}
                    updatePageTitle={setPageTitle}
                    updateMobileIsOpen={props.setMobileOpen}
                    topRef={topRef}
                    path={path}
                    icon={item.icon}>
                    {data &&
                      data.AloneActions.filter(
                        (action: AloneActionsTree) =>
                          action.ObjectTypeID === item.id,
                      )
                        .sort((a, b) =>
                          a.ActionName.localeCompare(b.ActionName, "en", {
                            numeric: true,
                          }),
                        )
                        .map(
                          (
                            categorisedAction: AloneActionsTree,
                            index: number,
                          ) => (
                            <div key={index}>
                              <NavItem
                                level={3}
                                id={categorisedAction.ActionID}
                                text={[
                                  categorisedAction.ActionName,
                                  categorisedAction.ActionReadableID,
                                ]}
                                link={`/template/action/${categorisedAction.ActionID}?display=actions`}
                                updatePageTitle={setPageTitle}
                                updateMobileIsOpen={props.setMobileOpen}
                                topRef={topRef}
                                path={path}
                                icon={categorisedAction.ObjectTypeIcon}
                              />
                            </div>
                          ),
                        )}
                  </NavItem>
                </div>
              ))}
            </div>
          </Row>
        </Container>
      );
    }
    return (
      <Container as={Col} ref={topRef} className="sideNav sideNavClosed">
        <chakra-scope>
          <ChakraHStack w="full" justifyContent="center">
            <ChakraButton
              variant="ghost"
              onClick={() => {
                setOpen(true);
                props.openSidebar();
              }}>
              <MdiIcon size={1} path={mdiMenu} />
            </ChakraButton>
          </ChakraHStack>
        </chakra-scope>
      </Container>
    );
  }
  return null;
};

export { Sidebar };
