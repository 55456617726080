import { FieldArray, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Row,
  Table,
  Tooltip
} from "react-bootstrap";
import * as Feather from "react-feather";
import * as Yup from "yup";
import { useAppState } from "../../components/App/AppProvider";
import FullLoader from "../../components/chakra/FullLoader";
import { Text } from "../../components/Forms/Field/Text";
import { Card } from "../../components/UI/Card/Card";
import { Header } from "../../components/UI/Header/Header";
import LegacyScreenContainer from "../../components/UI/LegacyScreenContainer";
import { HttpWithUrl } from "../../components/UI/URL/URLSelector";
import * as DocumentType from "../../constants/documentType";
import { urlRegex } from "../../constants/urlRegex";
import * as Permissions from "../../shared/constants/permission";
import * as Access from "../../utilities/access";
import { del, get, post, put } from "../../utilities/request";
import useToast from "../../utilities/useToast";
import { useAuth } from "../../utilities/useAuth";

const ScreensDocuments = () => {
  const { auth, app } = useAppState();
  const { getToken } = useAuth();
  const [pageStatus, setPageStatus] = useState<any>("Loading");
  const [data, setData] = useState<any>(null);
  const { displayToast } = useToast();
  const [originalUrls, setURLs] = useState<any>(null);

  const insertDocuments: any[] = [
    {
      DocumentID: "",
      DocumentName: "",
      DocumentURL: "",
      protocolName: "",
    },
  ];

  const schema: any = Yup.object().shape({
    data: Yup.array().of(
      Yup.object().shape({
        DocumentName: Yup.string().required("Name of document is required"),
        DocumentURL: Yup.string()
          .matches(urlRegex, "Document must be a valid URL")
          .required("URL is required"),
      }),
    ),
  });

  useEffect(() => {
    const fetchList = async () => {
      setPageStatus("Loading");
      const reply = await get(`settings/list/documents`, getToken);
      const documentData: any[] = Object.values(reply.data);

      if (reply.status !== 200 || !reply.data) {
        displayToast({
          status: "error",
          title: `Failed to retrieve Documents`,
        });
      } else {
        setURLs(documentData.map((document: any) => document.DocumentURL));
        setData(documentData);
        setPageStatus("Ready");
      }
    };
    fetchList();
  }, []); //eslint-disable-line

  if (pageStatus === "Loading") {
    return <FullLoader />;
    // return (
    //   <section>
    //     <div className="progress-spinner" style={{ marginTop: "20%" }}>
    //       <Loading size={"xl"} />
    //     </div>
    //   </section>
    // );
  }
  return (
    <LegacyScreenContainer pageTitle="Document Details" breadcrumbs={[]}> 
      <section>
        {/* <Header
          breadcrumbs={[
            { title: "Settings", link: "" },
            { title: "Pages & Fields", link: "" },
            { title: "Lists", link: "/settings/lists" },
            { title: "documents" },
          ]}
          title="Document Details"
        /> */}
        <Card
          title="Documents"
          collapsable={false}
          headerCols={[
            { colProps: {}, children: <span /> },
            {
              colProps: { sm: "auto" },
              children:
                /* Needs to be changed once the permissions work for things other than Requirements. Will check to see
                         if a user has permission to Edit Documents */
                Access.checkAccess(
                  app.permissions_LEGACY,
                  Permissions.CodeRequirement,
                  Permissions.TypeUpdate,
                ) && pageStatus !== "Editing" ? (
                  <Button
                    type="button"
                    variant="outline-dark"
                    disabled={pageStatus === "Submitting"}
                    onClick={() => setPageStatus("Editing")}>
                    <Feather.Edit2 className="feather" size="16" />
                    Edit
                  </Button>
                ) : pageStatus !== "Editing" ? (
                  <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip id="tooltip-not-authorised-to-edit">
                        You do not have permission to edit this
                      </Tooltip>
                    }>
                    <Button
                      type="button"
                      variant="outline-dark"
                      className="disabled">
                      <Feather.Edit2 className="feather" />
                      Edit
                    </Button>
                  </OverlayTrigger>
                ) : (
                  <span />
                ),
            },
          ]}>
          <Formik
            enableReinitialize
            initialValues={{ data: [...data] }}
            validationSchema={schema}
            onSubmit={async (values, actions) => {
              const changes = values.data.filter(
                (singleDoc: any, index: number) => {
                  if (index <= data.length - 1) {
                    return (
                      data[index].DocumentName !== singleDoc.DocumentName ||
                      originalUrl[index] !== singleDoc.DocumentURL ||
                      singleDoc.DocumentID === ""
                    );
                  }
                  return true;
                },
              );
              insertDoc = changes.filter(
                (doc: any) => doc.DocumentID === undefined,
              );
              // If there has been no insert, just update values
              if (insertDoc.length === 0) {
                const reply = await post(
                  `settings/list/documents`,
                  changes,
                  getToken,
                );
                if (reply.status === 200) {
                  values.data.forEach(
                    (document: any) => (document.errors = ""),
                  );
                  setPageStatus("Ready");
                  displayToast({
                    status: "success",
                    title: `Documents updated successfully`,
                  });
                } else {
                  displayToast({
                    status: "error",
                    title: `Failed to update Documents`,
                  });
                }
              } else {
                setPageStatus("Submitting");

                insertDoc = insertDoc.map((document: any) => ({
                  ...document,
                  DocumentTypeID: DocumentType.UserGenerated,
                }));

                const reply = await put(
                  `settings/lists/documents`,
                  insertDoc,
                  getToken,
                );

                if (reply.status === 200) {
                  setData(reply.data);
                  setPageStatus("Ready");
                  displayToast({
                    status: "success",
                    title: `Document created successfully`,
                  });
                } else {
                  displayToast({
                    status: "error",
                    title: `Failed to create Document`,
                  });
                }
                // setInsert(false)
                setPageStatus("Ready");
              }
            }}
            render={(formikProps) => (
              <Form onSubmit={formikProps.handleSubmit}>
                <Table borderless>
                  <thead>
                    <tr>
                      <th> Document Name </th>
                      <th> Document URL</th>
                      {pageStatus === "Editing" ? <th>Action</th> : null}
                    </tr>
                  </thead>

                  <tbody>
                    <FieldArray
                      name="data"
                      // onUpdate={formikProps.handleChange}
                      render={({ insert, remove, push }) => (
                        <>
                          {formikProps.values.data.map(
                            (document: any, index: number) => (
                              <tr key={index}>
                                <td>
                                  <div className="input-group">
                                    {formikProps.values.data[index].errors ? (
                                      <Feather.AlertTriangle color="red" />
                                    ) : null}
                                    <Col
                                      sm={
                                        formikProps.values.data[index].errors
                                          ? "9"
                                          : "10"
                                      }>
                                      <Text
                                        value={
                                          formikProps.values.data[index]
                                            .DocumentName
                                        }
                                        name={`data[${index}].DocumentName`}
                                        onUpdate={formikProps.handleChange}
                                        readOnly={pageStatus !== "Editing"}
                                      />
                                    </Col>
                                  </div>
                                </td>
                                <td>
                                  <Col sm="10">
                                    <HttpWithUrl
                                      value={
                                        formikProps.values.data[index]
                                          .DocumentURL
                                      }
                                      readOnly={pageStatus !== "Editing"}
                                      name={`data[${index}].DocumentURL`}
                                      onUpdate={(newValue: string) => {
                                        const copy = [
                                          ...formikProps.values.data,
                                        ];
                                        copy[index].DocumentURL = newValue;
                                        formikProps.setValues({ data: copy });
                                        console.log(newValue);
                                      }}
                                      protocolName={`data[${index}].protocolName`}
                                    />
                                  </Col>
                                </td>
                                {pageStatus === "Editing" ? (
                                  <td>
                                    <Button
                                      variant="outline-dark"
                                      onClick={async (event: any) => {
                                        if (document.DocumentID !== undefined) {
                                          setPageStatus("Submitting");
                                          remove(index);
                                          formikProps.setValues({
                                            data: [
                                              ...data.filter(
                                                (doc: any, docIndex: number) =>
                                                  index !== docIndex,
                                              ),
                                            ],
                                          });
                                          setData(
                                            data.filter(
                                              (doc: any, docIndex: number) =>
                                                index !== docIndex,
                                            ),
                                          );

                                          const reply = await del(
                                            `settings/lists/documents/${document.DocumentID}`,
                                            getToken,
                                          );
                                          if (reply.status === 200) {
                                            setPageStatus("Ready");
                                            displayToast({
                                              status: "success",
                                              title: `Document deleted successfully`,
                                            });
                                          } else {
                                            displayToast({
                                              status: "error",
                                              title: `Failed to delete Document`,
                                            });
                                          }
                                        } else {
                                          remove(index);
                                          setPageStatus("Ready");
                                        }
                                      }}>
                                      Remove
                                    </Button>
                                  </td>
                                ) : null}
                              </tr>
                            ),
                          )}
                          {pageStatus === "Editing" ? (
                            <tr>
                              <td style={{ paddingLeft: 25 }}>
                                <Button
                                  variant="outline-dark"
                                  onClick={() => {
                                    // setInsert(true)
                                    push({
                                      readOnly: true,
                                      ListValueID: Math.random(),
                                    });
                                  }}>
                                  {" "}
                                  Add Document
                                </Button>
                              </td>
                            </tr>
                          ) : null}
                        </>
                      )}
                    />
                  </tbody>
                </Table>
                {pageStatus === "Editing" ? (
                    <Form.Group as={Row}>
                      <Col sm="2" />
                      <Col sm="auto">
                        <Button
                          type="submit"
                          disabled={pageStatus === "Submitting"}
                          onClick={(event: any) => {
                            const errors = JSON.stringify(formikProps.errors)
                              .replace('{"data":[', "")
                              .replace("]", "")
                              .replace("}", "")
                              .replace("{", "")
                              .replace("}", "")
                              .split(",");
                            if (errors[0] !== "") {
                              const temp: any = formikProps.errors.data;
                              const errorInfo: any[] = temp;
                              errorInfo.forEach((error: any, index: number) => {
                                if (error !== undefined) {
                                  formikProps.values.data[index] = {
                                    ...formikProps.values.data[index],
                                    errors: "error",
                                  };
                                }
                              });

                              const errorData: string[] = errors.map(
                                (e: string, index: number) =>
                                  (errors[index] = e
                                    .substring(e.lastIndexOf(":") + 1)
                                    .replace("}", "")),
                              );
                              const errorMessage = errorData
                                .reduce(
                                  (
                                    errorMessage: string,
                                    error: any,
                                    index: number,
                                  ) => {
                                    if (error !== "null") {
                                      return `${errorMessage}\n${error}`;
                                    }
                                    return errorMessage;
                                  },
                                )
                                .replace("null", "");

                              displayToast({
                                status: "error",
                                title: `Failed to update Documents`,
                                description: `${errorMessage}`,
                              });
                            }
                          }}>
                          {" "}
                          Submit{" "}
                        </Button>
                      </Col>

                      <Col sm="auto">
                        <Button
                          type="button"
                          variant="light"
                          onClick={() => {
                            formikProps.setValues({ data: [...data] });
                            setPageStatus("Ready");
                          }}>
                          Cancel{" "}
                        </Button>
                      </Col>
                    </Form.Group>
                  
                ) : null}
              </Form>
            )}
          />
        </Card>
        <section></section>
    </section>
    </LegacyScreenContainer>
  );
};

export type DocumentPageStatus = "Loading" | "Ready" | "Editing" | "Submitting";
export { ScreensDocuments };
